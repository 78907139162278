import { useEffect } from "react"
import useApi from "../common/ApiMiddlewere"

function gstPercentage() {
    const { postApi } = useApi()
    const useGstPercentage = () => {
        if (JSON.parse(localStorage.getItem("authUser")).RoleName != "Salesman") {
            postApi("Setting/setting-by-keys", {
                Key: ["GST_PERCENTAGE"],
                userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
            }).then(({ data, status }) => {
                localStorage.setItem("GST", data[0]?.Value)
                if (status === 200) {

                } else {

                }
            })
        }
    };

    useEffect(() => {
        useGstPercentage()
    }, [])

}

export default gstPercentage; // Export the Counter component

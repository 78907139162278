import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Form,
    Modal,
    ModalBody,
    Input,
    FormGroup,
    ModalHeader,
    Button,
    Label,
    FormFeedback,
    Row,
    Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import AccessoryTable from "./AccessoryTable"
import AccessoryValueTable from "./AccessoryValueTable"

let accessaryValues = []
function Accessory() {
    document.title = `Accessories | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { deleteApi, getApi, postApi, putApi } = useApi()
    // useStates
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [isEditAccessoryValue, setIsEditAccessoryValue] = useState(false)
    const [tableData, setTableData] = useState([])
    const [accessaryData, setAccessoryData] = useState([])
    const [accessoryValuesData, setAccessoryValuesData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [screenLoad, setScreenLoad] = useState(false)
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [isAccessoryValue, setIsAccessoryValue] = useState(false)
    const [accessoryId, setAccessoryId] = useState(1)
    const [accessoryIdAddValue, setAccessoryIdAddValue] = useState(1)
    const [accessoryValueId, setAccessoryValueId] = useState(1)
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(0)

    // Accessory list api
    const accessoryListApi = () => {
        if (categoryId > 0) {
            setScreenLoad(true)
            setIsLoading(true)
            getApi(`Category/accessory-lookup?categoryId=${categoryId}`).then(({ data, status }) => {
                if (status === 200) {
                    setIsLoading(false)
                    setTableData(data)
                    setAccessoryData(data)
                    setTotalRecords(data.length)
                    handleAccessoryDetail(data[0]?.Key)
                } else {
                    setIsLoading(false)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
                setScreenLoad(false)
            })
        }
    }

    // accessory list api useEffect
    useEffect(() => {
        accessoryListApi()
    }, [categoryId])

    useEffect(() => {
        setScreenLoad(true)
        getApi("Category/accessory-category-lookup").then(({ data, status }) => {
            if (status === 200) {
                setCategories(data)
                setCategoryId(data[0]?.Id)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
    }, [])

    // toggle handlers
    const toggle = () => {
        setModal(!modal)
        validation.handleReset()
    }
    // toggle handlers
    const toggle1 = () => {
        setModal1(!modal1)
        accessoryValuevalidation.handleReset()
    }

    const handleReset = () => {
        validation.handleReset()
        setFields([])
    }

    // Delete accessory handler
    const handleDelete = (accessoryValueId) => {
        setScreenLoad(true)
        if (accessoryValueId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this accessory permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Category/delete-accessory-value?accessoryValueId=${accessoryValueId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = accessoryValuesData
                                    .map(obj => (obj.Id !== accessoryValueId ? obj : null))
                                    .filter(Boolean)
                                setAccessoryValuesData(updatedArray)
                                toast.success("successfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Accessory",
                                    text: data,
                                })
                            }
                            setScreenLoad(false)
                        }
                    )
                } else {
                    setScreenLoad(false)
                }
            })
        }
    }

    const handleAddValue = (accessoryId) => {
        setAccessoryIdAddValue(accessoryId)
        toggle1()
    }

    const handleAccessoryDetail = (accessoryId) => {
        setScreenLoad(true)
        if (accessoryId) {
            setIsAccessoryValue(true)
            setAccessoryId(accessoryId)
            getApi(`Category/accessory-value-lookup?accessoryIds=${accessoryId}`).then(({ data, status }) => {
                if (status === 200) {
                    setScreenLoad(false)
                    setAccessoryValuesData(data)
                    accessaryValues = data
                } else {
                    setScreenLoad(false)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
                setScreenLoad(false)
            })
            setAccessoryValueId(0)
        }
    }

    const handleUpdateAceesory = (accessoryId, accessaryName) => {
        accessaryData.forEach(function (value) {
            if (value.Key === accessoryId) {
                value.Value = accessaryName
            }
        });
    }

    const handleSaveAccessoryValue = (accessoryValueId) => {
        setScreenLoad(true)
        accessaryValues.forEach(function (item) {
            if (item.Id === accessoryValueId) {
                if (item.Value == "") {
                    setScreenLoad(false)
                    toast.error("Please fill accessory value", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                } else {
                    putApi("Category/update-accessory-value", {
                        accessoryId: 0,
                        accessoryValueId: accessoryValueId,
                        value: item.Value,
                        stock: item.Stock,
                    }).then(({ data, status }) => {
                        if (status == 200) {
                            toast.success("successfully updated", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            setIsEditAccessoryValue(false)
                            setAccessoryValuesData(accessaryValues)
                            setScreenLoad(false)
                        } else {
                            setScreenLoad(false)
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                    })


                }
            }
        });
    }

    const handleEditAccessoryValue = accessoryRowId => {
        setIsEditAccessoryValue(true)
        setAccessoryValueId(accessoryRowId)
    }

    const handleUpdateAceesoryValueName = (accessoryValueId, valueName) => {
        accessaryValues.forEach(function (value) {
            if (value.Id === accessoryValueId) {
                value.Value = valueName
            }
        });
    }

    const handleUpdateAceesoryValueStock = (accessoryValueId, valueStock) => {
        accessaryValues.forEach(function (value) {
            if (value.Id === accessoryValueId) {
                value.Stock = parseInt(valueStock) || 0
            }
        });
    }

    // accessory Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: "",
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("The accessory name is required"),
        }),
        onSubmit: values => {
            const hasDuplicates = (array) => {
                return new Set(array.map((item) => item.value)).size !== array.length;
            };
            setformSumitLoading(true)
            if (fields.length == 0) {
                toast.error("The accessory has at least one value.", {
                    style: {
                        fontSize: "15px",
                    },
                })
                setformSumitLoading(false)
                return false
            } else if (fields[fields.length - 1].value == "") {
                toast.error("Please fill value", {
                    style: {
                        fontSize: "15px",
                    },
                })
                setformSumitLoading(false)
                return false
            } else if (hasDuplicates(fields)) {
                toast.error("Value already exists. Please enter a unique value!", {
                    style: {
                        fontSize: "15px",
                    },
                })
                setformSumitLoading(false)
                return false
            }
            if (values) {
                postApi("Category/create-accessory", {
                    name: values.Name,
                    listAccessoryValue: fields,
                }).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 200) {
                        toast.success("successfully created", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        toggle()
                        accessoryListApi()
                        validation.handleReset()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                })
            }
        },
    })

    // accessory value Validation
    const accessoryValuevalidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            value: "",
            stock: 0,
        },
        validationSchema: Yup.object({
            value: Yup.string().required("The value is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                postApi("Category/create-accessory-value", {
                    accessoryId: accessoryIdAddValue,
                    stock: parseInt(values.stock),
                    value: values.value,
                }).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 200) {
                        toast.success("Successfully Add", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        toggle1()
                        handleAccessoryDetail(accessoryIdAddValue)
                        accessoryValuevalidation.handleReset()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                })
            }
        },
    })

    // Accessory table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Accessories",
                accessor: "Value",
                Cell: ({ row }) => {
                    return (
                        <>
                            {row.original.Value}
                        </>
                    )
                },
            },

            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                accessor: "action",
                width: "22%",
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    id={`deletetooltip${row.original.Key}`}
                                    className="far fa-plus-square"
                                    style={{ fontSize: "14px", fontWeight: "500", color: "#90499c", cursor: "pointer" }}
                                    onClick={() => handleAddValue(row.original.Key)}
                                ></i>
                                <span className="mytext">Add Accessory Value</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className={` ${isAccessoryValue && accessoryId == row.original.Key ? "fas fa-angle-right" : "fas fa-angle-left"}`}
                                    id={`edittooltip${row.original.Key}`}
                                    style={{ fontSize: "17px", color: "#556ee6", cursor: "pointer" }}
                                    onClick={() => handleAccessoryDetail(row.original.Key)}
                                ></i>
                                <span className="mytext">Values</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, isAccessoryValue, accessoryId]
    )

    // Accessory Value table header & accessor
    const columns1 = useMemo(
        () => [
            {
                Header: "Value",
                accessor: "Value",
                Cell: ({ row }) => {
                    return (
                        <>
                            {isEditAccessoryValue && accessoryValueId == row.original.Id ? <>
                                <div className="table-display">
                                    <Input
                                        className="form-control-sm table-cell"
                                        type="text"
                                        onFocus={(e) => { e.target.select() }}
                                        defaultValue={row.original.Value}
                                        onChange={e =>
                                            handleUpdateAceesoryValueName(row.original.Id, e.target.value)
                                        }
                                    />
                                </div>

                            </> : <>
                                {row.original.Value}
                            </>}

                        </>
                    )
                },
            },
            {
                Header: "Stock",
                accessor: "Stock",
                width: "15%",
                Cell: ({ row }) => {
                    return (
                        <>
                            {isEditAccessoryValue && accessoryValueId == row.original.Id ? <>
                                <div className="table-display">
                                    <Input
                                        className="form-control-sm table-cell"
                                        placeholder="0"
                                        type="text"
                                        onFocus={(e) => { e.target.select() }}
                                        defaultValue={row.original.Stock || 0}
                                        onChange={e =>
                                            handleUpdateAceesoryValueStock(row.original.Id, e.target.value)
                                        }
                                    />
                                </div>

                            </> : <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {row.original.Stock || 0}
                                </div>
                            </>}

                        </>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "8%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            {isEditAccessoryValue && accessoryValueId == row.original.Id ?
                                <li className="mytooltip">
                                    <i
                                        // className=" fs-6 fas fa-edit btn-edit"
                                        className="fs-5 far fa-save btn-edit"
                                        id={`edittooltip${row.original.Id}`}
                                        onClick={() => handleSaveAccessoryValue(row.original.Id)}
                                    ></i>
                                    <span className="mytext">Save</span>
                                </li>
                                :
                                <li className="mytooltip">
                                    <i
                                        // className=" fs-6 fas fa-edit btn-edit"
                                        className="fs-6 fas fa-edit btn-edit"
                                        id={`edittooltip${row.original.Id}`}
                                        onClick={() => handleEditAccessoryValue(row.original.Id)}
                                    ></i>
                                    <span className="mytext">Edit</span>
                                </li>
                            }

                            <li className="mytooltip">
                                <i
                                    id={`deletetooltip${row.original.Id}`}
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [isEditAccessoryValue, accessoryValueId, accessoryValuesData]
    )

    const [fields, setFields] = useState([]);

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screenLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <AccessoryTable
                                    columns={columns}
                                    data={tableData}
                                    isGlobalFilter={true}
                                    isAccessoryList={true}
                                    // handleAccessoryClick={handleAccessoryClick}
                                    customPageSize={10}
                                    className="custom-header-css"
                                    isLoading={isLoading}
                                    TotalRecords={totalRecords}
                                    accessoryId={accessoryId}
                                    isAccessoryValue={isAccessoryValue}
                                    categories={categories}
                                    categoryId={categoryId}
                                    setCategoryId={setCategoryId}
                                />
                            </Col>
                            <Col md={6}>
                                <AccessoryValueTable
                                    columns={columns1}
                                    data={accessoryValuesData}
                                    isGlobalFilter={true}
                                    isAccessoryList={true}
                                    // handleAccessoryClick={handleAccessoryClick}
                                    customPageSize={10}
                                    className="custom-header-css"
                                    isLoading={isLoading}
                                    TotalRecords={totalRecords}
                                />

                            </Col>
                        </Row>

                        <Modal
                            isOpen={modal1}
                            toggle={toggle1}
                            centered={true}
                            size="small"
                            style={{ width: "350px" }}
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggle1} tag="h5">
                                    {"Add Accessory Value"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            accessoryValuevalidation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault
                                            accessoryValuevalidation.handleReset()
                                            return false
                                        }}
                                    >

                                        <Row>
                                            <Col md={8}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03" className="required">
                                                        Value
                                                    </Label>
                                                    <Input
                                                        name="value"
                                                        placeholder="Value"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={accessoryValuevalidation.handleChange}
                                                        onBlur={accessoryValuevalidation.handleBlur}
                                                        value={accessoryValuevalidation.values.value || ""}
                                                        invalid={
                                                            accessoryValuevalidation.touched.value && accessoryValuevalidation.errors.value
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {accessoryValuevalidation.touched.value && accessoryValuevalidation.errors.value ? (
                                                        <FormFeedback type="invalid">
                                                            {accessoryValuevalidation.errors.value}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>

                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03" className="">
                                                        Stock
                                                    </Label>
                                                    <Input
                                                        name="stock"
                                                        placeholder="0"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onKeyPress={handleKeyPress}
                                                        onChange={accessoryValuevalidation.handleChange}
                                                        onBlur={accessoryValuevalidation.handleBlur}
                                                        value={accessoryValuevalidation.values.stock}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div className="text-end">
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2 btn-sm "
                                                type="reset"
                                            >
                                                Reset
                                            </Button>
                                            <>
                                                {formSumitLoading ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm "
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Accessory.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Accessory

var CryptoJS = require("crypto-js");

const encryptData = async (input) => {
  const Token =
    "qwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnm";

  const iv = CryptoJS.enc.Utf8.parse(Token);
  const key = CryptoJS.enc.Utf8.parse(Token);

  const encrypted = CryptoJS.AES.encrypt(input, key, {
    iv: iv,
  }).ciphertext;
  return encrypted.toString(CryptoJS.enc.Base64);
};

const decryptData = async (input) => {
  const Token =
    "qwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnm";

  const iv = CryptoJS.enc.Utf8.parse(Token);
  const key = CryptoJS.enc.Utf8.parse(Token);

  input = CryptoJS.enc.Base64.parse(input);

  // Encrypt the string to an array of bytes.
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: input }, key, {
    iv: iv,
  }).toString(CryptoJS.enc.Utf8);

  return decrypted;
};

export { encryptData, decryptData };

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import toast from "react-hot-toast"
import { Card, CardBody } from "reactstrap";
import useApi from "common/ApiMiddlewere";
// import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const LineBar = () => {

    const { getApi } = useApi()
    const [data, setData] = useState([])

    useEffect(() => {
        getApi("Report/booking-chart").then(({ data, status }) => {
            if (status === 200) {
                setData(data.reverse())
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }, [])


    const spineareaChartColors = [
        "#34c38f",
        "#556ee6",
        "#f46a6a"
    ]
    const options = {
        grid: {
            zlevel: 0,
            x: 90,
            x2: 40,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            orient: "center",
            left: 0,
            top: 20,
            feature: {
                // dataView: { show: true, readOnly: false, title: "Data View" },
                magicType: { show: true, type: ["line", "bar"], title: { line: "For line chart", bar: "For bar chart" } },
                // restore: { show: true, title: "restore" },
                saveAsImage: { show: true, title: "Download Image" },
            },
        },
        color: "#227C70",
        legend: {
            data: ["Bookings"],
            textStyle: {
                color: ["#8791af"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: data.map(({ Month }) => Month),
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#8791af",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Total booking",
                // min: 0,
                // max: 250,
                // interval: 50,
                axisLine: {
                    lineStyle: {
                        color: '#8791af'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(166, 176, 207, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                },
            }
        ],
        series: [
            {
                name: "Bookings",
                type: "bar",
                data: data.map(({ TotalBooking }) => TotalBooking),
            },
            // {
            //   name: "Precipitation",
            //   type: "bar",
            //   data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2],
            // },
            // {
            //   name: "Average Temperature",
            //   type: "line",
            //   yAxisIndex: 1,
            //   data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4],
            // },
        ],
        textStyle: {
            color: ["#74788d"],
        },
    }


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h3 className="card-title mb-4">Bookings</h3>
                    <ReactEcharts style={{ height: "320px" }} option={options} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default LineBar;

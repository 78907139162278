import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

// import logo from "../../assets/images/6.png"
import logo from "../../assets/images/logo.svg";
import fulllogo from "../../assets/images/fulllogo.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoSm from "../../assets/images/logo/logo.png"
import logoDark from "../../assets/images/logo-dark.png";
import { routesRoles } from "common/routesRoles"
import { routes } from "routes/path";
const Sidebar = props => {
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={`${RoleName.RoleName == "Salesman" ? routes.available : routes.dashboard}`} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to={`${RoleName.RoleName == "Salesman" ? routes.available : routes.dashboard}`} className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={fulllogo} alt="" height="32" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))

import useApi from "common/ApiMiddlewere"
// import { BookingItemHtml, InvoiceTable } from "./table"
import { InvoiceTable, BookingItemHtml } from "./table"

import { dateFormat } from "pages/Common"
import { clearFields } from "redux-form"

export const InvoiceHtml = async (id, getApi) => {
  let { data, status } = await getApi(`Booking/invoice-detail?id=${id}`)

  let { ProductList } = data


  if (status == 200) {
    let {
      Advance,
      BillDate,
      BillNo,
      BillNotes,
      ContactNo1,
      ContactNo2,
      Discount,
      CustomerName,
      TotalRent,
      CustomerAddress,
      Remarks,
      ShopAddress,
      ShopContactNo,
      ShopName,
      Deposit
    } = data.InvoiceDetail


    let totalHeight = 975
    let headerHeight = 145
    let footerHeight = 145
    let contantHeight = totalHeight - headerHeight - footerHeight - 100;
    let customerDetailsTop = headerHeight
    let contantTop = headerHeight + 100 - 1
    let footerTop = headerHeight + 100 + contantHeight



    var htmlNew = InvoiceTable
    var html = htmlNew
      .replace("{COMPANY_NAME}", ShopName)
      .replace("{COMPANY_ADDRESS}", ShopAddress)
      .replace("{COMPANY_MOBILENUMER}", ShopContactNo)
      .replace("{BILL_NO}", BillNo)
      .replace(
        "{BOOKING_DATE}",
        BillDate
      )
      .replace("{CUSTOMER_NAME}", CustomerName)
      .replace("{CUSTOMER_ADDRESS}", CustomerAddress)
      .replace("{CUSTOMER_MOBILENUM1}", ContactNo1)
      .replace("{CUSTOMER_MOBILENUM2}", ContactNo2)
      .replace("{TOTALRENT}", TotalRent.toFixed(2))
      .replace("{DISCOUNT}", Discount.toFixed(2))
      .replace("{ADVANCE}", Advance.toFixed(2))
      .replace(
        "{PAYBLE_AMOUNT}",
        (parseInt(TotalRent) - parseInt(Advance) - parseInt(Discount)).toFixed(2)
      )
      .replace("{DEPOSITE}", "")
      .replace("{NOTE_1}", BillNotes)
      .replace("{REMARKS}", Remarks)
      .replace("{HEADER_HIGHT}", headerHeight)
      .replace("{CONTANT_HEIGHT}", contantHeight)
      .replace("{FOOTER_HEIGHT}", footerHeight)
      .replace("{CUSTOMER_DETAILS_TOP}", customerDetailsTop)
      .replace("{CONTANT_TOP}", contantTop)
      .replace("{FOOTER_TOP}", footerTop)
      .replace("{Deposit}", Deposit.toFixed(2))

    var itemHtmlNew = BookingItemHtml
    var itemHtml = ""
    let loop = ProductList.length == 1 ? 10 : ProductList.length == 2 ? 9 : ProductList.length == 3 || ProductList.length == 4 ? 7 : 7
    let temp = loop - parseInt(ProductList.length)
    for (let i = 0; i < ProductList.length; i++) {
      itemHtml += itemHtmlNew
        .replace("{RE_NO}", i + 1)
        .replace("{PRODUCT_CODE}", ProductList[i].ProductCode)
        .replace("{PRODUCT_NAME}", `(${ProductList[i].ProductTitle})`)
        .replace(
          "{DELIVERY}",
          dateFormat(ProductList[i].DeliveryDate.split("T")[0], "dd/MM/yyyy")
        )
        .replace("{DyanamicData}", ProductList[i]?.DyanamicData ? ProductList[i].DyanamicData : "")
        .replace(
          "{RETURN}",
          dateFormat(ProductList[i].ReturnDate.split("T")[0], "dd/MM/yyyy")
        )
        .replace("{RUPPES}", "₹")
        .replace("{DeliveryTimeSlot}", `${ProductList[i].DeliveryTimeSlot ?? ""}`)
        .replace("{ReturnTimeSlot}", `${ProductList[i].ReturnTimeSlot ?? ""}`)
        .replace("{RUPPES}", "₹")
        .replace("{RENT}", `${ProductList[i].Rent.toFixed(2)}`)
    }
    var tempData = BookingItemHtml
    for (let i = 0; i < temp; i++) {
      itemHtml += tempData
        .replace("{RE_NO}", "")
        .replace("{PRODUCT_CODE}", "")
        .replace("{PRODUCT_NAME}", "")
        .replace("{DELIVERY}", "")
        .replace("{RETURN}", "")
        .replace("{RENT}", "")
        .replace("{RUPPES}", "")
        .replace("{DyanamicData}", "")
        .replace("{DeliveryTimeSlot}", "")
        .replace("{ReturnTimeSlot}", "")
    }
    html = html.replace("{BOOKINT_ITEMS}", itemHtml)
    return html
  }
}

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { map } from "lodash"

//Import Image
import { dateFormat } from "pages/Common"
import { useState } from "react"

const InvoiceDetail = props => {
  const { state } = useLocation() // navigate data
  const [invoiceDetails, setInvoiceDetails] = useState(state?.data)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col lg="2"></Col>
            <Col lg="8">
              <Card style={{ width: "fit-content" }}>
                <CardBody className="p-3">
                  <div className="invoice-title mb-2">
                    <h4 className="text-center " style={{ fontWeight: "600", fontSize: "22px" }}>
                      {/* Bill No: {invoiceDetails.BillNo} */}
                      Bill Details
                    </h4>
                  </div>
                  <hr />
                  <Row>

                    <Col sm="5" className="text-sm-start">
                      <address style={{ marginBottom: "4px" }}> <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        Customer Details</span>
                        <br />
                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                          Name: </span>
                        <span style={{ fontSize: "14px" }}>{invoiceDetails?.Name}</span>
                        <br />
                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                          Contact: </span>
                        <span style={{ fontSize: "14px" }}>
                          {invoiceDetails?.ContactNo1} || {invoiceDetails?.ContactNo2}
                        </span>
                      </address>
                      <div>
                        <address> <span style={{ fontSize: "15px", fontWeight: "600" }}>
                          Address: </span>
                          <span style={{ fontSize: "14px" }}>{invoiceDetails?.CustomerAddress}</span>
                          <br />
                        </address>
                      </div>
                    </Col>
                    <Col sm="7" className="text-end">
                      <h4 style={{ marginBottom: "5px" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}> Bill No:</span> <span style={{ fontSize: "14px" }}> {invoiceDetails?.BillNo}</span>
                      </h4>
                      <address className="mb-0">
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          Date: </span>
                        <span style={{ fontSize: "14px" }}>
                          {dateFormat(
                            invoiceDetails?.BillDate.split("T")[0],
                            "dd/MM/yyyy"
                          )}</span>
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <Row className="py-2 mt-1">
                    <Col sm={4}><div >
                      <h3 className="font-size-16" style={{ fontWeight: "600" }}>Product Detail</h3>
                    </div></Col>
                    <Col sm={8} className="text-end" style={{ color: "#0F6E25" }}>
                      <h3 className="font-size-16" style={{ fontWeight: "500" }}> Deposit:
                        <span style={{ fontSize: "14px" }}>
                          <i className="mdi mdi-currency-inr"></i>
                          {invoiceDetails?.Deposit ? invoiceDetails?.Deposit?.toFixed(2) : "0.00"}
                        </span>
                      </h3>
                    </Col>

                  </Row>
                  <div className="table-responsive">
                    <Table className="table-nowrap" bordered>
                      <thead>
                        <tr>
                          <th className="custom-header" style={{ width: "20px" }}>No.</th>
                          <th className="custom-header" style={{ width: "60px" }}>Product Code</th>
                          <th className="custom-header" style={{ width: "60px" }}>Product Name</th>
                          <th className="custom-header" style={{ width: "70px" }}>Delivery Date</th>
                          <th className="custom-header" style={{ width: "70px" }}>
                            Return Date
                          </th>
                          <th className="custom-header text-center" style={{ width: "60px" }}>
                            Rent
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(invoiceDetails?.ProductList, (item, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item.ProductCode}</td>
                            <td>{item.ProductTitle}</td>
                            <td>
                              {dateFormat(
                                item.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                              )}{item.DeliveryTimeSlot ? (
                                <span> {` (${item.DeliveryTimeSlot})`}</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="text-sm-end">
                              {dateFormat(
                                item.ReturnDate.split("T")[0],
                                "dd/MM/yyyy"
                              )}{item.ReturnTimeSlot ? (
                                <span> {` (${item.ReturnTimeSlot})`}</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="text-sm-end">
                              <i className="mdi mdi-currency-inr"></i>
                              {item.Rent.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td rowSpan={6}
                            colSpan={4}
                            className="custom-header"
                          >
                            <span style={{ fontSize: "14px", fontWeight: "600" }}>Remarks :</span>
                            <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: invoiceDetails?.Remarks,
                              }}
                            />
                          </td>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            Total Rent
                          </td>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                        
                            <i className="mdi mdi-currency-inr"></i>
                            {invoiceDetails?.TotalRent.toFixed(2)}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            Advance
                          </td>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            
                            <i className="mdi mdi-currency-inr"></i>
                            {invoiceDetails?.Advance.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            Discount
                          </td>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            <i className="mdi mdi-currency-inr"></i>
                            {invoiceDetails?.Discount.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            Payble Amount
                          </td>
                          <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header">
                            <i className="mdi mdi-currency-inr"></i>
                            {(
                              parseInt(invoiceDetails?.TotalRent) -
                              parseInt(invoiceDetails?.Advance) -
                              parseInt(invoiceDetails?.Discount)
                            ).toFixed(2)}
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="2"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)

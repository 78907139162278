import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useLocation, useNavigate } from "react-router-dom"

//import action
// import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import breadcrumbs from "../../common/Breadcrumbs"
import { routes } from "routes/path"
import Earning from "./earning"
import LineBar from "./Linebar"
import IconComponent from "./IconComponent"
import Reminder from "./Reminder"
import { dateFormat } from "pages/Common"


const Dashboard = (props) => {
  const navigate = useNavigate()
  const { getApi } = useApi()
  let { pathname } = useLocation()
  const state = useLocation()
  const [screeLoad, setscreeLoad] = useState(true)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [dailyActivity, setdailyActivity] = useState(null)
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  const [details, setDetails] = useState([])

  useEffect(() => {
    setscreeLoad(true)
    getApi(`User/user-details?id=-1`).then(({ data, status }) => {
      if (status === 200) {
        setRoleName(data.RoleName)
        getApi(`Dashboard/dashboard-widget`, "", RoleName).then(
          ({ data, status }) => {
            if (status === 200) {
              setscreeLoad(false)
              setdailyActivity(data)
            } else {
              setscreeLoad(false)
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
          }
        )
        getApi(`Dashboard/dashboard-alert-widget`).then(({ data, status }) => {
          if (status === 200) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].Key == "PendingDelivery") {
                data[i].Title = "Delivery";
                data[i].Route = routes.delivery;
              } else if (data[i].Key == "PendingReturn") {
                data[i].Title = "Return";
                data[i].Route = routes.return;
              }
              else {
                data[i].Title = "Prepare Delivery";
                data[i].Route = routes.delivery;
              }
            }
            setDetails(data)
          }
        })
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [props.sucess, state, JSON.parse(localStorage.getItem("authUser"))?.SubAdmin == true])

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  document.title = `Dashboard | ${localStorage.getItem("ShopName")}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Row>
            <Col xl="12">
              <Row>
                {dailyActivity
                  ? dailyActivity.map((report, key) => (
                    <Col md="3" key={"_col_" + key}>
                      {/* <Card className="mini-stats-wid box-shadow"> */}
                      <Card>
                        <CardBody
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            {
                              report.Key == "TodaysBooking"
                                ? navigate(routes.bookingList)
                                : report.Key == "TodaysDelivery"
                                  ? navigate(routes.delivery)
                                  : report.Key == "TodaysReturn"
                                    ? navigate(routes.return)
                                    : report.Key == "TotalProductinCart"
                                      ? navigate(routes.cartlist)
                                      : report.Key == "TodaysInquiry"
                                        ? navigate(routes.inquiries)
                                        : report.Key == "PendingInquiry"
                                          ? navigate(routes.inquiries)
                                          : report.Key == "TotalUsers"
                                            ? navigate(routes.userList)
                                            : report.Key == "PendingFollowup"
                                            ? navigate(routes.inquiries)
                                            : report.Key == "NextFollowup"
                                            ? navigate(routes.inquiries)
                                            : report.Key == "SubscriptionExpiredInXDays" ? navigate(routes.subscription, {
                                              state: {
                                                date: true,
                                              },
                                            })
                                              : navigate(routes.dashboard)
                            }
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.DisplayName}
                              </p>
                              <h4 className="mb-0">{report.Value}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span
                                className={`avatar-title rounded-circle`}
                                style={{
                                  backgroundColor: `${report.Key == "TodaysBooking"
                                    ? `#27374D`
                                    : report.Key == "TodaysDelivery"
                                      ? `#27374D`
                                      : report.Key == "TodaysReturn"
                                        ? `#27374D`
                                        : `#27374D`
                                    }`,
                                }}
                              >
                                <IconComponent report={report} />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))
                  : null}
              </Row>
            </Col>
          </Row>
          {
            RoleName != "Admin" ? <Row>
              {(details || []).map(({
                Key,
                DisplayName,
                Title,
                TotalDays,
                Route,
                MinDate,
                MaxDate
              }, key) => (
                <Col lg={4} key={key} >
                  <Card className="mini-stats-wid">
                    <CardBody style={{ cursor: "pointer" }} onClick={() => { navigate(Route, { state: { fromData: "0001-01-01T00:00:00" == MinDate ? new Date(new Date().setDate(new Date().getDate() + 1)) : MinDate, todate: "0001-01-01T00:00:00" == MaxDate ? new Date(new Date().setDate(new Date().getDate() + 1)) : MaxDate } }) }}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          {/* h5 className="mb-0" */}
                          <h5>{Title}</h5>
                          <p className="text-muted fs-6 mb-0">{Key == "FutureDelivery" ? `${MinDate == "0001-01-01T00:00:00" || MaxDate == "0001-01-01T00:00:00" ? "No Orders to Prepare Next 7 days" : `You have ${DisplayName.split(" ")[0]} order on ${dateFormat(MaxDate.split("T")[0], "dd-MM-yyyy")} `}` : `Last ${TotalDays} day's record`} </p>
                        </div>
                      </div>
                    </CardBody>
                    <div className="card-body border-top py-3">
                      <p className={`mb-0 badge badge-soft-${Key == "PendingDelivery" || Key == "PendingReturn" ? "danger" : "success"} fs-6`}>
                        {DisplayName}</p>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row> : null
          }


          {RoleName == "Vendor" ? (
            <Row>
              <Col md={12}>
                <Reminder />
              </Col>
              <Col md={12}>
                <LineBar dataColors='["--bs-success","--bs-primary", "--bs-danger"]' />
              </Col>
              <Col md={12}>
                <Earning loader={setscreeLoad} />
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)

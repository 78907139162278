import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
    Card,
    CardBody,
    Form,
    Modal,
    ModalBody,
    Input,
    UncontrolledTooltip,
    FormGroup,
    ModalHeader,
    Button,
    Label,
    FormFeedback,
    Row,
    Col,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import breadcrumbs from "common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { toast } from "react-hot-toast"
import appConfig from "Api/app.config"

function Decrypt() {
    document.title = `Decrypt | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const [loading, setloading] = useState(false)
    const [decryptData, setDecryptData] = useState()

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            data: "",
            token: '',
        },
        validationSchema: Yup.object({
            data: Yup.string().required("The data is required"),
            token: Yup.string().required("The token is required"),
        }),
        onSubmit: async values => {
     

            var myHeaders = new Headers()
            myHeaders.append("Content-type", "application/json")

            var raw = JSON.stringify({
                data: values.data,
                token: values.token,
            })
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            }
            let res = await fetch(
                `${appConfig.BASE_URL}/Encryption/decrypt`,
                requestOptions
            ).then(async response => {
                if (response.status === 200) {
                    return {
                        data: await response.json(),
                        status: response.status,
                    }
                } else {
                    return {
                        data: await response.text(),
                        status: response.status,
                    }
                }
            })
            const { data, status } = res
            if (status == 200) {
                setloading(false)
                setDecryptData(data)
            } else {
                setloading(false)
                setDecryptData('')
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }

        },
    })


    return (
        <div className="page-content">
            <div className="container-fluid">
                {loading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}>
                            <Row>
                                <FormGroup className="mb-2">
                                    <Label
                                        htmlFor="validationCustom04"
                                        className="required"
                                    >
                                        Data
                                    </Label>
                                    <Input
                                        name="data"
                                        rows="4"
                                        placeholder="Enter your obj"
                                        type="textarea"
                                        className="form-control-sm"
                                        id="validationCustom04"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.data}
                                        invalid={
                                            validation.touched.data &&
                                                validation.errors.data
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.data &&
                                        validation.errors.data ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.data}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="mb-2">
                                    <Label
                                        htmlFor="validationCustom04"
                                        className="required"
                                    >
                                        Token
                                    </Label>
                                    <Input
                                        name="token"
                                        rows="4"
                                        placeholder="Enter your token"
                                        type="textarea"
                                        className="form-control-sm"
                                        id="validationCustom04"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.token}
                                        invalid={
                                            validation.touched.token &&
                                                validation.errors.token
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.token &&
                                        validation.errors.token ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.token}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col md="12" className="text-end ">
                                    <Button
                                        color="secondary"
                                        className="btn btn-primary me-2"
                                        type="reset"
                                    >
                                        Reset
                                    </Button>

                                    <Button
                                        color="primary"
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <Row>
                            <h3>Output</h3>
                        </Row>

                        <Row>
                            <Col md={12}>
                                {decryptData}
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </div>
        </div >
    )
}

Decrypt.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    onFilterChange: PropTypes.func,
}

export default Decrypt

/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { dateFormat } from "../../Common"
import toast from "react-hot-toast"
import { BookingItemHtml, InvoiceTable } from "pages/Reports/Booking/table"
import jsPDF from "jspdf"
import { font } from "pages/Reports/Booking/font"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"

function ViewBooking() {
  const doc = new jsPDF({
    format: "a5",
    unit: "px",
    filters: ["ASCIIHexEncode"],
  })
  doc.addFileToVFS("NotoSansGujarati-Regular.ttf", font)
  doc.addFont(
    "NotoSansGujarati-Regular.ttf",
    "NotoSansGujarati-Regular",
    "normal"
  )
  doc.setFont("NotoSansGujarati-Regular")

  const [flag, setFlag] = useState(false)
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const { state } = useLocation() // navigate data
  const { getApi, postApi, putApi } = useApi()

  const [ProductDetails, setProductDetails] = useState([]) // product lookup
  const [TimeSolteDetails, setTimeSolteDetails] = useState([]) // timeslote lookup

  useEffect(() => {
    getApi("TimeSlot/color-lookup").then(({ data, status }) => {
      if (status === 200) {
        setTimeSolteDetails(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

  }, [])

  // table state management
  const [tableData, setTableData] = useState(
    state ? state.data.BookingProductList : []
  )

  // Delete Booking handler
  const deleteHandle = (ProductCode, Rent) => {
    const updatedArray = tableData
      .map(obj => (obj.ProductCode !== ProductCode ? obj : null))
      .filter(Boolean)
    setTableData(updatedArray)
    validation.setFieldValue("Rent", parseInt(totalRent) - parseInt(Rent))
  }

  // Edit mode handler
  const [isEdit, setisEdit] = useState(false) //flag for editing
  const editHandle = editData => {
    if (state && state?.isEdit) {
      editData = {
        ...editData,
        DeliveryDate: editData.DeliveryDate?.split("T")[0],
        ReturnDate: editData.ReturnDate?.split("T")[0],
        ProductId: editData.ProductId.toString(),
      }
    }
    setisEdit(true)
    setdata(editData)
  }

  // Booking Validations
  const validation = useFormik({
    enableReinitialize: false,

    initialValues: {
      id: state ? state?.data.Id : 1,
      BillNo: state ? state?.data.BillNo : "",
      Name: state ? state?.data.Name : "",
      remark: state ? state?.data.Remarks : "",
      Rent: state ? state?.data.TotalRent : "",
      Advance: state ? state?.data.Advance : 0,
      ContactNo1: state ? state?.data.ContactNo1 : "",
      ContactNo2: state ? state?.data.ContactNo2 : "",
      discount: state ? state?.data.Discount : "",
      Address: state ? state?.data.Address : "",
    },
    validationSchema: Yup.object({
      BillNo: Yup.string().required("The Bill no. is required"),
      ContactNo1: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("Contact Number is required"),
      ContactNo2: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("Contact Number is required"),
      Name: Yup.string().required("The Name is required"),
      Address: Yup.string().required("The Address is required"),
      Rent: Yup.number().min(1, "Value must be greater than zero"),
    }),
    onSubmit: values => {
      let a = []

      tableData.map(item => {
        a.push({
          id: 0,
          ProductId: parseInt(item.ProductId),
          Rent: item.Rent,
          DeliveryDate: item.DeliveryDate,
          ReturnDate: item.ReturnDate,
          ReturnTimeSlot: item.ReturnTimeSlot,
          DeliveryTimeSlot: item.DeliveryTimeSlot,
        })
      })
      if (state?.isEdit) {
        putApi("Booking/update", {
          id: state?.data.Id,
          name: values.Name,
          contactNo1: values.ContactNo1,
          contactNo2: values.ContactNo2,
          totalRent: totalRent,
          advance: values.Advance,
          discount: values.discount,
          remarks: values.remark,
          updateBookingItems: a,
          Address: values.Address,
        }).then(({ data, status }) => {
          if (status === 200) {
            setLoader(false)
            toast.success("Successfully Done", {
              style: {
                fontSize: "15px",
              },
            })
            navigate(routes.bookingList)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      } else {
        toast.loading("Waiting for the action to complete...")
        postApi("Booking/create", {
          billNo: values.BillNo,
          name: values.Name,
          contactNo1: values.ContactNo1,
          contactNo2: values.ContactNo2,
          totalRent: totalRent,
          advance: Number(values.Advance),
          discount: Number(values.discount),
          remarks: values.remark,
          createBookingItems: a,
          address: values.Address,
        }).then(({ data, status }) => {
          if (status === 200) {
            if (flag) {
              handleSaveprint(data)
            } else {
              toast.dismiss()
              toast.success("Booking successfully create", {
                style: {
                  fontSize: "15px",
                },
              })
              navigate(routes.bookingList)
            }
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    },
  })

  // validation Function for existing Item
  const exitInBookingItem = (arr, obj) => {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (item.ProductCode === obj.ProductCode) {
        return true
      }
    }
    return false
  }

  // product Details initialValues
  const [data, setdata] = useState({
    ProductId: 0,
    ProductCode: "",
    DeliveryDate: "",
    ReturnDate: "",
    Rent: "",
    ReturnTimeSlot: "",
    DeliveryTimeSlot: "",
    ProductName: "",
  })

  // Product Detilas validation
  const productDetailsValidation = useFormik({
    enableReinitialize: true,

    initialValues: data,
    validationSchema: Yup.object({
      ProductId: Yup.string().required("The Product is required"),
      ProductCode: Yup.string().required("The ProductCode is required"),
      DeliveryDate: Yup.string().required("The Delivery date is required"),
      ReturnDate: Yup.string().required("The Return date is required"),
      Rent: Yup.string().required("The Rent is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedArray = tableData.map(item =>
          item.ProductCode == data.ProductCode ? (item = values) : item
        )
        validation.setFieldValue(
          "Rent",
          parseInt(totalRent) + parseInt(values.Rent) - parseInt(data.Rent)
        )
        settotalRent(
          parseInt(totalRent) + parseInt(values.Rent) - parseInt(data.Rent)
        )
        setTableData(updatedArray)
        productDetailsValidation.handleReset()
        setdata({
          ProductId: 0,
          ProductCode: "",
          DeliveryDate: "",
          ReturnDate: "",
          Rent: "",
        })
        setisEdit(false)
        return false
      } else {
        let a = exitInBookingItem(tableData, values)
        if (!a) {
          setTableData([...tableData, values])
          validation.setFieldValue(
            "Rent",
            parseInt(totalRent) + parseInt(values.Rent)
          )
          settotalRent(parseInt(totalRent) + parseInt(values.Rent))
          productDetailsValidation.handleReset()
          setdata({
            ProductId: 0,
            ProductCode: "",
            DeliveryDate: "",
            ReturnDate: "",
            Rent: "",
          })
        } else {
          toast.error("Product is already has been list", {
            style: {
              fontSize: "15px",
            },
          })
        }
      }
    },
  })

  // multiple Booking Table
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Product Code
          </div>
        ),
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Name
          </div>
        ),
        accessor: "ProductName",
        Cell: ({ row }) => {
          return <div>{row.original.ProductName}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Delivery
          </div>
        ),
        accessor: "DeliveryDate",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>{dateFormat(row.original.DeliveryDate, "dd/MM/yyyy")}</div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Return
          </div>
        ),
        accessor: "ReturnDate",
        width: "7%",
        Cell: ({ row }) => {
          return <div>{dateFormat(row.original.ReturnDate, "dd/MM/yyyy")}</div>
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "10%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Rent.toFixed(2)}
            </div>
          )
        },
      },
    ],
    [tableData]
  )

  // Auto select feild Code Product Rent
  const autoSelect = (e, name) => {
    if (name == "ProductCode") {
      let TargetValue = e
      const filterData = ProductDetails.filter(item => item.Code == TargetValue)
      if (filterData.length) {
        setdata({
          ...data,
          ProductCode: TargetValue,
          ProductId: filterData[0].Id,
          Rent: filterData[0].Rent,
        })
      } else {
        setdata({
          ...data,
          ProductCode: TargetValue,
          ProductId: 0,
          Rent: "",
        })
      }
    } else {
      let TargetValue = e
      const filterData = ProductDetails.filter(item => item.Id == TargetValue)
      setdata({
        ...data,
        ProductCode: filterData[0].Code,
        ProductId: TargetValue,
        Rent: filterData[0].Rent,
        ProductName: filterData[0].Name,
      })
    }
  }

  if (loader) {
    return (
      <React.Fragment>
        <div className="page-content">Loading...</div>
      </React.Fragment>
    )
  }
  // Total Rent
  const [totalRent, settotalRent] = useState(state ? state?.data?.TotalRent : 0)

  // handle Save and Print event

  const InvoiceDetails = async id => {
    return await getApi(`Booking/invoice-detail?id=${id}`)
  }

  const handleSaveprint = async id => {
    let { data, status } = await InvoiceDetails(id)

    if (status == 200) {
      let {
        Advance,
        BillDate,
        BillNo,
        BillNoteList,
        ContactNo1,
        ContactNo2,
        Discount,
        Name,
        ProductList,
        TotalRent,
      } = data

      var invoiceHtml = InvoiceTable.replace("{COMPANY_NAME}", "whitecore")
        .replace("{COMPANY_ADDRESS}", "g302")
        .replace("{COMPANY_MOBILENUMER}", "8200478318")
        .replace("{BILL_NO}", BillNo)
        .replace(
          "{BOOKING_DATE}",
          dateFormat(BillDate.split("T")[0], "dd/MM/yyyy")
        )
        .replace("{CUSTOMER_NAME}", Name)
        .replace("{CUSTOMER_ADDRESS}", "11")
        .replace("{CUSTOMER_MOBILENUM1}", ContactNo1)
        .replace("{CUSTOMER_MOBILENUM2}", ContactNo2)
        .replace("{DISCOUNT}", Discount)
        .replace("{ADVANCE}", Advance)
        .replace("{PAYBLE_AMOUNT}", TotalRent)
        .replace("{DEPOSITE}", "56")
        .replace("{NOTE_1}", BillNoteList[0].DisplayName)
        .replace("{NOTE_2}", BillNoteList[0].DisplayName)
        .replace("{NOTE_3}", BillNoteList[0].DisplayName)
        .replace("{NOTE_4}", BillNoteList[0].DisplayName)

      var itemHtml = ""
      let temp = 10 - parseInt(ProductList.length)
      for (let i = 0; i < ProductList.length; i++) {
        itemHtml += BookingItemHtml.replace("{RE_NO}", i + 1)
          .replace("{PRODUCT_CODE}", i)
          .replace("{PRODUCT_NAME}", ProductList[i].ProductTitle)
          .replace(
            "{DELIVERY}",
            dateFormat(ProductList[i].DeliveryDate.split("T")[0], "dd/MM/yyyy")
          )
          .replace(
            "{RETURN}",
            dateFormat(ProductList[i].ReturnDate.split("T")[0], "dd/MM/yyyy")
          )
          .replace("{RUPPES}", "₹")
          .replace("{RENT}", ProductList[i].Rent)
      }
      for (let i = 0; i < temp; i++) {
        itemHtml += BookingItemHtml.replace("{RE_NO}", i + 1)
          .replace("{PRODUCT_CODE}", "")
          .replace("{PRODUCT_NAME}", "")
          .replace("{DELIVERY}", "")
          .replace("{RETURN}", "")
          .replace("{RENT}", "")
          .replace("{RUPPES}", "")
      }
      invoiceHtml = invoiceHtml.replace("{BOOKINT_ITEMS}", itemHtml)

      doc.html(invoiceHtml, {
        async callback(doc) {
          // await doc.output('blob') doc.save('document');
          await doc.autoPrint()
          window.open(doc.output("bloburl"), "_blank")
          // doc.output('dataurlnewwindow');
          // await doc.output('pdfobjectnewwindow')
        },
        margin: [10, 10, 10, 10],
      })
      navigate(routes.bookingList)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            paRent="Booking"
            breadcrumbItem={"View Booking"}
            paRentLink={routes.bookingList}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody></CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewBooking

// sagas.js

import { takeLatest, put, call } from 'redux-saga/effects';

import { addToCartFailure, addToCartSuccess, financeAmountFailure, financeAmountSuccess } from './action';
import { ADD_TO_CART_REQUEST, FINANCE_AMOUNT_REQUEST } from './actionType';


// Simulated API call to add item to the cart
const apiAddToCart = (item) => {
    return item
};
const financeAmount = (item) => {
    return item
};

// Worker saga that performs the add to cart operation
function* addToCartSaga(action) {
    try {
        yield call(apiAddToCart, action.payload); // Call the API to add item to the cart
        yield put(addToCartSuccess()); // Dispatch success action
        yield call(financeAmount, action.payload); // Call the API to add item to the cart
        yield put(financeAmountSuccess());
        yield put(financeAmountFailure(error));
    } catch (error) {
        yield put(addToCartFailure(error)); // Dispatch failure action
    }
}

function* financeAmountSaga(action) {
    try {
        yield call(financeAmount, action.payload); // Call the API to add item to the cart
        yield put(financeAmountSuccess());
    } catch (error) {
        yield put(financeAmountFailure(error));
    }
}

// Watcher saga that listens for ADD_TO_CART_REQUEST actions
function* watchAddToCart() {
    yield takeLatest(ADD_TO_CART_REQUEST, addToCartSaga);
    yield takeLatest(FINANCE_AMOUNT_REQUEST, financeAmountSaga);
}

export default function* rootSaga() {
    yield ([
        watchAddToCart(),
        // Other sagas...
    ]);
}

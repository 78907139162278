import { dateFormat } from 'pages/Common';
import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody } from 'reactstrap';
import PaymentHistoryTable from './PaymentHistoryTable';
import useApi from 'common/ApiMiddlewere';
import { toast } from 'react-hot-toast';

const PaymentHistory = () => {
    const { getApi } = useApi()
    const [isLoading, setIsLoading] = useState(false)
    const [tableData, setTableData] = useState([]
    )

    useEffect(() => {
        setIsLoading(true)
        getApi("SubscriptionLogs/Plan-History", "").then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }, [])


    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Plan Name",
                accessor: "PlanName",
            },
            {
                // Header: "Plan Amount",
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Plan Amt.
                    </div>
                ),
                accessor: "Amount",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Amount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Discount
                    </div>
                ),
                accessor: "Discount",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {row.original.Discount == null || row.original.Discount == ""
                                ? "0.00"
                                : row.original?.Discount?.toLocaleString('en-IN', { currency: 'INR' }) + ".00"}
                        </div>
                    )
                },
            },
            {
                Header: "Activation Date",
                accessor: "ActivationDate",
                width: "9%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.ActivationDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original?.ActivationDate?.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Start Date",
                accessor: "StartDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.StartDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original?.StartDate?.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
            {
                Header: "End Date",
                accessor: "EndDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.EndDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original?.EndDate?.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
            {
                Header: "Remark",
                accessor: "Remarks",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.Remarks ? row.original?.Remarks: "N/A" }
                    </p>
                ),
            },

        ],
        [tableData]
    )

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h5 className="mb-1 page-title-box" style={{ fontSize: "16px", fontWeight: "600", color: "#25292d" }}>Payment History</h5>
                    <PaymentHistoryTable
                        columns={columns}
                        data={tableData}
                        isLoading={isLoading}
                        className="custom-header-css"
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default PaymentHistory
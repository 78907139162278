// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import GroomWellProductTable from "./GroomWellProductTable"
import { dateFormat } from "pages/Common"
import { GetObjByVerificationStatus, GetObjByTargetGender } from "_mock/Static_data"
import UpdateGroomWellProduct from "./UpdateGroomWellProduct"

function GroomWellProduct() {
    let { pathname } = useLocation()
    const { deleteApi, postApi, putApi, getApi } = useApi()
    document.title = `GroomWell Products | ${localStorage.getItem("ShopName")}`
    const navigate = useNavigate()

    // useStates
    const [tableData, setTableData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [TotalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [categories, setCategories] = useState([])
    const [dispalyStart, setDisplayStart] = useState(1)
    const [categoryId, setCategoryId] = useState(0)
    const [modal, setModal] = useState(false)
    const [editProductId, setEditProductId] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [productData, setProductData] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        getApi("GroomWell/category-mapping-list").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    GWCategoryName: "Select category",
                    GWCategoryId: 0
                });
                setCategories(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })

    }, [])

    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue, categoryId) => {
        setSearchText(serchValue == undefined ? "" : serchValue)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setRowsPerPage(pageSize)
        setCategoryId(categoryId)
    }

    // productList Api
    const productListApi = () => {
        setIsLoading(true)
        postApi("GroomWell/product-list", {
            DisplayStart: dispalyStart,
            PageSize: rowsPerPage,
            SearchText: searchText,
            CategoryId: parseInt(categoryId),
            SortColumn: "",
            SortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data)
                setTotalRecords(data.length)
                setIsLoading(false)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        productListApi()
    }, [dispalyStart, rowsPerPage, searchText, categoryId])


    // product delete handler
    const handleDelete = productId => {
        setIsLoading(true)
        if (productId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this product permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`GroomWell/delete-product?productId=${productId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== productId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("Product sucessfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Product",
                                    text: data,
                                })
                            }
                            setIsLoading(false)
                        }
                    )
                } else {
                    setIsLoading(false)
                }
            })
        }
    }

    const toggle = () => {
        setModal(!modal)
        setIsEdit(!isEdit)
    }

    // product edit Id handler
    const editRow = (productId, value) => {
        if (productId) {
            setProductData(value)
            setEditProductId(productId)
            toggle()
            setIsEdit(true)
        }
    }

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Image",
                accessor: "MainImageUrl",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <>
                            <div>
                                <div className="d-flex">
                                    <FormGroup>
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                border: "1px solid #0000004d",
                                                height: "77px",
                                                width: "65px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "2%",
                                            }}
                                        >
                                            <>
                                                <img
                                                    style={{
                                                        cursor: "pointer", height: "75px",
                                                        width: "65px",
                                                    }}
                                                    src={row.original.MainImageUrl}
                                                />
                                            </>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Title",
                accessor: "Title",
                // width: "8%",
            },
            {
                Header: "Category",
                accessor: "CategoryName",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.CategoryName ? row.original.CategoryName : "N/A"}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Color",
                accessor: "ColorName",
                width: "6%",
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div className="ColorCode_box" style={{ backgroundColor: row.original.ColorCode, marginTop: "2px" }}></div>
                            <span>
                                {row.original.ColorName ? row.original.ColorName : "N/A"}
                            </span>
                        </div>
                    </>
                ),
            },
            {
                Header: "Target Gender",
                accessor: "TargetGender",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {GetObjByTargetGender(row.original.TargetGender)[0].DisplayName}
                    </span>
                ),
            },
            {
                Header: "Verification Status",
                accessor: "VerificationStatus",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {GetObjByVerificationStatus(row.original.VerificationStatus)[0].DisplayName}
                    </span>
                ),
            },
            {
                Header: "Created On",
                accessor: "CreatedOn",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.CreatedOn.split("T")[0] == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original.CreatedOn, "dd/MM/yyyy")}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            {
                                row.original.IsRetire == true ? (
                                    <>
                                        <li className="mytooltip">
                                            <i
                                                className=" fs-6 fas fa-edit"
                                                style={{ cursor: "not-allowed", color: "#808080b3" }}
                                                id={`edittooltip${row.original.Id}`}
                                            ></i>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="mytooltip">
                                            <i
                                                className=" fs-6 fas fa-edit btn-edit"
                                                id={`edittooltip${row.original.Id}`}
                                                onClick={() => editRow(row.original.Id, row.original)}
                                            ></i>
                                            <span className="mytext">Edit</span>
                                        </li>
                                    </>
                                )
                            }


                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.Id}`}
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, TotalRecords]
    )
    // redirect create product handler
    const handleUserClicks = () => {
        navigate(routes.createProduct)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <GroomWellProductTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={true}
                            handleUserClick={handleUserClicks}
                            customPageSize={20}
                            className="custom-header-css"
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            categories={categories}
                            categoryId={categoryId}
                            setCategoryId={setCategoryId}
                        />
                    </CardBody>
                </Card>
                {isEdit &&
                    <UpdateGroomWellProduct
                        toggle={toggle}
                        modal={modal}
                        editProductId={editProductId}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        productData={productData}
                        listApi={productListApi}
                    />
                }
            </div>
        </div>
    )
}
GroomWellProduct.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default GroomWellProduct

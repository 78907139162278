import useApi from "common/ApiMiddlewere"
import { InvoiceTable } from "./UserTable"
import { dateFormat } from "pages/Common"

export const UserInvoicePdf = async (id, getApi) => {
  let { data, status } = await getApi(`User/customer-invoice?id=${id}`)
  if (status == 200) {
    let {
      CompanyName,
      Address,
      ContactNo1,
      ContactNo2,
      BillNo,
      CreatedOn,
      ShopName,
      CustomerAddress,
      CustomerContactNo,
      ProductName,
      Qty,
      Rate,
      GST,
      Amount,
      TotalAmount,
      Discount,
      PaybleAmount,
      Website,
      CompanyEmail,
    } = data

    var htmlNew = InvoiceTable
    var html = htmlNew
      .replace("{COMPANY_NAME}", CompanyName)
      .replace("{COMPANY_ADDRESS}", Address)
      .replace("{CONTACTNO1}", ContactNo1)
      .replace("{CONTACTNO2}", ContactNo2 ? '| +91' + ContactNo2 : '')
      .replace("{BILL_NO}", BillNo)
      .replace(
        "{CREATE_DATE}",
        dateFormat(CreatedOn.split("T")[0], "dd/MM/yyyy")
      )
      .replace("{SHOP_NAME}", ShopName)
      .replace("{CUSTOMER_ADDRESS}", CustomerAddress)
      .replace("{CUSTOMER_CONTACTNO1}", CustomerContactNo)
      .replace("{PRODUCT_NAME}", ProductName)
      .replace("{QTY}", Qty)
      .replace("{RATE}", Rate.toFixed(2))
      .replace("{GST}", GST.toFixed(2))
      .replace("{AMOUNT}", Amount.toFixed(2))
      .replace("{TOTAL_AMOUNT}", TotalAmount.toFixed(2))
      .replace("{DISCOUNT}", Discount.toFixed(2))
      .replace("{PAYBLE_AMOUNT}", PaybleAmount.toFixed(2))
      .replace("{WEBSITE}", Website)
      .replace("{COMPANYEMAIL}", CompanyEmail)
    return html
  }
}

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import { useState } from "react"

const PaymentHistoryTable = ({
  columns,
  data,
  className,
  isLoading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const handlesort = column => { }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }


  return (
    <Fragment>
      <div className="table-responsive react-table">
        <Table bordered  {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        const Row = cell.row.original
                        return (
                          <td
                            key={cell.id}
                            className={`${data[0] == Row ? "deliverd-status" : ""} text-nowrap`}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

    </Fragment>
  )
}

PaymentHistoryTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PaymentHistoryTable

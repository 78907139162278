import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, FormGroup, Label } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
    filterData,
    value,
    setValue,
}) {
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
        filterData(value)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search..."}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const IncomeExpenceTable = ({
    columns,
    data,
    className,
    isLoading,
    totalIncome,
    totalExpense,
    selectedOptions
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                // pageIndex: 0,
                pageSize: 1000,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )

    const handlesort = column => {
    }

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    return (
        <Fragment>
            <div className="table-responsive react-table">
                <Table bordered className={className}>
                    <thead className="table-light table-nowrap">
                        <tr style={{ textAlign: "center", fontSize: "16px", fontWeight: "500" }}>
                            <td className="border-Right" colSpan={`${selectedOptions.length == 1 ? 4 : selectedOptions.length == 2 ? 3 : selectedOptions.length == 3 ? 2 : 5}`}>
                                Incomes
                            </td>
                            <td colSpan={`${selectedOptions.length == 1 ? 4 : selectedOptions.length == 2 ? 3 : selectedOptions.length == 3 ? 2 : 5}`}>
                                Expense
                            </td>
                        </tr>
                        {headerGroups.map(headerGroup => (
                            < tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} >
                                {
                                    headerGroup.headers.map(column => {
                                        return (
                                            <th
                                                key={column.id}
                                                onClick={() => {
                                                    handlesort(column)
                                                }}
                                                className={` ${column.Header == 'Amount' ? "aline-right" : (column.Header == 'Name' && selectedOptions?.includes("Name")) ? 'column-display' : (column.Header == 'Details' && selectedOptions?.includes("Details")) ? 'column-display' : (column.Header == 'Mode' && selectedOptions?.includes("Mode")) ? 'column-display' : ""} `}
                                                {...column.getHeaderProps({
                                                    style: {
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                        width: column.width,
                                                        borderRight: column.borderRight,
                                                        textAline: column.textAline,
                                                        columnvisibility: column.visibility,
                                                    },
                                                })}
                                            >
                                                <div className={`mb-0`}>
                                                    {column.render("Header")}
                                                    {generateSortingIndicator(column)}
                                                </div>
                                            </th>
                                        )
                                    }


                                    )
                                }
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr className="">
                                            {row.cells.map((cell, columnIndex) => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className={`text-nowrap ${columnIndex == 4 ? "border-Right" : (cell.column.Header == 'Name' && selectedOptions?.includes("Name")) ? 'column-display' : (cell.column.Header == 'Details' && selectedOptions?.includes("Details")) ? 'column-display' : (cell.column.Header == 'Mode' && selectedOptions?.includes("Mode")) ? 'column-display' : ""} `}
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                                borderRight: cell.borderRight,
                                                                textAline: cell.textAline,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>

                                    </Fragment>
                                )
                            })}
                            <tr>
                                <td colSpan={`${selectedOptions.length == 1 ? 3 : selectedOptions.length == 2 ? 2 : selectedOptions.length == 3 ? 0 : 4}`} style={{ textAlign: "end", fontWeight: "600" }}>
                                    Total Income
                                </td>
                                <td className="border-Right" style={{ textAlign: "end", fontWeight: "600" }}>
                                    <i className="mdi mdi-currency-inr"></i>
                                    {new Intl.NumberFormat('en-IN', {
                                        currency: 'INR',
                                        minimumFractionDigits: 2,
                                    }).format(Math.round(totalIncome) || 0)}
                                </td>
                                <td colSpan={`${selectedOptions.length == 1 ? 3 : selectedOptions.length == 2 ? 2 : selectedOptions.length == 3 ? 0 : 4}`} style={{ textAlign: "end", fontWeight: "600" }}>
                                    Total Expense
                                </td>
                                <td style={{ textAlign: "end", fontWeight: "600" }}>
                                    <i className="mdi mdi-currency-inr"></i>
                                    {new Intl.NumberFormat('en-IN', {
                                        currency: 'INR',
                                        minimumFractionDigits: 2,
                                    }).format(Math.round(totalExpense) || 0)}
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? "Loading..." : "No Record Found"}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>


        </Fragment >
    )
}

IncomeExpenceTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default IncomeExpenceTable

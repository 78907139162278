// actions.js

import { ADD_TO_CART_FAILURE, ADD_TO_CART_REQUEST, ADD_TO_CART_SUCCESS,FINANCE_AMOUNT_FAILURE, FINANCE_AMOUNT_REQUEST, FINANCE_AMOUNT_SUCCESS } from "./actionType";

export const addToCartRequest = (item) => ({
    type: ADD_TO_CART_REQUEST,
    payload: item,
});

export const addToCartSuccess = () => ({
    type: ADD_TO_CART_SUCCESS,
});

export const addToCartFailure = (error) => ({
    type: ADD_TO_CART_FAILURE,
    payload: error,
});

export const financeAmountRequest = (item) => ({
    type: FINANCE_AMOUNT_REQUEST,
    payload: item,
});

export const financeAmountSuccess = () => ({
    type: FINANCE_AMOUNT_SUCCESS,
});

export const financeAmountFailure = (error) => ({
    type: FINANCE_AMOUNT_FAILURE,
    payload: error,
});
import React, { useState } from 'react';
import { Button, Card, Col, Container, Input, Label, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import logoDark from '../assets/images/fulllogo1.png';
import logo from '../assets/images/logo/logo.png';
import { routes } from 'routes/path';

const AllPlans = () => {
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState(1);
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)

    const handlePriceChange = (e) => {
        setSelectedPlan(parseInt(e.target.value));
    };
    const handleButtonClick = () => {
        navigate('/inquiry');
    };
    const titleStyle = { color: "black", fontWeight: "bold" }
    const plans = [
        {
            id: 1,
            name: 'Free',
            duration: '3 Month',
            price: 'Free',
            originalPrice: '5000/12 month',
            description: "Get your trial today it's absolutely free.",
        },
        {
            id: 2,
            name: 'Basic',
            duration: '12 Month',
            price: '15,000',
            originalPrice: '17000/12 month',
            description: 'Get your first year subscription. It is the most popular.',
        },
        {
            id: 3,
            name: 'Add-on Accounting',
            duration: '12 Month',
            price: '4,000',
            originalPrice: '5000/12 month',
            description: 'Basic + Accounting',
        },
        {
            id: 4,
            name: 'Add-on Sale',
            duration: '12 Month',
            price: '4,000',
            originalPrice: '5000/12 month',
            description: 'Basic + Sale',
        },
        {
            id: 5,
            name: 'Full Plan',
            duration: '12 Month',
            price: '20,000',
            originalPrice: '23,000/12 month',
            description: "Basic + Accounting + Sale",
        },
    ];

    return (
        <>
            <div>
                <nav className="navbar bg-white">
                    <a className="navbar-brand" style={{ marginLeft: '8px' }} href="#">
                        <span className="logo-lg m-2 ">
                            <img src={logoDark} width="180px" alt="" />
                        </span>
                    </a>
                    <div className="navbar-brand" >
                        <div>
                            <span style={{ fontWeight: "500", fontSize: "15px" }}> <i className='mdi mdi-phone-dial-outline'></i> </span> <span style={{fontSize:"13px"}}>    +91 7600763090, +91 8905560307</span>
                        </div>
                        <div className=''>
                            <span style={{ fontWeight: "500", fontSize: "15px" }}> <i className=' mdi mdi-email-outline'></i>  </span> <span style={{fontSize:"13px"}}>   info@whitecoretechnology.com </span>
                        </div>

                    </div>
                </nav>
                <div className="page-content-plans">
                    <Container fluid>
                        <section className="container">
                            <Row className="d-flex  justify-content-center">
                                <Col className="" md={7} sm={12}>
                                    <div className="d-flex justify-content-start">
                                        <div className="u-text u-text-1 mb-3" style={{ color: '#2c2b2bcf' }}>
                                            Our Plans
                                        </div>
                                    </div>
                                    <div style={{ fontSize: "15px", textAlign: "justify" }}>

                                        <div style={titleStyle}>Separate Logins:</div>
                                        <div className='our-plans-pra'>Effortlessly manage your shop using two distinct logins: one for vendors and the other for salesmen.</div>


                                        <div style={titleStyle}>Efficient Shop Setup:</div>
                                        <div className='our-plans-pra'>Import the necessary masters such as categories, colors, sizes, and products.</div>

                                        <div style={titleStyle}>Product Availability:</div>
                                        <div className='our-plans-pra'>Quickly check the availability of your products.</div>

                                        <div style={titleStyle}>Swift Bookings:</div>
                                        <div className='our-plans-pra'>Efficiently manage your cart for swift bookings.</div>

                                        <div style={titleStyle}>Printable Bills:</div>
                                        <div className='our-plans-pra'>Generate and download printable bills.</div>

                                        <div style={titleStyle}>Insightful Dashboard:</div>
                                        <div className='our-plans-pra'>Access valuable information on your dashboard, including today's bookings, deliveries, returns, and the total number of products in the cart.</div>

                                        <div style={titleStyle}>Washing Product:</div>
                                        <div className='our-plans-pra'>Enjoy the convenience of a washing product functionality.</div>

                                        <div style={titleStyle}>Diverse Data Reports:</div>
                                        <div className='our-plans-pra'>Access a variety of reports based on your data, including delivery, return, product history, sales, and salesman reports.</div>
                                    </div>
                                </Col>
                                <Col md={5} sm={12}>
                                    {plans.map((plan) => (
                                        <>
                                            <Row key={plan.id} >
                                                <label htmlFor={`specifyColor${plan.id}`}>
                                                    <Card className='card-border' >
                                                        <Row className='d-flex' style={{ paddingTop: "16px", paddingLeft: "16px", paddingRight: "16px" }}>
                                                            <Col md={6} xs={4}>
                                                                <div>
                                                                    <Label
                                                                        htmlFor={`specifyColor${plan.id}`}
                                                                        className="radio-lable text-black"
                                                                        style={{ fontSize: '18px' }}
                                                                    >
                                                                        {plan.name}
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col md={6} xs={7}>
                                                                <div className="text-end mt-1">
                                                                    <h1 style={{ fontSize: '15px', color: 'black', fontWeight: '600', marginBottom: '0px', marginTop: '0px' }}>
                                                                        <span style={{ wordSpacing: "-5px" }}> {plan.id == 1 ? "" : <i className="mdi mdi-currency-inr"></i>} {plan.price}</span> <span style={{ fontSize: '12px', fontWeight: '500' }}>{`/${plan.duration}`}</span>
                                                                    </h1>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex' style={{ paddingBottom: "16px", paddingLeft: "16px", paddingRight: "16px" }}>
                                                            <Col className="" xs={11} md={8}>
                                                                <div>{plan.description}</div>
                                                            </Col>
                                                            <Col className="" xs={11} md={4}>
                                                                <div className={`${isMobile ? 'text-start' : 'text-end'} text-black`}>
                                                                    <s>
                                                                        <i className="mdi mdi-currency-inr" />
                                                                        <span style={{ fontSize: '12px', fontWeight: '500' }}>{plan.originalPrice}</span>
                                                                    </s>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Card>
                                                </label>
                                            </Row>
                                        </>
                                    ))}
                                    <Row>
                                        <Row style={{ paddingLeft: "22px", paddingRight: "0px" }}>
                                            <Button className="" style={{ background: '#91499D', border: 'none', fontSize: '15px', fontWeight: '500' }} onClick={handleButtonClick}>
                                                Create inquiry
                                            </Button>
                                        </Row>
                                    </Row>
                                    {/* <Row style={{ paddingLeft: "5px", paddingRight: "11px" }}>
                                        <Button className="" style={{ background: '#91499D', border: 'none', fontSize: '15px', fontWeight: '500' }} onClick={handleButtonClick}>
                                            Select Plan
                                        </Button>
                                    </Row> */}
                                </Col>

                            </Row>
                        </section>
                    </Container>
                </div>
            </div >
        </>
    );
};

export default AllPlans;

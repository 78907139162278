import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useExpanded,
  usePagination,
} from "react-table"
import { Table } from "reactstrap"

// Define a default UI for filtering

const TableListBooking = ({ columns, data, className, allowSalesmanDisplay, }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 2000,
        sortBy: [
            {
                desc: true,
            },
        ],
    },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )

  const handlesort = column => {
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  return (
    <Fragment>
      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      key={column.id}
                      onClick={() => {
                        handlesort(column)
                      }}
                      className={` ${column.id == 'SalesmanId' && !allowSalesmanDisplay ? "column-display" : ""} `}
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          width: column.width,
                        },
                      })}
                    >
                      <div className={`mb-0`}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                    </th>
                  )
                }
                )
                }
              </tr>
            ))}
          </thead>
          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            style={{ padding: "12px !important" }}
                            className={` ${cell.column.id == 'SalesmanId' && !allowSalesmanDisplay ? "column-display" : "header-display"} text-nowrap`}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                                alignItems: cell.alignItems,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  No Record Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </Fragment>
  )
}

TableListBooking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableListBooking

import Swal from "sweetalert"

const confirmDeleteInquiry = ({ title, text, isMobile }) => {

  return Swal({
    title,
    // text: text,
    content: {
      element: "div",
      attributes: {
        innerHTML: text,
        style: "color: black; font-size:14px;",
        className: "",
      },
    },
    html: true,
    icon: "warning",
    width: "50px", // set the width to 400px
    className: isMobile ? "swal-modal-mobile" : "swal-modal",
    heightAuto: true, // disable auto height
    buttons: {
      cancel: {
        text: "Cancel",
        value: false,
        visible: true,
        className: "btn btn-secondary btn-sm cancel-button ",
        closeModal: true,
      },
      confirm: {
        text: "Yes",
        value: true,
        visible: true,
        className: "btn btn-primary btn-sm confirm-button",
        closeModal: true,
      },
    },
    dangerMode: false,
  });

}

const DeleteModel = {
  confirmDeleteInquiry,
}

export default DeleteModel

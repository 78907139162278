/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    Container,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import Select from "react-select";
import breadcrumbs from "common/Breadcrumbs"
import { routes } from "routes/path"
import TableList from "components/Common/TableList"
import { addCurrentTimeToDate, dateFormat, getCurrentFinancialYear } from "pages/Common"
import TableListSales from "./TableListSales"
import SaleBreadcrumb from "components/Common/SaleBreadcrumb"
import CreateItemModel from "pages/CreateItemModal"

const colourStyles = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid  #ced4da",
            cursor: isDisabled ? "not-allowed" : "default",
            backgroundColor: isDisabled ? "#e8e9ebd1" : "default",
            minHeight: "28px",
            padding: "3px 10px",
            fontSize: "12px",
            width: "-webkit-fill-available !important",
        }
        return updatedStyles
    },
}

const colourStyles1 = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid #f46a6a",
            minHeight: "28px",
            width: "-webkit-fill-available !important",
            padding: "3px 10px",
            fontSize: "12px",
        }
        return updatedStyles
    },
}

let advance = 0
let discount = 0
let IdIncrease = 1
let isSelectedFinancialYear = false
function CreateSales() {
    const startDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[0]
    const endDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[1]
    const endDateYear = endDate.split('-')[2] + "-" + endDate.split('-')[1] + '-' + endDate.split('-')[0]
    document.title = `Sale | ${localStorage.getItem("ShopName")}`
    const { getApi, postApi, putApi } = useApi()
    const nextInputRef = useRef(null);
    let { pathname } = useLocation()
    const { state } = useLocation()
    const [isEdit, setIsEdit] = useState(false)
    const [modal3, setModal3] = useState(false)
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    const [screeLoad, setscreeLoad] = useState(false)
    const [paymentMethod, setpaymentMethod] = useState("1")
    const [productSale, setProductSale] = useState("1")
    const [bookingAvailabilityList, setBookingAvailableList] = useState()
    const [items, setItems] = useState([])
    const [saleId, setSaleId] = useState(0)
    const [isDiscountCalculate, setIsDiscountCalculate] = useState(false)
    const [totalCGSTAmount, setTotalCGSTAmount] = useState(0)
    const [totalSGSTAmount, setTotalSGSTAmount] = useState(0)
    const [totalIGSTAmount, setTotalIGSTAmount] = useState(0)
    const [totalAmt, setTotalAmt] = useState(0)
    const [totalNetAmt, setTotalNetAmt] = useState(0.00)
    const [totalTexes, setTotalTexes] = useState(0)
    const [totalQty, setTotalQty] = useState(0.00)
    const [isDelete, setIsDelete] = useState(false)
    const [isProductSale, setIsProductSale] = useState(productSale == "1" ? true : false)
    const [itemName, setItemName] = useState(null)
    const [selectedItem, setSelectedItem] = useState({})
    const [isError, setIsError] = useState(false)
    const [isProductEdit, setIsProductEdit] = useState(false)
    const [editItem, setEditItem] = useState(0)
    const [saleItemsIdList, setSaleItemsIdList] = useState([])
    const [editProductCode, seteditProductCode] = useState()
    const [itemStock, setItemStock] = useState("0")
    const [billNo, setBillNo] = useState(0)
    const [oldItemIdOrCode, setOldItemIdOrCode] = useState('')
    const [createItemId, setCreateItemId] = useState(0)
    const [salemanList, setSalesmanList] = useState([])
    const [salesmanId, setSalesmanId] = useState(0)
    const [saleItamConfirm, setSaleItemConfirm] = useState()
    const [saleDetails, setSaleDetails] = useState()

    // table state management
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        const SelectedFinancialYear = startDate?.split("-")[2] + "-" + (endDate?.split("-")[2])
        if (SelectedFinancialYear == getCurrentFinancialYear()) {
            isSelectedFinancialYear = true
            validation.setFieldValue("date", new Date().toISOString().slice(0, 10))
        } else {
            isSelectedFinancialYear = false
            validation.setFieldValue("date", endDateYear)
        }
    }, [localStorage.getItem("FinancialYearDate")])

    useEffect(() => {
        if (state?.saleId) {
            setscreeLoad(true)
            getApi(`Sales/details?id=${state?.saleId}`).then(({ data, status }) => {
                console.log("data", data)
                setIsEdit(true)
                if (status === 200) {
                    setProductSale(data?.SaleProductList[0]?.IsProduct == false ? "2" : "1")
                    setIsProductSale(data?.SaleProductList[0]?.IsProduct)
                    setBillNo(data?.SaleDetailModel?.BillNo)
                    setSaleDetails(data?.SaleDetailModel)
                    validation.setFieldValue("name", data?.SaleDetailModel?.Name)
                    validation.setFieldValue("date", new Date(new Date(data?.SaleDetailModel?.Date).setDate(new Date(data?.SaleDetailModel?.Date).getDate() + 1)).toISOString().slice(0, 10),)
                    validation.setFieldValue("contactNo", data?.SaleDetailModel?.ContactNo)
                    validation.setFieldValue("address", data?.SaleDetailModel?.Address)
                    validation.setFieldValue("remark", data?.SaleDetailModel?.Remarks)
                    validation.setFieldValue("discount", data?.SaleDetailModel?.Discount)
                    discount = data?.SaleDetailModel?.Discount;
                    setSaleId(data?.SaleDetailModel?.Id)
                    setSalesmanId(data?.SaleDetailModel?.SalesmanId)
                    setTotalCGSTAmount(data?.SaleDetailModel?.CGSTAmount)
                    setTotalSGSTAmount(data?.SaleDetailModel?.SGSTAmount)
                    setTotalIGSTAmount(data?.SaleDetailModel?.IGSTAmount)
                    setpaymentMethod(data?.SaleDetailModel?.PaymentMode == 2 ? "2" : "1");
                    setTotalAmt(data?.SaleDetailModel?.TaxableAmount)
                    setTotalNetAmt(data?.SaleDetailModel?.NetAmount + data?.SaleDetailModel?.Discount)
                    validation.setFieldValue("advance", data?.SaleDetailModel?.Advance)
                    advance = data?.SaleDetailModel?.Advance
                    setTotalTexes(data?.SaleDetailModel?.CGSTAmount + data?.SaleDetailModel?.SGSTAmount + data?.SaleDetailModel?.IGSTAmount)
                    setTotalQty(data?.SaleDetailModel?.TotalQty)
                    data?.SaleProductList.forEach(function (item, index) {
                        IdIncrease = IdIncrease + 1
                        item["IdIncrease"] = IdIncrease
                        item["editItem"] = index + 1
                        setEditItem(index + 1)
                    })
                    setTableData(data?.SaleProductList)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    });
                }
                setscreeLoad(false)
            })
        }
    }, [state])

    useEffect(() => {
        setscreeLoad(true)
        getApi("Items/items-lookup").then(({ data, status }) => {
            if (status === 200) {
                const itemData = data.map(item => ({
                    label: `${item.ItemCode} - ${item.Name}`,
                    value: item.ItemCode,
                    Id: item.Id,
                    ItemCode: item.ItemCode,
                    Name: item.Name,
                    BarCodeNo: item.BarcodeNo,
                    CGST: item.CGST,
                    SGST: item.SGST,
                    IGST: item.IGST,
                    MRP: item.MRP,
                    OpeningStock: item.OpeningStock,
                    Qty: item.Qty,
                }));
                itemData.unshift({
                    label: "Select item",
                    value: "",
                    Id: 0,
                    ItemCode: '',
                    Name: '',
                    BarCodeNo: '',
                    CGST: 0,
                    SGST: 0,
                    IGST: 0,
                    MRP: 0,
                    OpeningStock: 0,
                    Qty: 0,
                });
                setItems(itemData)
                const filterdata = itemData.filter(item => item?.Id == createItemId)
                setItemName(filterdata)
                handleNetPrice(filterdata[0].MRP, filterdata[0].CGST, filterdata[0].SGST, filterdata[0].IGST, 1)
                setSelectedItem({
                    Id: filterdata[0].Id,
                    Name: filterdata[0].Name,
                    ItemCode: filterdata[0].ItemCode,
                    label: filterdata[0].label,
                })
                setItemStock(filterdata[0].Qty)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })


        getApi("User/vendor-salesman-lookup").then(({ data, status }) => {
            if (status === 200) {
                setSalesmanList(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
        discount = validation.values.discount || 0
    }, [createItemId])

    // available confirm modal
    const toggle3 = () => {
        setModal3(!modal3)
    }

    const toggle = () => {
        setModal(!modal)
    }

    const handleItemClick = () => {
        toggle()
    }

    // Booking Validations
    const validation = useFormik({
        enableReinitialize: false,
        initialValues: {
            // SaleAccount: 0,
            name: "",
            remark: "",
            contactNo: "",
            address: "",
            discount: 0,
            advance: 0,
            date: new Date().toISOString().slice(0, 10),
        },
        validationSchema: Yup.object({
            date: Yup.string().required("The date is required"),
            contactNo: Yup.string()
                .matches(/^[0-9s]{10}$/, "Contact number must be a 10-digit number")
                .required("Contact Number is required"),
            name: Yup.string().required("The Name is required"),
            address: Yup.string().required("The Address is required"),
        }),
        onSubmit: values => {
            if (Math.round(totalNetAmt) < 0 || advance < 0 || (Math.round(totalNetAmt) - discount - advance) < 0) {
                toast("Amount should not be nagative.", {
                    icon: "⚠️",
                    style: {
                        fontSize: "15px",
                    },
                })
                return false
            }

            if (tableData.length > 0) {
                setscreeLoad(true)
                tableData.forEach(function (item) {
                    item.TotalAmount = parseFloat(item.TotalAmount)
                    item.CGSTPer = parseFloat(item.CGSTPer) || 0
                    item.SGSTPer = parseFloat(item.SGSTPer) || 0
                    item.IGSTPer = parseFloat(item.IGSTPer) || 0
                    item.Price = item.Price ? parseFloat(item.Price) : 0 || 0
                    item.TotalPrice = parseFloat(item.TotalPrice) || 0
                });

                const Api = isEdit ? putApi : postApi;
                const endPoint = isEdit ? 'Sales/update-sales' : 'Sales/create-sales'
                const successMessage = isEdit ? "Update successfully" : "Sale successfully"
                const obj = {
                    Id: saleId,
                    date: isEdit ? (values.date) == dateFormat(
                        saleDetails?.Date.split("T")[0],
                        "yyyy-MM-dd"
                    ) ? saleDetails?.Date : addCurrentTimeToDate(values.date) : addCurrentTimeToDate(values.date),
                    name: values.name,
                    contactNo: values.contactNo,
                    TaxableAmount: parseFloat(totalAmt),
                    remarks: values.remark || "",
                    address: values.address,
                    discount: discount,
                    paymentMode: parseFloat(paymentMethod),
                    termsDays: 0,
                    totalQty: totalQty,
                    CGSTAmount: parseFloat(totalCGSTAmount),
                    SGSTAmount: parseFloat(totalSGSTAmount),
                    IGSTAmount: parseFloat(totalIGSTAmount),
                    netAmount: parseFloat(totalNetAmt) - parseFloat(discount),
                    advance: parseFloat(advance),
                    isProduct: productSale == "1" ? true : false,
                    saleItems: tableData,
                    salesmanId: parseInt(salesmanId),
                }
                console.log("obj", obj)
                Api(endPoint, obj).then(({ data, status }) => {
                    if (status === 200) {
                        navigate(routes.salesList)
                        toast.success(successMessage, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setscreeLoad(false)
                })
            } else {
                toast.error("Atleast one product add in table", {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        },
    })

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    // Delete Each Product Booking handler
    const deleteHandle = (Id, saleItemId, productCode, IdIncrease) => {
        // setSaleItemsIdList(prevIds => [...prevIds, saleItemId]);
        setSaleItemsIdList(prevIds => [...prevIds, { saleItemId, productCode }]);
        let updatedArray = []
        updatedArray = tableData
            .map(obj => (obj.IdIncrease !== IdIncrease ? obj : null))
            .filter(Boolean)
        setTableData(updatedArray)
        setIsDelete(true)
        handleTotalGstAmounts(updatedArray, discount)
    }
    // Edit Each Product Booking handler
    const editHandle = (data, saleItemId, productCode) => {
        seteditProductCode(data)
        setOldItemIdOrCode(data.IsProduct ? data.ProductCode : data.ItemId);
        setSaleItemsIdList(prevIds => [...prevIds, { saleItemId, productCode }]);
        setIsProductEdit(true)
        setSelectedItem({
            Id: data.ItemId,
            Name: data.ProductName,
            ItemCode: data.ProductCode
        })
        const filterdata = items.filter(item => item.Id == data.ItemId)
        setItemName(filterdata)
        setItemStock(filterdata[0]?.Qty || 0)
        setFieldValue(data)
    }

    // product & item sale 
    const handleProductItemSale = (value) => {
        setProductSale(value)
        setIsProductSale(value == "1" ? true : false)
        setTableData([])
        setEditItem(0)
        setSelectedItem({})
        productDetailsValidation.handleReset()
        handleTotalGstAmounts(tableData, 0)
        handleDisount(0)
    }

    // table  existing product validation
    const exitInBookingItem = (arr, obj) => {
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            if (productSale == "1") {
                if (item.ProductCode.toUpperCase() === obj.ProductCode.toUpperCase() && obj.ProductCode?.toUpperCase() != oldItemIdOrCode?.toUpperCase()) {
                    return true
                }
            } else {
                if (item.ItemId == parseInt(obj.ItemId) && parseInt(obj.ItemId) != parseInt(oldItemIdOrCode)) {
                    return true
                }
            }
        }
        return false
    }
    const handleConfirmSaleProduct = () => {
        productAddEdit(saleItamConfirm)
        toggle3()
    }

    // Product Detilas validation
    const productDetailsValidation = useFormik({
        enableReinitialize: true,
        // initialValues
        initialValues: {
            IdIncrease: IdIncrease,
            Id: 0,
            ItemId: 0,
            ProductCode: "",
            ProductName: "",
            Qty: '1',
            Price: 0,
            CGSTPer: 0,
            SGSTPer: 0,
            IGSTPer: 0,
            TaxableAmount: 0,
            TotalPrice: 0,
            TotalAmount: 0,
            CGSTAmount: 0,
            SGSTAmount: 0,
            IGSTAmount: 0,
            IsProduct: isProductSale,
            editItem: editItem,
            itemStock: 0,
            // remarks: "",
        },

        // input validation
        validationSchema: Yup.object().shape({
            ProductCode: Yup.string().when('IsProduct', {
                is: true,
                then: Yup.string().required("The code is required"),
                otherwise: Yup.string(),
            }),
            Qty: Yup.number().min(1, "The qty is required").required("The qty is required"),
        }),

        onSubmit: (saleItem) => {
            // select control validation
            if (!isProductSale && (Object.keys(selectedItem).length === 0) || (!isProductSale && selectedItem?.label == "Select item")
            ) {
                setIsError(true);
                return;
            }

            // CGSTPer & SGSTPer validation
            if (productDetailsValidation.values.CGSTPer > 0 && productDetailsValidation.values.SGSTPer == 0 || productDetailsValidation.values.SGSTPer > 100) {
                return false;
            }
            if (productDetailsValidation.values.SGSTPer > 0 && productDetailsValidation.values.CGSTPer == 0 || productDetailsValidation.values.CGSTPer > 100) {
                return false;
            }

            // product add & edit
            handleNonProductEdit(saleItem);
            setSaleItemConfirm(saleItem)
        },
    })

    function setFieldValue(saleItem) {
        productDetailsValidation.setFieldValue("Qty", saleItem.Qty)
        productDetailsValidation.setFieldValue("Price", saleItem.Price)
        productDetailsValidation.setFieldValue("CGSTPer", saleItem.CGSTPer)
        productDetailsValidation.setFieldValue("SGSTPer", saleItem.SGSTPer)
        productDetailsValidation.setFieldValue("IGSTPer", saleItem.IGSTPer)
        productDetailsValidation.setFieldValue("TotalPrice", saleItem.TotalPrice)
        productDetailsValidation.setFieldValue("TotalAmount", saleItem.TotalAmount)
        productDetailsValidation.setFieldValue("ProductCode", saleItem?.ProductCode)
    }
    // in table product add & edit function
    function handleNonProductEdit(saleItem) {
        saleItem.ItemId = isProductSale ? saleItem.ItemId : selectedItem?.Id;
        IdIncrease = IdIncrease + 1
        saleItem.IdIncrease = IdIncrease
        // in table existing product & item validation
        const existingItem = isProductSale ? exitInBookingItem(tableData, saleItem) : false;
        if (!existingItem) {
            if (!isProductEdit) {
                setEditItem(editItem + 1)
            }
            // All GST amount  calculation
            saleItem.editItem = isProductEdit ? editProductCode.editItem : editItem;
            saleItem.CGSTAmount = (saleItem.Price * saleItem.CGSTPer * saleItem.Qty) / 100;
            saleItem.SGSTAmount = (saleItem.Price * saleItem.SGSTPer * saleItem.Qty) / 100;
            saleItem.IGSTAmount = (saleItem.Price * saleItem.IGSTPer * saleItem.Qty) / 100;
            saleItem.TaxableAmount = saleItem.Price * saleItem.Qty;

            if (productSale === "1") {
                // product availability check
                setscreeLoad(true);
                handleProductAvailability(saleItem);
            } else {
                setIsError(false);
                productAddEdit(saleItem);
            }
        } else {
            toast.error("Product is already listed", {
                style: {
                    fontSize: "15px",
                },
            });
        }
    }

    // product availability check
    function handleProductAvailability(saleItem) {
        const filterData = (saleItemsIdList.length > 0 && saleItemsIdList.filter(item => item?.productCode == saleItem?.ProductCode.trim()))
        postApi("Sales/check-availablility", {
            productCode: saleItem.ProductCode.trim(),
            SaleDate: validation.values.date || new Date().toISOString().slice(0, 10),
            Qty: parseInt(saleItem.Qty),
            SaleItemId: filterData[0]?.saleItemId || 0,
        }).then(({ data, status }) => {
            setscreeLoad(false);
            if (status === 200) {
                setFieldValue(saleItem)
                saleItem.ItemId = data?.ProductId;
                saleItem.ProductName = data.ProductName;
                //  product availability list
                if (data?.BookingAvailabilityList?.length > 0 && data.Status == 3) {
                    setBookingAvailableList(data?.BookingAvailabilityList);
                    // product confirm from availability list
                    toggle3();
                } else if (data.Status == 1) {
                    // product add in table
                    productAddEdit(saleItem);
                } else if (data?.BookingAvailabilityList?.length == 0 &&
                    data.Status == 2) {
                    setFieldValue(saleItem)
                    toast.error("Product Not available", {
                        style: {
                            fontSize: "15px",
                        },
                    });
                }
                else {
                    setFieldValue(saleItem)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    });
                }
            } else {
                setFieldValue(saleItem)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                });
            }
        });
    }

    // product & item add edit
    function productAddEdit(saleItem) {
        if (isProductEdit) {
            // product & item edit
            tableData.forEach(function (item) {
                if (item.IdIncrease === editProductCode.IdIncrease) {
                    item.ProductCode = saleItem.IsProduct ? saleItem.ProductCode : selectedItem?.ItemCode;
                    item.ItemId = saleItem.IsProduct ? saleItem.ItemId : selectedItem?.Id;
                    item.ProductName = saleItem.IsProduct ? saleItem.ProductName : selectedItem?.Name;
                    item.Qty = saleItem.Qty;
                    item.Price = saleItem.Price;
                    item.CGSTPer = saleItem.CGSTPer;
                    item.SGSTPer = saleItem.SGSTPer;
                    item.IGSTPer = saleItem.IGSTPer;
                    item.TotalPrice = saleItem.TotalPrice;
                    item.TotalAmount = saleItem.TotalAmount;
                    item.CGSTAmount = (saleItem.Price * saleItem.CGSTPer) / 100;
                    item.SGSTAmount = (saleItem.Price * saleItem.SGSTPer) / 100;
                    item.IGSTAmount = (saleItem.Price * saleItem.IGSTPer) / 100;
                    item.TaxableAmount = saleItem.Price * saleItem.Qty;
                    item.itemStock = saleItem.IsProduct ? 0 : itemStock;
                }
            });
            // GST amount calculation
            setIsProductEdit(false)
            setItemStock(0)
            handleTotalGstAmounts(tableData, discount);
            productDetailsValidation.handleReset();
            setSelectedItem({})
        } else {
            // item add in table
            if (!saleItem.IsProduct) {
                saleItem.ItemId = selectedItem?.Id;
                saleItem.ProductName = selectedItem?.Name;
                saleItem.ProductCode = selectedItem?.ItemCode;
                saleItem.itemStock = itemStock;
            }
            const productData = [...tableData, saleItem];
            handleTotalGstAmounts(productData, discount);
            setTableData(productData);
            setSelectedItem({})
            productDetailsValidation.handleReset();
        }
        setItemName('');
        setItemStock(0)
    }

    // prices calculation
    const handleNetPrice = (Price, cgst, sgst, igst, Qty) => {
        const TotalPrice = parseFloat(Price || 0) + (parseFloat(Price || 0) * (parseFloat(cgst || 0) + parseFloat(sgst || 0) + parseFloat(igst || 0)) / 100)
        const TotalAmount = TotalPrice * parseFloat(Qty || 1)
        productDetailsValidation.setFieldValue("TotalPrice", TotalPrice.toFixed(2))
        productDetailsValidation.setFieldValue("Price", Price ? Price : '')
        productDetailsValidation.setFieldValue("Qty", Qty ? Qty : '')
        productDetailsValidation.setFieldValue("TotalAmount", parseFloat(TotalAmount).toFixed(2))
        productDetailsValidation.setFieldValue("IGSTPer", igst ? igst : '')
        productDetailsValidation.setFieldValue("CGSTPer", cgst ? cgst : '')
        productDetailsValidation.setFieldValue("SGSTPer", sgst ? sgst : '')
    }

    // set GST per
    const handleGSTPer = (cgst, sgst, igst) => {
        productDetailsValidation.setFieldValue("SGSTPer", sgst ? sgst : '')
        productDetailsValidation.setFieldValue("CGSTPer", cgst ? cgst : '')
        if (parseFloat(cgst) > 0 || parseFloat(sgst) > 0) {
            productDetailsValidation.setFieldValue("IGSTPer", '')
        } else {
            productDetailsValidation.setFieldValue("IGSTPer", igst ? igst : '')
            productDetailsValidation.setFieldValue("CGSTPer", cgst ? cgst : '')
            productDetailsValidation.setFieldValue("SGSTPer", sgst ? sgst : '')
        }
        const TotalPrice = parseFloat(productDetailsValidation.values.Price || 0) + (parseFloat(productDetailsValidation.values.Price || 0) * (parseFloat(cgst || 0) + parseFloat(sgst || 0) + parseFloat(igst || 0)) / 100)
        const TotalAmount = TotalPrice * parseFloat(productDetailsValidation.values.Qty || 1)
        productDetailsValidation.setFieldValue("TotalPrice", TotalPrice.toFixed(2))
        productDetailsValidation.setFieldValue("TotalAmount", parseFloat(TotalAmount).toFixed(2))
    }

    // set GST per
    const handleIGSTPer = (cgst, sgst, igst) => {
        productDetailsValidation.setFieldValue("IGSTPer", igst ? igst : '')
        if (parseFloat(igst) > 0) {
            productDetailsValidation.setFieldValue("CGSTPer", '')
            productDetailsValidation.setFieldValue("SGSTPer", '')
        }
        else {
            productDetailsValidation.setFieldValue("IGSTPer", igst ? igst : '')
            productDetailsValidation.setFieldValue("CGSTPer", cgst ? cgst : '')
            productDetailsValidation.setFieldValue("SGSTPer", sgst ? sgst : '')
        }
        const TotalPrice = parseFloat(productDetailsValidation.values.Price || 0) + (parseFloat(productDetailsValidation.values.Price || 0) * (parseFloat(cgst || 0) + parseFloat(sgst || 0) + parseFloat(igst || 0)) / 100)
        const TotalAmount = TotalPrice * parseFloat(productDetailsValidation.values.Qty || 1)
        productDetailsValidation.setFieldValue("TotalPrice", TotalPrice.toFixed(2))
        productDetailsValidation.setFieldValue("TotalAmount", parseFloat(TotalAmount).toFixed(2))
    }

    // Net price  reverse calculation
    const handleReversenetPrice = (netPrice, cgst, sgst, igst, Qty) => {
        productDetailsValidation.setFieldValue("TotalPrice", netPrice ? netPrice : '')
        if (parseFloat(igst) > 0) {
            const itemIGSTAmount = (netPrice * 100) / (100 + parseFloat(igst || 0));
            const TotalAmount = netPrice * parseFloat(Qty || 1)
            productDetailsValidation.setFieldValue("Price", itemIGSTAmount.toFixed(2))
            productDetailsValidation.setFieldValue("TotalAmount", TotalAmount)
        }
        else {
            const totalGstPer = parseFloat(cgst || 0) + parseFloat(sgst || 0);

            //Find Total GST Amount.-
            const totalGstAmount = (netPrice * 100) / (100 + totalGstPer);
            const TotalAmount = netPrice * parseFloat(Qty || 1)
            productDetailsValidation.setFieldValue("Price", totalGstAmount.toFixed(2))
            productDetailsValidation.setFieldValue("TotalAmount", TotalAmount)
        }
    }

    // item select control automatically fills  all input
    const autoSelect = (Item, name) => {
        setItemName(Item);
        setIsError(false)
        if (name == "Item") {
            setItemStock(Item?.Qty || 0)
            setSelectedItem(Item)
            nextInputRef.current.focus();
            nextInputRef.current.select();
            handleNetPrice(Item?.MRP, Item?.CGST, Item?.SGST, Item?.IGST, 1)
        }
    }

    const handleTypeBarcode = (value) => {
        const filterdata = items.filter(item => item?.BarCodeNo?.toUpperCase() === value?.toUpperCase())
        setItemName(filterdata[0])
        setSelectedItem(filterdata[0])
        if (filterdata.length > 0) {
            nextInputRef.current.focus();
            nextInputRef.current.select();
        }
        setItemStock(filterdata[0]?.Qty || 0)
        handleNetPrice(filterdata[0]?.MRP, filterdata[0]?.CGST, filterdata[0]?.SGST, filterdata[0]?.IGST, 1)
    }

    // total GST amounts calculation
    const handleTotalGstAmounts = (data, discount) => {
        console.log("yes")
        const totalCGSTAmount = data
            ? data.reduce((sum, item) => sum + parseFloat(item.CGSTAmount), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.CGSTAmount), 0)
        setTotalCGSTAmount(totalCGSTAmount.toFixed(2))

        const totalSGSTAmount = data
            ? data.reduce((sum, item) => sum + parseFloat(item.SGSTAmount), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.SGSTAmount), 0)
        setTotalSGSTAmount(totalSGSTAmount.toFixed(2))

        const totalIGSTAmount = data
            ? data.reduce((sum, item) => sum + parseFloat(item.IGSTAmount), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.IGSTAmount), 0)
        setTotalIGSTAmount(totalIGSTAmount.toFixed(2))

        const totoalTaxes = data
            ? data.reduce((sum, item) => sum + (parseFloat(item.IGSTAmount * item.Qty) + parseFloat(item.SGSTAmount * item.Qty) + parseFloat(item.CGSTAmount * item.Qty)), 0)
            : tableData.reduce((sum, item) => sum + (parseFloat(item.IGSTAmount * item.Qty) + parseFloat(item.SGSTAmount * item.Qty) + parseFloat(item.CGSTAmount * item.Qty)), 0)
        setTotalTexes((totalCGSTAmount + totalSGSTAmount + totalIGSTAmount).toFixed(2))

        const totalQty = data
            ? data.reduce((sum, item) => sum + parseFloat(item.Qty), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.Qty), 0)
        setTotalQty(totalQty)

        const totalAmt = data
            ? data.reduce((sum, item) => sum + parseFloat(item.Price * item.Qty), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.Price * item.Qty), 0)
        setTotalAmt(totalAmt)

        const totalNetAmount = data
            ? data.reduce((sum, item) => sum + parseFloat(item.TotalAmount), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.TotalAmount), 0)
        if (!isEdit) {
            validation.setFieldValue("advance", (totalNetAmount - discount).toFixed(2));
            advance = (totalNetAmount - discount).toFixed(2)
        }
        setTotalNetAmt((totalNetAmount).toFixed(2))
    }

    // discount  calculation
    const handleDisount = (value) => {
        const discountPercentage = parseFloat(value);
        validation.setFieldValue("discount", value ? value : '');
        if (isDiscountCalculate && !isNaN(discountPercentage)) {
            discount = ((parseFloat(totalAmt) + parseFloat(totalCGSTAmount) + parseFloat(totalSGSTAmount) + parseFloat(totalIGSTAmount)) * discountPercentage) / 100;
            handleTotalGstAmounts(tableData, discount);
        } else {
            discount = value ? value : 0
            handleTotalGstAmounts(tableData, discount)
        }
    }

    // discount change event function call
    useEffect(() => {
        handleDisount(validation.values.discount || 0)
    }, [isDiscountCalculate, isDelete, productSale])

    // multiple Booking Table
    const columns = useMemo(
        () => [
            {
                // Header: "Code",
                Header: () => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                        }}
                    >
                        {"Code"}
                    </div>
                ),
                accessor: "ProductCode",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "start",
                        }}>
                            {row.original?.ProductCode}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                        }}
                    >
                        {productSale == "1" ? "Product Name" : "Item Name"}
                    </div>
                ),
                accessor: "ProductName",
                Cell: ({ row }) => {
                    return <div style={{
                        display: "flex",
                        alignItems: "start",
                    }}>{row.original?.ProductName}</div>
                },
            },
            {
                Header: "Qty",
                accessor: "Qty",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            {row.original?.Qty}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Price
                    </div>
                ),
                accessor: "Price",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original?.Price || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Taxable Amt.
                    </div>
                ),
                accessor: "Price,Qty",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format((row.original?.Price * row.original.Qty) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        CGST
                    </div>
                ),
                accessor: "CGSTPer",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.CGSTPer ? row.original.CGSTPer : "0"}% |  <i className="mdi mdi-currency-inr"></i>{new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original.CGSTAmount || 0)
                            }
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        SGST
                    </div>
                ),
                accessor: "SGSTPer",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.SGSTPer ? row.original.SGSTPer : "0"}% |  <i className="mdi mdi-currency-inr"></i>{new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original.SGSTAmount || 0)
                            }
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        IGST
                    </div>
                ),
                accessor: "IGSTPer",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.IGSTPer ? row.original.IGSTPer : "0"}% |  <i className="mdi mdi-currency-inr"></i>{new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original.IGSTAmount || 0)
                            }
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Net Price
                    </div>
                ),
                accessor: "TotalPrice",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original?.TotalPrice || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Total Amt.
                    </div>
                ),
                accessor: "TotalAmount",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original?.TotalAmount || 0)}
                        </div>
                    )
                },
            },

            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-2 mb-0 d-flex justify-content-center">
                            {/* {(productSale == "1" && isEdit) ? "" : */}
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit "
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => editHandle(row.original, row.original.Id, row.original.ProductCode)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            {/* } */}
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.ItemId}`}
                                    onClick={() =>
                                        deleteHandle(row.original.ItemId, row.original.Id, row.original.ProductCode, row.original.IdIncrease)
                                    }
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, productSale]
    )

    // table header & accessor
    const columns3 = useMemo(
        () => [
            {
                Header: "Customer Name",
                accessor: "CustomerName",
            },
            {
                Header: "Contact No.1",
                accessor: "ContactNo1",
                width: "8%",
            },
            {
                Header: "Contact No.2",
                accessor: "ContactNo2",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ContactNo2 == null ? "N/A" : row.original.ContactNo2}
                    </p>
                ),
            },

            {
                Header: "Delivery",
                accessor: "DeliveryDate,DeliveryTimeSlot",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.DeliveryDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                        {row.original.DeliveryTimeSlot ? (
                            <span> {` (${row.original.DeliveryTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Return",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReturnDate == null
                            ? "N/A"
                            : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
                        {row.original.ReturnTimeSlot ? (
                            <span> {` (${row.original.ReturnTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
        ],
        []
    )


    const handleAdvanceChange = value => {
        advance = parseInt(value) || 0
        validation.setFieldValue("advance", advance || '')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {screeLoad && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}
                    <SaleBreadcrumb
                        parent={breadcrumbs[pathname].parent}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                        parentLink={breadcrumbs[pathname].parentLink}
                        BillNo={billNo}
                        isEdit={isEdit}
                    />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onReset={e => {
                                            e.preventDefault
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <Row>
                                            <Label className="mb-3">
                                                Customer Details
                                                <hr
                                                    style={{
                                                        background: "#00000036",
                                                        height: "0.5px",
                                                        margin: "0px",
                                                        marginBottom: "10px",
                                                    }}
                                                />
                                            </Label>
                                        </Row>
                                        <Row>
                                            <Col md={3} className="pe-0">
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="productDetailsValidationCustom01"
                                                        className="required"
                                                    >
                                                        Date
                                                    </Label>
                                                    <Input
                                                        name="date"
                                                        placeholder="date placeholder"
                                                        type="date"
                                                        min={dateFormat(startDate, "yyyy-dd-MM")}
                                                        max={endDateYear}
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.date}
                                                        invalid={
                                                            validation.touched
                                                                .date &&
                                                                validation.errors.date
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.date &&
                                                        validation.errors.date ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.date}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md="7" className="pe-0">
                                                <div className="">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Name
                                                    </Label>
                                                    <Input
                                                        name="name"
                                                        placeholder="Customer Name"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name && validation.errors.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md="2" className="">
                                                <div className="">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Contact No.
                                                    </Label>
                                                    <Input
                                                        name="contactNo"
                                                        placeholder="Contact No."
                                                        type="text"
                                                        maxLength="10"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contactNo || ""}
                                                        onKeyPress={handleKeyPress}
                                                        invalid={
                                                            validation.touched.contactNo &&
                                                                validation.errors.contactNo
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.contactNo &&
                                                        validation.errors.contactNo ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.contactNo}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Address
                                                    </Label>
                                                    <Input
                                                        name="address"
                                                        placeholder="Type your address here"
                                                        type="textarea"
                                                        rows="1"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address || ""}
                                                        invalid={
                                                            validation.touched.address &&
                                                                validation.errors.address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.address &&
                                                        validation.errors.address ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.address}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <Label
                                                    htmlFor="validationCustom03"
                                                >
                                                    Salesman
                                                </Label>
                                                <Input
                                                    disabled={isEdit}
                                                    type="select"
                                                    bsSize="sm"
                                                    id="validationCustom02"
                                                    className="form-control"
                                                    name="salesman"
                                                    onChange={e => {
                                                        setSalesmanId(e.target.value)
                                                    }}
                                                    value={salesmanId}
                                                >
                                                    <option value={0}>Select item</option>
                                                    {
                                                        salemanList.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.Key}>
                                                                    {item.Value}
                                                                </option>
                                                            )
                                                        })}
                                                </Input>
                                            </Col>
                                            {!isEdit && <Col md={3}>
                                                <div className=" d-flex gap-2" style={{ marginTop: "25px" }} >
                                                    <label
                                                        htmlFor="name"
                                                        className=" form-label"
                                                    >
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        value="1"
                                                        id="customRadioInline1"
                                                        name="productSale"
                                                        className="form-check-input"
                                                        checked={productSale === "1"}
                                                        onClick={(e) => handleProductItemSale(e.target.value)}
                                                    />
                                                    <Label className="form-check-label font-size-13" htmlFor="customRadioInline1" style={{ marginRight: "7px" }} >
                                                        Product Sale
                                                    </Label>
                                                    <input
                                                        type="radio"
                                                        value="2"
                                                        id="customRadioInline2"
                                                        name="productSale"
                                                        className="form-check-input"
                                                        checked={productSale === "2"}
                                                        onClick={(e) => handleProductItemSale(e.target.value)}
                                                    />
                                                    <Label className="form-check-label font-size-13" htmlFor="customRadioInline2">
                                                        Item Sale
                                                    </Label>
                                                </div>
                                            </Col>}
                                        </Row>
                                        <Row className="mb-2">
                                            <Label className="my-3">
                                                {productSale == "1" ? "Products" : "Items"}
                                                <hr
                                                    style={{
                                                        background: "#00000036",
                                                        height: "0.5px",
                                                        margin: "0px",
                                                        // marginBottom: "2px",
                                                    }}
                                                />
                                            </Label>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col md="12">
                                                <Row>
                                                    {productSale == "1" ?
                                                        <Col md="1" className="" lg="2">
                                                            <div >
                                                                <Label
                                                                    htmlFor="validationCustom02"
                                                                    className="required"
                                                                >
                                                                    Code
                                                                </Label>
                                                                <Input
                                                                    name="ProductCode"
                                                                    placeholder="Code"
                                                                    type="text"
                                                                    className="form-control-sm"
                                                                    id="validationCustom02"
                                                                    onChange={productDetailsValidation.handleChange}
                                                                    onBlur={productDetailsValidation.handleBlur}
                                                                    value={
                                                                        productDetailsValidation?.values
                                                                            ?.ProductCode || ""
                                                                    }
                                                                    invalid={
                                                                        productDetailsValidation.touched
                                                                            .ProductCode &&
                                                                            productDetailsValidation.errors.ProductCode
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {productDetailsValidation.touched.ProductCode &&
                                                                    productDetailsValidation.errors.ProductCode ? (
                                                                    <FormFeedback type="invalid">
                                                                        {productDetailsValidation.errors.ProductCode}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        :
                                                        <Col md={1} lg="2" style={{ marginTop: "2px" }}>
                                                            <span className="d-flex" style={{ justifyContent: "space-between" }}>
                                                                <label
                                                                    style={{ marginBottom: "0px" }}
                                                                    htmlFor="name"
                                                                    className="required"
                                                                >
                                                                    Items
                                                                </label>
                                                                <label style={{ marginBottom: "0px", color: itemStock >= 0 ? "green" : "red" }}>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                    }).format(itemStock)}
                                                                </label>
                                                            </span>
                                                            <div style={{ display: "flex" }}>
                                                                <Select
                                                                    unstyled
                                                                    styles={isError ? colourStyles1 : colourStyles}
                                                                    value={itemName}
                                                                    onChange={e => autoSelect(e, "Item")}
                                                                    onKeyDown={e => {
                                                                        if (e.key === 'Enter') {
                                                                            handleTypeBarcode(e.target.value)
                                                                        }
                                                                    }}
                                                                    options={items}
                                                                    className="select2-selection"
                                                                />
                                                                <button
                                                                    title="Create Item"
                                                                    type="button"
                                                                    style={{
                                                                        height: "100%",
                                                                        width: "28px",
                                                                        color: "#3834349c",
                                                                        cursor: "pointer",
                                                                        minHeight: "28px",
                                                                        border: "1px solid  #ced4da",
                                                                        backgroundColor: "#f8f9fa",
                                                                    }}
                                                                    onClick={handleItemClick}
                                                                >
                                                                    <span>
                                                                        <i className="fas fa-plus" style={{ marginTop: "5px" }} />
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            {isError ? (
                                                                <div className="name-required">
                                                                    {"The item is required"}
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    }
                                                    <Col md={1} style={{ paddingLeft: "0" }}>
                                                        <div>
                                                            <Label className="form-label required">
                                                                Qty
                                                            </Label>
                                                            <Input
                                                                innerRef={nextInputRef}
                                                                // disabled={productSale == "1"}
                                                                id="qty"
                                                                name="Qty"
                                                                className="form-control-sm"
                                                                placeholder="0"
                                                                type="text"
                                                                onChange={e => handleNetPrice(productDetailsValidation.values.Price, productDetailsValidation.values.CGSTPer, productDetailsValidation.values.SGSTPer, productDetailsValidation.values.IGSTPer, e.target.value)}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.Qty}
                                                                invalid={
                                                                    (productDetailsValidation.touched
                                                                        .Qty &&
                                                                        productDetailsValidation.errors.Qty) || productDetailsValidation.values.Qty == 0
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {(productDetailsValidation.touched.Qty &&
                                                                productDetailsValidation.errors.Qty) || productDetailsValidation.values.Qty == 0 ? (
                                                                <FormFeedback type="invalid">
                                                                    {productDetailsValidation.errors.Qty}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={1} style={{ paddingLeft: "0" }}>
                                                        <div >
                                                            <Label className="form-label">
                                                                Price
                                                            </Label>
                                                            <Input
                                                                name="Price"
                                                                className="form-control-sm"
                                                                placeholder="0.00"
                                                                type="text"
                                                                onChange={e => handleNetPrice(e.target.value, productDetailsValidation.values.CGSTPer, productDetailsValidation.values.SGSTPer, productDetailsValidation.values.IGSTPer, productDetailsValidation.values.Qty)}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.Price || ''}
                                                                invalid={
                                                                    productDetailsValidation.touched.Price &&
                                                                        productDetailsValidation.errors.Price
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {productDetailsValidation.touched.Price &&
                                                                productDetailsValidation.errors.Price ? (
                                                                <FormFeedback type="invalid">
                                                                    {productDetailsValidation.errors.Price}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={1} style={{ paddingLeft: "0" }}>
                                                        <div >
                                                            <Label className="form-label">
                                                                CGST(%)
                                                            </Label>
                                                            <Input
                                                                name="CGSTPer"
                                                                className="form-control-sm"
                                                                placeholder="0.00"
                                                                type="text"
                                                                onChange={e => {
                                                                    let inputValue = e.target.value;
                                                                    if (isNaN(inputValue)) {
                                                                        inputValue = 0;
                                                                    } else if (inputValue < 0) {
                                                                        inputValue = 0;
                                                                    }

                                                                    handleGSTPer(
                                                                        inputValue,
                                                                        productDetailsValidation.values.SGSTPer,
                                                                        productDetailsValidation.values.IGSTPer,
                                                                    );
                                                                }}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.CGSTPer}
                                                                invalid={
                                                                    productDetailsValidation.values.SGSTPer > 0 && productDetailsValidation.values.CGSTPer == 0 || productDetailsValidation.values.CGSTPer > 100 ? true : false
                                                                }
                                                            />
                                                            {productDetailsValidation.values.SGSTPer > 0 && productDetailsValidation.values.CGSTPer == 0 || productDetailsValidation.values.CGSTPer > 100 ? (
                                                                <FormFeedback type="invalid">
                                                                    {"CGSTPer required"}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={1} style={{ paddingLeft: "0" }}>
                                                        <div >
                                                            <Label className="form-label">
                                                                SGST(%)
                                                            </Label>
                                                            <Input
                                                                name="SGSTPer"
                                                                className="form-control-sm"
                                                                placeholder="0.00"
                                                                type="text"
                                                                onChange={e => {
                                                                    let inputValue = e.target.value;
                                                                    if (isNaN(inputValue)) {
                                                                        inputValue = 0;
                                                                    } else if (inputValue < 0) {
                                                                        inputValue = 0;
                                                                    }
                                                                    handleGSTPer(
                                                                        productDetailsValidation.values.CGSTPer,
                                                                        inputValue,
                                                                        productDetailsValidation.values.IGSTPer,
                                                                    );
                                                                }}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.SGSTPer}
                                                                invalid={
                                                                    productDetailsValidation.values.CGSTPer > 0 && productDetailsValidation.values.SGSTPer == 0 || productDetailsValidation.values.SGSTPer > 100 ? true : false
                                                                }
                                                            />
                                                            {productDetailsValidation.values.CGSTPer > 0 && productDetailsValidation.values.SGSTPer == 0 || productDetailsValidation.values.SGSTPer > 100 ? (
                                                                <FormFeedback type="invalid">
                                                                    {"SGSTPer required"}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={1} style={{ paddingLeft: "0" }}>
                                                        <div >
                                                            <Label className="form-label">
                                                                IGST(%)
                                                            </Label>
                                                            <Input
                                                                name="IGSTPer"
                                                                className="form-control-sm"
                                                                placeholder="0.00"
                                                                type="text"
                                                                onChange={e => {
                                                                    let inputValue = e.target.value;
                                                                    if (isNaN(inputValue)) {
                                                                        inputValue = 0;
                                                                    } else if (inputValue < 0) {
                                                                        inputValue = 0;
                                                                    }
                                                                    handleIGSTPer(
                                                                        productDetailsValidation.values.CGSTPer,
                                                                        productDetailsValidation.values.SGSTPer,
                                                                        inputValue,
                                                                    )
                                                                }}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.IGSTPer}
                                                                invalid={
                                                                    (productDetailsValidation.values.CGSTPer == 0 && productDetailsValidation.values.SGSTPer == 0 ? false : false) || (productDetailsValidation.values.IGSTPer > 100 ? true : false)
                                                                }
                                                            />
                                                            {(productDetailsValidation.values.CGSTPer == 0 && productDetailsValidation.values.SGSTPer == 0) || (productDetailsValidation.values.IGSTPer > 100) ? (
                                                                <FormFeedback type="invalid">
                                                                    {productDetailsValidation.errors.IGSTPer}
                                                                </FormFeedback>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                    <Col md={2} style={{ paddingLeft: "0" }}>
                                                        <div >
                                                            <Label className="form-label">
                                                                Net Price
                                                            </Label>
                                                            <Input
                                                                name="TotalPrice"
                                                                className="form-control-sm"
                                                                placeholder="0.00"
                                                                type="text"
                                                                onChange={e => {
                                                                    let inputValue = e.target.value;
                                                                    if (isNaN(inputValue)) {
                                                                        inputValue = '';
                                                                    } else if (inputValue < 0) {
                                                                        inputValue = '';
                                                                    }

                                                                    handleReversenetPrice(
                                                                        inputValue,
                                                                        productDetailsValidation.values.CGSTPer,
                                                                        productDetailsValidation.values.SGSTPer,
                                                                        productDetailsValidation.values.IGSTPer,
                                                                        productDetailsValidation.values.Qty
                                                                    )
                                                                }}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.TotalPrice}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={2} style={{ paddingLeft: "0" }}>
                                                        <div >
                                                            <Label className="form-label">
                                                                Total Amt.
                                                            </Label>
                                                            <Input
                                                                name="TotalAmount"
                                                                disabled
                                                                className="form-control-sm"
                                                                placeholder="0.00"
                                                                type="text"
                                                                onChange={productDetailsValidation.handleChange}
                                                                onBlur={productDetailsValidation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={productDetailsValidation.values.TotalAmount}
                                                                invalid={
                                                                    productDetailsValidation.touched.TotalAmount &&
                                                                        productDetailsValidation.errors.TotalAmount
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {productDetailsValidation.touched.TotalAmount &&
                                                                productDetailsValidation.errors.TotalAmount ? (
                                                                <FormFeedback type="invalid">
                                                                    {productDetailsValidation.errors.TotalAmount}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={1} className="text-sm-end">
                                                        <Button
                                                            color="primary"
                                                            className="btn btn-success btn-sm "
                                                            type="button"
                                                            onClick={e => {
                                                                // e.preventDefault()
                                                                productDetailsValidation.handleSubmit()
                                                                return false
                                                            }}
                                                            style={{ marginTop: "21px", padding: "4px 14px" }}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12" className="text-end ">
                                                <TableListSales
                                                    columns={columns}
                                                    data={tableData}
                                                    isGlobalFilter={false}
                                                    isCreateBooking={false}
                                                    customPageSize={10}
                                                    className="custom-header-css"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md={4}>
                                                <Row>
                                                    <Col md={12} className="">
                                                        <div className="">
                                                            <Label htmlFor="validationCustom05">Remark</Label>
                                                            <Input
                                                                name="remark"
                                                                rows="3"
                                                                placeholder="Type your remark here"
                                                                type="textarea"
                                                                className="form-control-sm"
                                                                value={validation.values.remark || ""}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={8} style={{ display: "flex", justifyContent: "end" }}>
                                                <Row>
                                                    <Col md={3}>
                                                        <Row>
                                                            <Col md={12} style={{ marginTop: "7px" }}>
                                                                <Label htmlFor="validationCustom05">Discount</Label>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Label htmlFor="validationCustom03"> <div className=" d-flex gap-2">
                                                                    <input
                                                                        type="radio"
                                                                        value={false}
                                                                        id="flat"
                                                                        className="form-check-input"
                                                                        style={{ fontSize: "12px" }}
                                                                        checked={isDiscountCalculate == false}
                                                                        onClick={() => { setIsDiscountCalculate(false), handleDisount(0) }}
                                                                    />
                                                                    <Label className="form-check-label font-size-12" style={{ marginRight: "7px" }} htmlFor="flat">
                                                                        Flat
                                                                    </Label>

                                                                    <input
                                                                        type="radio"
                                                                        value={true}
                                                                        id="per"
                                                                        className="form-check-input"
                                                                        checked={isDiscountCalculate == true}
                                                                        style={{ fontSize: "12px" }}
                                                                        onClick={() => { setIsDiscountCalculate(true), handleDisount(0) }}
                                                                    />
                                                                    <Label className="form-check-label font-size-12" htmlFor="per">
                                                                        Per.(%)
                                                                    </Label>
                                                                </div></Label>
                                                                <div>
                                                                    <Input
                                                                        name="discount"
                                                                        placeholder="0.00"
                                                                        type="text"
                                                                        className="form-control-sm"
                                                                        onKeyPress={handleKeyPress}
                                                                        onChange={e => {
                                                                            let inputValue = e.target.value;
                                                                            if (isNaN(inputValue)) {
                                                                                inputValue = 0;
                                                                            } else if (inputValue < 0) {
                                                                                inputValue = 0;
                                                                            }
                                                                            handleDisount(inputValue)
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.discount}
                                                                        invalid={isDiscountCalculate &&
                                                                            validation.values.discount > 100 ? true : false
                                                                        }
                                                                    />
                                                                    {isDiscountCalculate && validation.values.discount > 100 ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.discount}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={4} style={{ fontSize: "14px", marginTop: "5px", textAlign: "-webkit-right" }}>

                                                        <table className="">
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">
                                                                    <Label>
                                                                        Total Qty:
                                                                    </Label>
                                                                </th>
                                                                <th className="gst-amounts3" style={{ textAlign: "left" }}>
                                                                    {totalQty}
                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">CGST Amt.</th>
                                                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalCGSTAmount || 0)}
                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">SGST Amt.</th>
                                                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalSGSTAmount || 0)}
                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">IGST Amt.</th>
                                                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalIGSTAmount || 0)}

                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Advance</th>
                                                                <th className="gst-amounts3">
                                                                    <Input
                                                                        name="advance"
                                                                        className="form-control-sm input-css"
                                                                        placeholder="0.00"
                                                                        type="text"
                                                                        onChange={e => {
                                                                            let inputValue = e.target.value;
                                                                            if (isNaN(inputValue)) {
                                                                                inputValue = 0;
                                                                            } else if (inputValue < 0) {
                                                                                inputValue = 0;
                                                                            }
                                                                            handleAdvanceChange(inputValue)
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        onFocus={(e) => { e.target.select() }}
                                                                        value={validation.values.advance}
                                                                        onKeyPress={handleKeyPress}
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                    <Col md={5} style={{ fontSize: "14px", marginTop: "5px", textAlign: "-webkit-right" }} >
                                                        <table className="text-end" >

                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Taxable Amt.</th>
                                                                <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalAmt || 0)}
                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Taxes(+)</th>
                                                                <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalTexes || 0)}

                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Net Amt.</th>
                                                                <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(Math.round(totalNetAmt))}

                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Discount(-)</th>
                                                                <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(discount || 0)}

                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Advance(-)</th>
                                                                <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(Math.round(advance || 0))}

                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">Payable Amt.
                                                                </th>
                                                                <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(Math.round(totalNetAmt) - advance - discount)}

                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div md={9} style={{ marginTop: "20px", alignItems: "center", display: "flex", gap: "15px" }} className="d-flex justify-content-end text-center">
                                                <div className="mt-3">
                                                    <div className=" d-flex gap-2">
                                                        <input
                                                            type="radio"
                                                            value="1"
                                                            id="cash"
                                                            name="mode"
                                                            className="form-check-input"
                                                            checked={paymentMethod === "1"}
                                                            onClick={() => { setpaymentMethod("1") }}
                                                        />
                                                        <Label className="form-check-label font-size-13" htmlFor="cash">
                                                            Cash
                                                        </Label>

                                                        <input
                                                            type="radio"
                                                            value="2"
                                                            id="bank"
                                                            name="mode"
                                                            className="form-check-input"
                                                            checked={paymentMethod === "2"}
                                                            onClick={() => { setpaymentMethod("2") }}
                                                        />
                                                        <Label className="form-check-label font-size-13" htmlFor="bank">
                                                            Bank
                                                        </Label>
                                                    </div>
                                                </div>
                                                <div className="" style={{ marginTop: "20px" }}>
                                                    <Button
                                                        color="secondary"
                                                        className="btn btn-primary me-2"
                                                        type="button"
                                                        onClick={() => {
                                                            {
                                                                navigate(routes.salesList)
                                                            }
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className="" style={{ marginTop: "20px" }}>

                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary me-2"
                                                        type="button"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            validation.handleSubmit()
                                                            return false
                                                        }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </Row>
                                    </Form>

                                    <Modal isOpen={modal3} toggle={toggle3} centered={true}>
                                        <div className="modal-content ">
                                            <ModalHeader className="text-centre" toggle={toggle3} tag="h5">
                                                Product already in Booking
                                            </ModalHeader>
                                            <ModalBody>
                                                <TableList
                                                    columns={columns3}
                                                    data={bookingAvailabilityList}
                                                    customPageSize={10}
                                                    className="custom-header-css"
                                                />
                                            </ModalBody>
                                            <Row>
                                                <Col
                                                    md="12"
                                                    className="text-end"
                                                    style={{ marginBottom: "5px" }}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        className="btn btn-primary btn-sm me-2"
                                                        type="button"
                                                        onClick={() => toggle3()}
                                                    >
                                                        Cancle
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm me-2"
                                                        type="button"

                                                        onClick={e => {
                                                            e.preventDefault()
                                                            handleConfirmSaleProduct()
                                                            return false
                                                        }}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Modal>

                                    <CreateItemModel
                                        modal={modal}
                                        toggle={toggle}
                                        isEdit={false}
                                        ItemsApi={false}
                                        flag={"sale"}
                                        itemEditData={[]}
                                        setIsEdit={null}
                                        itemId={0}
                                        setCreateItemId={setCreateItemId}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div >
        </React.Fragment >
    )
}

export default CreateSales

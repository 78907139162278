import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import "flatpickr/dist/themes/material_blue.css";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  setGlobalFilter,
  isJobListGlobalFilter,
  value,
  setValue,
}) {
  const count = preGlobalFilteredRows.length
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col>
        <div className="search-box d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search…"}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const StockRegisterTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  className,
  pagePerSize,
  isLoading,
  TotalRecords,
  enableProductStock,
  setStockTable, stoctTable,
  productQtySave,
  validationMSG,
  setpageIndex,
  writeDataToExcel,
  pageIndex,
  categoryId,
  setCategoryId,
  categories,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        // pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [value, setValue] = React.useState()
  const [prevValue, setPrevValue] = useState();

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value);
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1); // Reset pageIndex to 1 when value changes
    }
  }, [value]);

  const handlesort = column => {
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isIpad, setIsIpad] = useState(window.innerWidth < 924)

  const margin = {
    marginTop: isMobile ? "8px" : "",
  }

  useEffect(() => {
    setpageIndex(1); // Reset pageIndex to 1 when value changes
  }, [pageSize]);

  return (
    <Fragment>
      <Row className="">
        <Col md="12">
          <Row>
            <Col md={isIpad ? "3" : "2"} className="table-header" style={margin}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={isIpad ? "3" : "2"} className="table-header" style={margin}>
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
            {enableProductStock &&
              <>
                <Col md={isIpad ? "3" : "2"} className="table-header" style={margin}>
                  <select
                    className="form-select"
                    value={stoctTable}
                    onChange={e => setStockTable(e.target.value)}
                  >
                    <option value={1}>Item</option>
                    <option value={2}>Product</option>
                  </select>
                </Col>
                {parseInt(stoctTable) == 2 &&
                  <>
                    <Col md="2"
                      className={`${isMobile ? '' : ''}  pe-0`}
                    >
                      <FormGroup >
                        <select
                          className="form-select"
                          value={categoryId}
                          onChange={e => setCategoryId(e.target.value)}
                        >
                          {categories.map((item, index) => {
                            return (
                              <option key={index} value={item.Key}>
                                {item.Value}
                              </option>
                            )
                          })}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md={isIpad ? "3" : "3"} className="table-header" style={{ marginTop: isMobile && "8px", display: "flex", justifyContent: "end" }}>
                      <Row>
                        <Col md={12}>
                          <button
                            type="button"
                            className="btn btn-primary  btn btn-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => productQtySave()}
                          >
                            Save Changes
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                }
                <Col md={parseInt(stoctTable) == 2 ? 1 : 6} className="table-header" style={{ marginTop: isMobile && "8px", display: "flex", justifyContent: "end" }}>
                  <div style={margin}>
                    {data.length > 0 ? (<button
                      type="button"
                      className="btn btn-excel  btn-label"
                      onClick={writeDataToExcel}
                    >
                      Excel <i className="far fa-file-excel label-icon"></i>
                    </button>) : (<button
                      type="button"
                      className="btn  btn-excel  btn-label"
                      style={{ cursor: "not-allowed" }}
                    >
                      Excel <i className="far fa-file-excel label-icon"></i>
                    </button>)}
                  </div>
                </Col>
              </>
            }
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered  {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        const { Status } = cell.row.original;
                        return (
                          <td
                            key={cell.id}
                            className={`${cell.column.Header == "Status" && (Status == 1 ? "booked-status-booking" : Status == 2 ? "deliverd-status-booking" : Status == 3 ? "return-status-booking" : "cancle-status-booking")} text-nowrap`}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              // onClick={() => setpageIndex(pageIndex - 1)}
              onClick={() => validationMSG(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong style={{ marginLeft: "5px" }}>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => validationMSG(pageIndex + 1)}
              // onClick={() => setpageIndex(pageIndex + 1)}
              disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment >
  )
}

StockRegisterTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default StockRegisterTable

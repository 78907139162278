import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SizeTableContainer from "../../../components/Common/SizeTableContainer"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModel from "common/deleteModel/deleteModel"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"

function SizeTable() {
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()
  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState("")
  const [screenLoad, setScreenLoad] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [sizeId, setSizeId] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)

  // size list api
  const SizeApi = () => {
    document.title = `Sizes | ${localStorage.getItem("ShopName")}`

    setIsLoading(true)
    getApi("size/list", "").then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }
  // size list api useEffect
  useEffect(() => {
    SizeApi()
  }, [])

  // Delete size handler
  const handleDelete = sizeId => {
    setScreenLoad(true)
    if (sizeId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this size permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          deleteApi(`Size/delete?id=${sizeId}`).then(({ data, status }) => {
            if (status === 200) {
              const updatedArray = tableData
                .map(obj => (obj.Id !== sizeId ? obj : null))
                .filter(Boolean)
              setTableData(updatedArray)
              toast.success("Sucessfully deleted", {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              ErrorModel.errormodel({
                title: "Verify Size",
                text: data,
              })
            }
            setScreenLoad(false)
          })
        }
        setScreenLoad(false)
      })
    }
  }

  // cancle handler
  const handleCancle = () => {
    toggle()
  }

  // Update size handler
  const handleEditSize = sizeData => {
    if (sizeData) {
      setSizeId(sizeData.Id)
      setIsEdit(true)
      setModal(!modal)
      setEditData(sizeData)
    }
  }

  // Create size handler
  const handleSizeClick = () => {
    setIsEdit(false)
    toggle()
  }

  // size Validations
  const validation = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      Name: isEdit ? editData.Name : "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("The name is required").max(10),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        isEdit
          ? putApi("size/update", {
            id: sizeId,
            name: values.Name,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("Sucessfully updated", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              SizeApi()
              validation.handleReset()
            }
          })
          : postApi("size/create", {
            name: values.Name,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("Sucessfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              SizeApi()
              validation.handleReset()
            }
          })
      }
    },
  })

  // popup box handler
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  const handleCodeChange = e => {
    validation.setFieldTouched("Code", true)
    validation.validateForm()
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Size",
        accessor: "Name",
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "7%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEditSize(row.original)}
                ></i>
                <span className="mytext">Edit</span>
              </li>

              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
      {screenLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SizeTableContainer
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isSizeList={true}
              handleSizeClick={handleSizeClick}
              isLoading={isLoading}
              className="custom-header-css"
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "350px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update Size" : "Create Size"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Name
                      </Label>
                      <Input
                        name="Name"
                        placeholder="Size Name"
                        type="text"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Name || ""}
                        invalid={
                          validation.touched.Name && validation.errors.Name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.Name && validation.errors.Name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Name}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>

                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}

                      {formSumitLoading ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                          Loading
                        </button>
                      ) : (
                        <Button
                          color="primary"
                          className="btn btn-primary btn-sm "
                          type="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
SizeTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SizeTable

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap"
import DeleteModel from "common/deleteModel/deleteModel"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import ProductTable from "./ProductTable"

function Products() {
  let { pathname } = useLocation()
  const { deleteApi, postApi, putApi, getApi } = useApi()
  document.title = `Products | ${localStorage.getItem("ShopName")}`
  const navigate = useNavigate()

  // useStates
  const [tableData, setTableData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [TotalRecords, setTotalRecords] = useState(0)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [categories, setCategories] = useState([])
  const [dispalyStart, setDisplayStart] = useState(0)
  const [isRetire, setIsRetire] = useState(false)
  const [isInStock, setIsInStock] = useState(false)
  const [isSale, setIsSale] = useState(false)
  const [categoryId, setCategoryId] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    getApi("Category/category-lookup").then(({ data, status }) => {
      if (status === 200) {
        data.unshift({
          Value: "Categories",
          Key: 0
        });
        setCategories(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setIsLoading(false)
    })
  }, [])

  // pagination
  const pagePerSize = (pageSize, pagePerIndex, serchValue, isRetire, isInStock, isSale, categoryId) => {
    setIsLoading(true)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setRowsPerPage(pageSize)
    setIsRetire(isRetire)
    setIsInStock(isInStock)
    setIsSale(isSale)
    setCategoryId(categoryId)
  }

  // productList Api
  const productListApi = () => {
    postApi("product/list", {
      DisplayStart: dispalyStart,
      PageSize: rowsPerPage,
      SearchText: searchText,
      CategoryId: parseInt(categoryId),
      SortColumn: "",
      SortOrder: "",
      IsRetire: isRetire,
      IsSale: isSale,
      IsNotInStock: isInStock,
      ForGroomWell: false,
    }).then(({ data, status }) => {
      if (status === 200) {
        setTableData(data.ProductList)
        setTotalRecords(data.TotalRecords)
        setIsLoading(false)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    productListApi()
  }, [dispalyStart, rowsPerPage, searchText, categoryId, isRetire, isSale, isInStock])


  // product delete handler
  const handleDelete = productId => {
    setIsLoading(true)
    if (productId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this product permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          deleteApi(`product/delete?id=${productId}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== productId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Product sucessfully deleted", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Product",
                  text: data,
                })
              }
              setIsLoading(false)
            }
          )
        } else {
          setIsLoading(false)
        }
      })
    }
  }

  // product edit Id handler
  const editRow = productId => {
    if (productId) {
      getApi(`Product/details?id=${productId}`).then(
        ({ data, status }) => {
          if (status === 200) {
            navigate(routes.updateproduct, {
              state: {
                productId,
                data,
              },
            })
          } else {
            setscreeLoad(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        }
      )
    }
  }

  // Retire Product
  const handleProductRetire = (productId, isRetire, e) => {
    setIsLoading(true)
    if (productId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to retire this product permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          putApi("Product/retire-product", { productId: productId }).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== productId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Product sucessfully Changed", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                $("#isRetire_" + productId + "").prop("checked", isRetire)
                ErrorModel.errormodel({
                  title: "Verify Product",
                  text: data,
                })
              }
              setIsLoading(false)
            }
          )
        } else {
          $("#isRetire_" + productId + "").prop("checked", isRetire)
          setIsLoading(false)
        }
      })
    }

  }

  // product active handler
  const handleSwitch = (id, IsActive, e) => {
    if (id) {
      putApi("Product/active", { id: String(id) }).then(({ data, status }) => {
        e.preventDefault()
        if (status === 200) {
          toast.success("Product sucessfully changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          $("#IsActive_" + id + "").prop("checked", IsActive)
          ErrorModel.errormodel({
            title: "Verify Product",
            text: data,
          })
        }
      })
    }
  }

  const handleIsWashing = (id, IsActive, e) => {
    if (id) {
      putApi("Product/washing", { id: String(id) }).then(({ data, status }) => {
        e.preventDefault()
        if (status === 200) {
          toast.success("Product sucessfully changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          $("#isWashing_" + id + "").prop("checked", IsActive)
          ErrorModel.errormodel({
            title: "Verify Product",
            text: data,
          })
        }
      })
    }
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "Code",
        width: "8%",
      },
      {
        Header: "Name",
        accessor: "Name",
        Cell: ({ row }) => (
          <span>
            {row.original.Name ? row.original.Name : "N/A"}
          </span>
        ),
      },
      {
        Header: "Category",
        accessor: "CategoryName",
        width: "6%",
        Cell: ({ row }) => (
          <span>
            {row.original.CategoryName ? row.original.CategoryName : "N/A"}
          </span>
        ),
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Rent || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            MRP
          </div>
        ),
        accessor: "MRP",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.MRP || 0)}
            </div>
          )
        },
      },
      {
        Header: "Color",
        accessor: "ColorName",
        width: "6%",
        Cell: ({ row }) => (
          <span>
            {row.original.ColorName ? row.original.ColorName : "N/A"}
          </span>
        ),
      },
      {
        Header: "Size",
        accessor: "SizeName",
        width: "5%",
        Cell: ({ row }) => (
          <span>{row.original.SizeName ? row.original.SizeName : "N/A"}</span>
        ),
      },
      {
        Header: "Date",
        accessor: "CreatedOn",
        width: "8%",
        Cell: ({ row }) => (
          <span>
            {row.original.CreatedOn.split("T")[0] == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.CreatedOn, "dd/MM/yyyy")}
          </span>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Retire
          </div>
        ),
        width: "7%",
        accessor: "IsRetire",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`isRetire_${row.original.Id}`}
              defaultChecked={row.original.IsRetire}
              onClick={e => {
                handleProductRetire(row.original.Id, row.original.IsRetire, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Active
          </div>
        ),
        width: "7%",
        accessor: "IsActive",

        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`IsActive_${row.original.Id}`}
              disabled={row.original.IsRetire == true}
              defaultChecked={row.original.IsActive}
              onClick={e => {
                handleSwitch(row.original.Id, row.original.IsActive, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              {
                row.original.IsRetire == true ? (
                  <>
                    <li className="mytooltip">
                      <i
                        className=" fs-6 fas fa-edit"
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                        id={`edittooltip${row.original.Id}`}
                      ></i>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mytooltip">
                      <i
                        className=" fs-6 fas fa-edit btn-edit"
                        id={`edittooltip${row.original.Id}`}
                        onClick={() => editRow(row.original.Id)}
                      ></i>
                      <span className="mytext">Edit</span>
                    </li>
                  </>
                )
              }
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )
  // redirect create product handler
  const handleUserClicks = () => {
    navigate(routes.createProduct)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <ProductTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isCreateProduct={true}
              handleUserClick={handleUserClicks}
              customPageSize={20}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              isLoading={isLoading}
              TotalRecords={TotalRecords}
              categories={categories}
              isRetire={isRetire}
              setIsRetire={setIsRetire}
              isInStock={isInStock}
              setIsInStock={setIsInStock}
              isSale={isSale}
              setIsSale={setIsSale}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Products.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Products

import React, { useEffect, useMemo, useState } from "react";
import {
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";
import useApi from "common/ApiMiddlewere";
import toast from "react-hot-toast";
import TableList from "components/Common/TableList";
import { dateFormat, timeFormate } from "pages/Common";

const TransactionModel = ({
    modal,
    toggle,
    saleId,
    billNo,
}) => {
    const { getApi } = useApi()
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (saleId?.saleId && billNo) {
            setIsLoading(true)
            getApi(`Sales/sale-transactions?saleId=${saleId?.saleId}`).then(
                ({ data, status }) => {
                    if (status === 200) {
                        setTableData(data)
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setIsLoading(false)
                }
            )
        }

    }, [saleId])

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "Date",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original?.Date.split("T")[0] == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original?.Date, "dd/MM/yyyy") + ` (${timeFormate(row.original.Date)})`}
                    </span>
                ),
            },
            {
                Header: "Payment",
                accessor: "PaymentMode",
                width: "7%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.PaymentMode == "1"
                            ? "Cash"
                            : row.original.PaymentMode == "2"
                                ? "Bank" : ''}
                    </p>
                ),
            },
            {
                // Header: "Bill Amount",
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Bill Amt.
                    </div>
                ),
                accessor: "Amount",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            color: "green", display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Amount || 0)}
                        </div>
                    )
                },
            },

            {
                Header: "Description",
                accessor: "Remarks",
            },
        ],
        [tableData]
    )

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        Transactions - Bill No:  {billNo}
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <TableList
                                columns={columns}
                                data={tableData}
                                isGlobalFilter={true}
                                isCategoryList={true}
                                customPageSize={10}
                                isLoading={isLoading}
                                className="custom-header-css"
                            />
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}


export default TransactionModel;

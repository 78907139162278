import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Form,
    Modal,
    ModalBody,
    Input,
    UncontrolledTooltip,
    FormGroup,
    ModalHeader,
    Button,
    Label,
    FormFeedback,
    Row,
    Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import AccountsTable from "./AccountsTable"
import { dateFormat } from "pages/Common"

function Accounts() {
    document.title = `Accounts | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { deleteApi, getApi, postApi, putApi } = useApi()
    // useStates
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState("")
    const [tableData, setTableData] = useState([])
    const [accountsDetails, setAccountsDetails] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [screenLoad, setScreenLoad] = useState(false)
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [searchText, setSearchText] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [accountGroup, setAccountsGroup] = useState([])
    const [accountId, setAccountId] = useState(0)

    const filterData = textFilter => {
        setSearchText(textFilter == undefined ? '' : textFilter)
    }

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    // Account list api
    const AccountsApi = () => {
        setScreenLoad(true)
        setIsLoading(true)
        postApi("Accounts/list", {
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.List)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
    }

    // Account list api useEffect
    useEffect(() => {
        AccountsApi()
    }, [rowsPerPage, searchText, displayStart])

    useEffect(() => {
        setScreenLoad(true)
        getApi("Accounts/account-group-lookup").then(({ data, status }) => {
            if (status === 200) {
                const AccountsIds = [4, 6, 7]    // Display Accounts Ids:  Vendors = 4,Income = 6, Expenses = 7 
                let AccountGroup = data?.filter(item => AccountsIds?.includes(item?.Key));
                setAccountsGroup(AccountGroup)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
    }, [])

    // toggle handlers
    const toggle = () => {

        setModal(!modal)
        validation.handleReset()
    }

    // cancle handler
    const handleCancle = () => {
        toggle()
    }

    // Delete Account handler
    const handleDelete = accountId => {
        setScreenLoad(true)
        if (accountId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this Account permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Accounts/delete?id=${accountId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== accountId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("successfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Category",
                                    text: data,
                                })
                            }
                            setScreenLoad(false)
                        }
                    )
                } else {
                    setScreenLoad(false)
                }
            })
        }
    }

    // Update Account handler
    const handleEditAccount = accountId => {
        setScreenLoad(true)
        if (accountId) {
            setAccountId(accountId)
            getApi(`Accounts/details?id=${accountId}`).then(({ data, status }) => {
                if (status === 200) {
                    setAccountsDetails(data);
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
                setScreenLoad(false)
            })
        }
        setIsEdit(true)
        setModal(!modal)
    }

    // Create Account handler
    const handleAccountClick = () => {
        setAccountsDetails([])
        setIsEdit(false)
        toggle()
    }

    // Accounts Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: isEdit ? accountsDetails.Name : "",
            ContactNo: isEdit ? accountsDetails.ContactNo : "",
            Email: isEdit ? accountsDetails.Email : "",
            ShopName: isEdit ? accountsDetails.ShopName : "",
            OpeningBalance: isEdit ? accountsDetails.OpeningBalance : "",
            Address: isEdit ? accountsDetails.Address : "",
            Remark: isEdit ? accountsDetails.Remarks : "",
            AccountGroup: isEdit ? accountsDetails.AccountGroup : 0,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("The name is required"),
            ContactNo: Yup.string().required("The contact no. is required"),
            AccountGroup: Yup.number().min(1, "The account group is required"),
            // Email: Yup.string()
            //     .email("Invalid email format"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                setScreenLoad(true)
                isEdit
                    ? putApi("Accounts/update", {
                        id: accountId,
                        name: values.Name || "",
                        email: values.Email || "",
                        contactNo: values.ContactNo || "",
                        shopName: values.ShopName || "",
                        address: values.Address || "",
                        openingBalance: parseFloat(values.OpeningBalance) || 0,
                        remarks: values.Remark || "",
                    }).then(({ data, status }) => {
                        setformSumitLoading(false)
                        if (status == 400) {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        } else {
                            toast.success("successfully updated", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            AccountsApi()
                            validation.handleReset()
                        }
                        setScreenLoad(false)
                    })
                    : postApi("Accounts/create", {
                        name: values.Name || '',
                        accountGroup: parseInt(values.AccountGroup),
                        email: values.Email || '',
                        contactNo: values.ContactNo || '',
                        shopName: values.ShopName || '',
                        address: values.Address || '',
                        openingBalance: parseFloat(values.OpeningBalance) || 0,
                        remarks: values.Remark || '',
                    }).then(({ data, status }) => {
                        setformSumitLoading(false)
                        if (status == 200) {
                            toast.success("successfully created", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            AccountsApi()
                            validation.handleReset()
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                        setScreenLoad(false)
                    })
            }
        },
    })

    const accountGroupValue = (accountGroupValue) => {
        const accountValue = accountGroup.find((Value) =>
            Value.Key == accountGroupValue
        )
        return accountValue ? accountValue?.Value : "";
    }

    // Category table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Contact No",
                accessor: "ContactNo",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.ContactNo == ""
                            ? "N/A"
                            : row.original.ContactNo == null
                                ? "N/A"
                                : row.original.ContactNo}
                    </span>
                ),
            },
            {
                Header: "Email",
                accessor: "Email",
                Cell: ({ row }) => (
                    <span>
                        {row.original.Email == ""
                            ? "N/A"
                            : row.original.Email == null
                                ? "N/A"
                                : row.original.Email}
                    </span>
                ),
            },
            {
                Header: "Shop Name",
                accessor: "ShopName",
                Cell: ({ row }) => (
                    <span>
                        {row.original.ShopName == ""
                            ? "N/A"
                            : row.original.ShopName == null
                                ? "N/A"
                                : row.original.ShopName}
                    </span>
                ),
            },
            {
                // Header: "Opening Balance",
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`Opening Balance`}
                    >
                        Op. Balance
                    </div>
                ),
                accessor: "OpeningBalance",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.OpeningBalance || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Address",
                accessor: "Address",
                Cell: ({ row }) => (
                    <span
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Address == null ? '' : row.original.Address}`}
                    >
                        {row.original.Address?.length > 15
                            ? `${row.original.Address?.substr(0, 15)}...`
                            : row.original.Address == ""
                                ? "N/A"
                                : row.original.Address == null
                                    ? "N/A"
                                    : row.original.Address}
                    </span>
                ),
            },
            {
                Header: "Remarks",
                accessor: "Remarks",
                width: "7%",
                Cell: ({ row }) => (
                    <span
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
                    >
                        {row.original.Remarks?.length > 15
                            ? `${row.original.Remarks?.substr(0, 15)}...`
                            : row.original.Remarks == ""
                                ? "N/A"
                                : row.original.Remarks == null
                                    ? "N/A"
                                    : row.original.Remarks}
                    </span>
                ),
            },
            {
                Header: "Account Group",
                accessor: "AccountGroup",
                Cell: ({ row }) => {
                    const value = accountGroupValue(row?.original?.AccountGroup);
                    return (
                        <span>{value}</span>
                    )
                }
            },
            {
                Header: "Date",
                accessor: "CreatedOn",
                width: "10%",
                Cell: ({ row }) => (
                    <span className=" mb-0">
                        {row.original.CreatedOn == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.CreatedOn.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <>
                                {row?.original?.InternalName ? (<>
                                    <li className="mytooltip">
                                        <i
                                            className=" fs-6 fas fa-edit btn-edit"
                                            id={`edittooltip`}
                                            style={{ cursor: "not-allowed", color: "#808080b3" }}
                                        ></i>
                                    </li>

                                    <li className="mytooltip">
                                        <i
                                            id={`deletetooltip`}
                                            style={{ cursor: "not-allowed", color: "#808080b3" }}
                                            className="fs-6 fas fa-trash-alt btn-delete"
                                        ></i>
                                    </li>
                                </>) : (
                                    <>
                                        <li className="mytooltip">
                                            <i
                                                className=" fs-6 fas fa-edit btn-edit"
                                                id={`edittooltip${row.original.Id}`}
                                                onClick={() => handleEditAccount(row.original.Id)}
                                            ></i>
                                            <span className="mytext">Edit</span>
                                        </li>

                                        <li className="mytooltip">
                                            <i
                                                id={`deletetooltip${row.original.Id}`}
                                                className="fs-6 fas fa-trash-alt btn-delete"
                                                onClick={() => handleDelete(row.original.Id)}
                                            ></i>
                                            <span className="mytext">Delete</span>
                                        </li>
                                    </>
                                )}
                            </>


                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screenLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <AccountsTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isAccountList={true}
                            handleAccountClick={handleAccountClick}
                            customPageSize={10}
                            className="custom-header-css"
                            filterData={filterData}
                            isLoading={isLoading}
                            TotalRecords={totalRecords}
                            pagePerSize={pagePerSize}
                        />

                        <Modal
                            isOpen={modal}
                            toggle={toggle}
                            centered={true}
                            size="small"
                            style={{ width: "500px" }}
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggle} tag="h5">
                                    {isEdit ? "Update Account" : "Create Account"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03" className="required">
                                                        Name
                                                    </Label>
                                                    <Input
                                                        name="Name"
                                                        placeholder="Name"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Name || ""}
                                                        invalid={
                                                            validation.touched.Name && validation.errors.Name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Name && validation.errors.Name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <label
                                                    htmlFor="name"
                                                    className="required form-label"
                                                >
                                                    Account Group
                                                </label>
                                                <Input
                                                    type="select"
                                                    id="validationCustom02"
                                                    disabled={isEdit}
                                                    bsSize="sm"
                                                    className="form-control-sm"
                                                    name="AccountGroup"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.AccountGroup}
                                                    invalid={
                                                        validation.touched.AccountGroup &&
                                                            validation.errors.AccountGroup
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <option value={0}>Select...</option>
                                                    {accountGroup.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.Key}>
                                                                {item.Value}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                                {validation.touched.AccountGroup &&
                                                    validation.errors.AccountGroup ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.AccountGroup}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Contact No
                                                    </Label>
                                                    <Input
                                                        name="ContactNo"
                                                        placeholder="Contact No."
                                                        type="text"
                                                        maxLength="10"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.ContactNo || ""}
                                                        onKeyPress={handleKeyPress}
                                                        invalid={
                                                            validation.touched.ContactNo &&
                                                                validation.errors.ContactNo
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.ContactNo &&
                                                        validation.errors.ContactNo ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.ContactNo}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom01"
                                                        className="form-label"
                                                    >
                                                        Opening Balance
                                                    </Label>
                                                    <Input
                                                        name="OpeningBalance"
                                                        disabled={isEdit}
                                                        className="form-control-sm"
                                                        placeholder="0.00"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        onKeyPress={handleKeyPress}
                                                        onFocus={(e) => { e.target.select() }}
                                                        value={validation.values.OpeningBalance}
                                                        invalid={
                                                            validation.touched.OpeningBalance &&
                                                                validation.errors.OpeningBalance
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.OpeningBalance &&
                                                        validation.errors.OpeningBalance ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.OpeningBalance}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03">
                                                        Email
                                                    </Label>
                                                    <Input
                                                        name="Email"
                                                        placeholder="Email"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Email || ""}
                                                        invalid={
                                                            validation.touched.Email && validation.errors.Email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Email && validation.errors.Email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03">
                                                        Shop Name
                                                    </Label>
                                                    <Input
                                                        name="ShopName"
                                                        placeholder="ShopName"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.ShopName || ""}
                                                        invalid={
                                                            validation.touched.ShopName && validation.errors.ShopName
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.ShopName && validation.errors.ShopName ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.ShopName}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                    >
                                                        Address
                                                    </Label>
                                                    <Input
                                                        name="Address"
                                                        placeholder="Address"
                                                        type="textarea"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Address || ""}
                                                        invalid={
                                                            validation.touched.Address &&
                                                                validation.errors.Address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Address &&
                                                        validation.errors.Address ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Address}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup  >
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom05">Remark</Label>
                                                    <Input
                                                        name="Remark"
                                                        rows="2"
                                                        placeholder="Type your Remark here"
                                                        type="textarea"
                                                        className="form-control-sm"
                                                        value={validation.values.Remark || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div className="text-end">
                                            {isEdit ? (
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2 btn-sm"
                                                    onClick={handleCancle}
                                                >
                                                    Cancle
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2 btn-sm "
                                                    type="reset"
                                                >
                                                    Reset
                                                </Button>
                                            )}

                                            <>
                                                {formSumitLoading ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm "
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Accounts.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Accounts

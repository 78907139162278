import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import breadcrumbs from 'common/Breadcrumbs'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import useApi from 'common/ApiMiddlewere'
import noImage from "../../assets/images/no_image.jpg"
import { toast } from 'react-hot-toast'
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { dateFormat } from 'pages/Common'
import CreatableSelect from "react-select/creatable"
import { useFormik } from "formik"
import * as Yup from "yup"
import TableList from 'components/Common/TableList'
import { addToCartRequest } from 'store/addtocart/action'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModel from 'common/deleteModel/deleteModel'

const colourStyles = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid  #ced4da",
            minHeight: "28px",
            padding: "3px 10px",
            fontSize: "12px",
        }
        return updatedStyles
    },
}
const colourStyles1 = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid #f46a6a",
            minHeight: "28px",
            padding: "3px 10px",
            fontSize: "12px",
        }
        return updatedStyles
    },
}

let productQty = 1
const ProductAvailable = () => {
    const { getApi, postApi } = useApi()
    const dispatch = useDispatch();
    const addToCartStatus = useSelector(state => state.ReduxData.addToCartStatus);
    let { pathname } = useLocation()

    const [screeLoad, setscreeLoad] = useState(false)
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(0)
    const [sizes, setSizes] = useState([])
    const [sizeId, setSizeId] = useState(0)
    const [catalogueData, setCatalogueData] = useState([])
    const [pagesize, setPagesize] = useState(12)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [isLoading, setIsLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [isIpad, setIsIpad] = useState(window.innerWidth < 924)
    const [date, setdate] = useState([new Date(), new Date()])
    const [isLoadiButtonng, setIsButtonLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [isLoading1, setIsLoading1] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [isError, setIsError] = useState(false)
    const [options, setOptions] = useState([])
    const [value, setValue] = useState("")
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [TimeSolteDetails, setTimeSolteDetails] = useState([])
    const [falgForTimeSlotevalidation, setfalgForTimeSlotevalidation] = useState(false)
    const [enableProductStock, setEnableProductStock] = useState(false)
    const [cartConfirm, setCartConfirm] = useState(false)
    const [cartAvailableList, setCartAvailableList] = useState([])
    const [productCode, setProductCode] = useState('')
    const [availableQty, setAvailableQty] = useState(1)

    const handlePagesize = () => {
        setPagesize(pagesize + 12)
    }

    useEffect(() => {
        setscreeLoad(true)
        getApi("Category/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Key: 0,
                    Value: "Categories"
                });
                setCategories(data)
                setCategoryId(data[0]?.Key)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })

        getApi("Size/size-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Key: 0,
                    Value: "Size"
                });
                setSizes(data)
                setSizeId(data[0]?.Key)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })

        getApi("TimeSlot/time-slot-lookup").then(({ data, status }) => {
            if (status === 200) {
                setscreeLoad(false)
                setTimeSolteDetails(data)
            } else {
                setscreeLoad(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })

        getApi("Cart/cart-customer-lookup").then(({ data, status }) => {
            if (status === 200) {
                setscreeLoad(false)
                data.map(item => {
                    options.push({
                        label: item.Value,
                        value: item.Value,
                    })
                })
            } else {
                setscreeLoad(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })

        postApi("Setting/setting-by-keys", {
            key: ["TIME_SLOT_MANDATORY", "ENABLE_PRODUCT_STOCK"],
            userId: JSON.parse(localStorage.getItem("authUser")).RoleName
                == "Salesman" ? JSON.parse(localStorage.getItem("authUser")).VendorId : (JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id),
        }).then(({ data, status }) => {
            if (status == 200) {
                setfalgForTimeSlotevalidation(data[0].Value == "1");
                setEnableProductStock(data[1].Value == "1")
            }
        })

    }, [])

    useEffect(() => {
        if (date[1]) {
            productAvailableApi()
        }
    }, [searchText, date, categoryId, sizeId, pagesize])


    const productAvailableApi = () => {
        // setIsLoading(true)
        setscreeLoad(true)
        setIsButtonLoading(true)
        postApi("Product/available-products", {
            fromDate: date[0] ? dateFormat(
                date[0],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            toDate: date[1] ? dateFormat(
                date[1],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            categoryId: parseInt(categoryId),
            sizeId: parseInt(sizeId),
            displayStart: 1,
            pageSize: pagesize,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setCatalogueData(data.ProductList)
                setTotalRecords(data.TotalRecords)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
            // setIsLoading(false)
            setIsButtonLoading(false)
        })
    }

    const flatpickrRef = useRef(null);

    const createOption = label => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ""),
    })


    const handleCreate = inputValue => {
        setInputValue(inputValue)
        setIsError(false)
        setIsLoading1(true)
        setTimeout(() => {
            const newOption = createOption(inputValue)
            setIsLoading1(false)
            setOptions(prev => [...prev, newOption])
            setIsError(false)
            setValue(newOption)
        }, 1000)
    }

    const handaleAddToCart = (productCode, Qty) => {
        setProductCode(productCode)
        setAvailableQty(Qty)
        setModal(!modal)
    }

    // Add product in cart
    const addtoCartValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            customerName: inputValue ? inputValue : value?.label,
            deliveryTimeSlot: "",
            returnTimeSlot: "",
            productQty: 1
        },
        validationSchema: Yup.object({
            deliveryTimeSlot: falgForTimeSlotevalidation ? Yup.string().required("The Delivery time is required") : Yup.string(),
            returnTimeSlot: falgForTimeSlotevalidation ? Yup.string().required("The Return time is required") : Yup.string(),
            productQty: enableProductStock && Yup.number()
                .required(" Qty is required")
                .min(1, " Qty is required")
                .max(availableQty, `The maximum quantity allowed is ${availableQty}`)
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (
                values.customerName == "" ||
                values.customerName == undefined
            ) {
                setformSumitLoading(false)
                setIsError(true)
            } else {
                postApi("Cart/add-to-cart", {
                    name: values.customerName,
                    productCode: productCode,
                    deliveryDate: date[0] ? dateFormat(
                        date[0],
                        "yyyy-MM-dd"
                    ) : new Date().toISOString().slice(0, 10),
                    returnDate: date[1] ? dateFormat(
                        date[1],
                        "yyyy-MM-dd"
                    ) : new Date().toISOString().slice(0, 10),
                    deliveryTimeSlot: values.deliveryTimeSlot,
                    returnTimeSlot: values.returnTimeSlot,
                    confirmByVendor: cartConfirm,
                    qty: values.productQty,
                }).then(({ data, status }) => {
                    setIsError(false)
                    setformSumitLoading(false)
                    if (status == 200 && data.Status == 1) {
                        setInputValue("")
                        setValue("")
                        setCartConfirm(false)
                        dispatch(addToCartRequest(addToCartStatus + 1));
                        toast.success("The product successfully added to the cart.", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        addtoCartValidation.handleReset()
                        setModal(!modal)
                    } else if (status == 200 && data.Status == 2) {
                        toast.error(data.ErrorMessage, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    } else {
                        setCartAvailableList(data.CartAvailabilityList)
                        toggle1()
                    }
                })
            }
        },
    })
    productQty = addtoCartValidation.values.productQty

    const toggle = () => {
        setModal(!modal)
        setInputValue('')
        setValue('')
        addtoCartValidation.handleReset()
    }

    const toggle1 = () => {
        setModal1(!modal1)
    }

    const handaleConfirm = () => {
        DeleteModel.confirmDeleteInquiry({
            title: "Are you sure to procced?",
            text: "",
            isMobile,
        }).then(response => {
            if (response == true) {
                setCartConfirm(true)
                addtoCartValidation.handleSubmit()
                toggle1()
            } else {
                toggle1()
            }
        })
    }

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const columns1 = useMemo(
        () => [
            {
                Header: "Customer Name",
                accessor: "CustomerName",
            },
            {
                Header: "Salesman Name",
                accessor: "SalesmanName",
            },
            {
                Header: "Delivery Date",
                accessor: "DeliveryDate,DeliveryTimeSlot",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.DeliveryDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                        {row.original.DeliveryTimeSlot ? (
                            <span> {` (${row.original.DeliveryTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Return Date",
                accessor: "ReturnDate,ReturnTimeSlot",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReturnDate == null
                            ? "N/A"
                            : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
                        {row.original.ReturnTimeSlot ? (
                            <span> {` (${row.original.ReturnTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
        ],
        []
    )


    document.title = `Product Available | ${localStorage.getItem("ShopName")}`

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    {screeLoad && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}
                    <Breadcrumbs
                        parent={breadcrumbs[pathname].parent}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                        parentLink={breadcrumbs[pathname].parentLink}
                    />

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={isIpad ? "4" : "2"}>
                                    <div className="mb-2">
                                        <FormGroup className="mb-2">
                                            <input
                                                onChange={e => {
                                                    setSearchText(e.target.value)
                                                }}
                                                id="search-bar-0"
                                                type="text"
                                                className="form-control"
                                                placeholder={"Search Code…"}
                                                value={searchText || ""}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={3} className="table-header" >
                                    <div className=" d-flex">
                                        <Flatpickr
                                            ref={flatpickrRef}
                                            className="form-control d-block"
                                            placeholder="dd M,yyyy"
                                            options={{
                                                mode: "range",
                                                dateFormat: "d-m-Y"
                                            }}
                                            value={date}
                                            onChange={(e) => { setdate(e) }}

                                        />
                                        <i
                                            className="fas fa-calendar-alt calander-icon"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                flatpickrRef.current.flatpickr.open();
                                            }}
                                        ></i>
                                    </div>
                                </Col>
                                <Col md={isIpad ? "3" : "2"}>
                                    <div className="mb-2">
                                        <FormGroup className="mb-2">
                                            <Input
                                                type="select"
                                                id="validationCustom02"
                                                className="form-control"
                                                name="Category"
                                                onChange={e => setCategoryId(e.target.value, setPagesize(12))}
                                                value={categoryId}
                                            >
                                                {categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.Key}>
                                                            {item.Value}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={isIpad ? "3" : "2"}>
                                    <div className="mb-2">
                                        <FormGroup className="mb-2">
                                            <Input
                                                type="select"
                                                id="validationCustom02"
                                                className="form-control"
                                                name="Size"
                                                onChange={e => setSizeId(e.target.value)}
                                                value={sizeId}
                                            >
                                                {sizes.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.Key}>
                                                            {item.Value}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </Col>
                                {/* <Col md={3} className="table-header mb-2 text-end" >
                                    <div style={{fontSize:"18px", fontWeight:"600", color:"green"}}>
                                        Available Products: {totalRecords}
                                    </div>
                                </Col> */}
                            </Row>

                            {catalogueData.length ?
                                <div>
                                    <Row>
                                        {catalogueData?.map((data, key) => (
                                            <Col xl={3} sm={6} key={"_col_" + key}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="product-img position-relative"
                                                            style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
                                                        >
                                                            <img
                                                                src={data?.Image ? data.Image : noImage}
                                                                alt={noImage}
                                                                style={{ objectFit: "cover", height: "320px", width: "250px", textAlign: "center", alignItems: "center" }}
                                                                className="img-fluid d-block"
                                                            />
                                                        </div>
                                                        <div className="mt-3 text-center d-flex justify-content-center gap-3">
                                                            {enableProductStock && <div style={{ marginTop: "3px", fontSize: "16px", fontWeight: "bold" }}> Qty: {data.Stock} </div>}
                                                            <div style={{ marginTop: "5px" }}>
                                                                <h5 className="">
                                                                    <span className=''>  <b>{data?.Code}</b></span>
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <div className="">
                                                                    <Button
                                                                        color="primary"
                                                                        className="btn btn-primary btn-sm"
                                                                        type="button"
                                                                        onClick={() => handaleAddToCart(data?.Code, data?.Stock)}
                                                                    >
                                                                        Book
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    {catalogueData.length != totalRecords && <Row>
                                        <Col md="12" >
                                            {isLoading ?
                                                <button type="button" className="btn btn-primary" style={{ width: "100%" }}>
                                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                    Loading
                                                </button>
                                                :
                                                <Button
                                                    color="primary"
                                                    className="btn btn-primary "
                                                    type="button"
                                                    onClick={handlePagesize}
                                                    style={{ width: "100%" }}
                                                >
                                                    Load more
                                                </Button>
                                            }
                                        </Col>
                                    </Row>}
                                </div>
                                :
                                <Row>
                                    <Col md="12">
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                                            {
                                                "No Record Found"
                                            }
                                        </div>

                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>

                    <Modal
                        isOpen={modal}
                        toggle={toggle}
                        centered={true}
                        size="small"
                        style={{ width: "350px" }}
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={toggle} tag="h5">
                                Add to Cart
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        addtoCartValidation.handleSubmit()
                                        return false
                                    }}
                                    onReset={e => {
                                        e.preventDefault
                                        addtoCartValidation.handleReset()
                                        return false
                                    }}
                                >
                                    <Row>
                                        <Col md={enableProductStock ? 8 : 12} className="mb-2">
                                            <label
                                                htmlFor="name"
                                                className="required form-label"
                                            >
                                                Customer Name
                                            </label>
                                            <CreatableSelect
                                                name="customerName"
                                                unstyled
                                                styles={isError ? colourStyles1 : colourStyles}
                                                isClearable
                                                isDisabled={isLoading1}
                                                isLoading={isLoading1}
                                                onChange={newValue => {
                                                    setValue(newValue), setIsError(false)
                                                }}
                                                onCreateOption={handleCreate}
                                                options={options}
                                                value={value}
                                            />
                                            {isError ? (
                                                <div className="name-required">
                                                    {"Customer name is required"}
                                                </div>
                                            ) : null}
                                        </Col>
                                        {enableProductStock &&
                                            <Col md={4}>
                                                <Label className="form-label">
                                                    Qty
                                                </Label>
                                                <Input
                                                    name="productQty"
                                                    className="form-control-sm"
                                                    placeholder="0"
                                                    max={availableQty}
                                                    type="number"
                                                    onChange={addtoCartValidation.handleChange}
                                                    onBlur={addtoCartValidation.handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    value={parseInt(addtoCartValidation.values.productQty)}
                                                    invalid={
                                                        (addtoCartValidation.touched.productQty &&
                                                            addtoCartValidation.errors.productQty)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-2">
                                            <label htmlFor="name" className="form-label">
                                                Delivery time
                                            </label>
                                            <Input
                                                name="deliveryTimeSlot"
                                                placeholder="Delivery Time"
                                                bsSize="sm"
                                                type="select"
                                                className="form-control-sm"
                                                onChange={addtoCartValidation.handleChange}
                                                onBlur={addtoCartValidation.handleBlur}
                                                value={
                                                    addtoCartValidation.values
                                                        .deliveryTimeSlot || ""
                                                }
                                                invalid={
                                                    addtoCartValidation.touched
                                                        .deliveryTimeSlot &&
                                                        addtoCartValidation.errors
                                                            .deliveryTimeSlot
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option value={""}>Select</option>
                                                {TimeSolteDetails.map(item => {
                                                    return (
                                                        <option
                                                            key={item.Key}
                                                            value={item.Value}
                                                        >
                                                            {item.Value}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                            {addtoCartValidation.touched
                                                .deliveryTimeSlot &&
                                                addtoCartValidation.errors
                                                    .deliveryTimeSlot ? (
                                                <FormFeedback type="invalid">
                                                    {
                                                        addtoCartValidation.errors
                                                            .deliveryTimeSlot
                                                    }
                                                </FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label htmlFor="name" className="form-label">
                                                Return time
                                            </label>
                                            <Input
                                                name="returnTimeSlot"
                                                placeholder="Return Time"
                                                bsSize="sm"
                                                type="select"
                                                className="form-control-sm"
                                                onChange={addtoCartValidation.handleChange}
                                                onBlur={addtoCartValidation.handleBlur}
                                                value={
                                                    addtoCartValidation.values
                                                        .returnTimeSlot || ""
                                                }
                                                invalid={
                                                    addtoCartValidation.touched
                                                        .returnTimeSlot &&
                                                        addtoCartValidation.errors.returnTimeSlot
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option value={""}>Select</option>
                                                {TimeSolteDetails.map(item => {
                                                    return (
                                                        <option
                                                            key={item.Key}
                                                            value={item.Value}
                                                        >
                                                            {item.Value}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                            {addtoCartValidation.touched.returnTimeSlot &&
                                                addtoCartValidation.errors.returnTimeSlot ? (
                                                <FormFeedback type="invalid">
                                                    {
                                                        addtoCartValidation.errors
                                                            .returnTimeSlot
                                                    }
                                                </FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}
                                            className="mb-2 check_button Button_aline available_button"
                                        >
                                            <>
                                                {formSumitLoading ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                        style={{
                                                            width: "100%",
                                                            cursor: "not-allowed",
                                                        }}
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        className="btn btn-primary btn-sm w-full "
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            addtoCartValidation.handleSubmit()
                                                            return false
                                                        }}
                                                    >
                                                        Add to Cart
                                                    </Button>
                                                )}
                                            </>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </div>
                    </Modal>

                    <Modal isOpen={modal1} toggle={toggle1} centered={true}>
                        <div className="modal-content ">
                            <ModalHeader className="text-centre" toggle={toggle1} tag="h5">
                                Product already in Cart
                            </ModalHeader>
                            <ModalBody>
                                <TableList
                                    columns={columns1}
                                    data={cartAvailableList}
                                    className="custom-header-css"
                                />
                            </ModalBody>
                            <Row>
                                <Col
                                    md="12"
                                    className="text-end"
                                    style={{ marginBottom: "5px" }}
                                >
                                    <Button
                                        color="secondary"
                                        className="btn btn-primary btn-sm me-2"
                                        type="button"
                                        onClick={() => toggle1()}
                                    >
                                        Cancle
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-sm me-2"
                                        type="submit"
                                        onClick={() => {
                                            handaleConfirm()
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProductAvailable
import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
} from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function GlobalFilter({
  preGlobalFilteredRows,
  setGlobalFilter,
  value,
  setValue,
}) {
  const count = preGlobalFilteredRows.length
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
    setValue(value)
  }, 200)

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="search-box w-full d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search…"}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

const PendingBillTable = ({
  columns,
  data,
  isGlobalFilter,
  TotalRecords,
  isJobListGlobalFilter,
  className,
  pagePerSize,
  isLoading = { isLoading },
  setdateDisble,
  dateDisble,
  date, setdate,
  financeAmountShowHide,
  totalBillAmount,
  totalPendingAmount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )

  const checkbox = useRef();
  const [pageIndex, setpageIndex] = useState(1)
  const [prevValue, setPrevValue] = useState();
  const [value, setValue] = React.useState()

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value)
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1);
    }
  }, [value]);

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [isMobile, setisMobile] = useState(window.innerWidth < 768)

  const margin = {
    marginTop: isMobile ? "8px" : "",
  }

  const handleClick = () => {
    setdateDisble(!dateDisble);
  }

  useEffect(() => {
    setpageIndex(1);
  }, [pageSize]);

  const flatpickrRef = useRef(null);

  return (
    <Fragment>
      <Row className="">
        <Col md="12">
          <Row>
            <Col md="2" className="table-header" style={margin}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md="2" className="table-header" style={margin}>
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
            <Col md={4} className="table-header" style={margin}>
              <div className="d-flex" >
                <div className="form-control " onClick={() => handleClick()} style={{ width: "40px", borderRight: "none", backgroundColor: "#EFF2F7", display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <input
                    id="checkbox"
                    type="checkbox"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                    checked={dateDisble}
                    onChange={e => setdateDisble(e.target.checked)}
                    ref={checkbox}
                  />
                </div>
                <Flatpickr
                  ref={flatpickrRef}
                  disabled={!dateDisble}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y"
                  }}
                  value={date}
                  onChange={(e) => { setdate(e) }}
                />
                <i
                  className="fas fa-calendar-alt calander-icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    flatpickrRef.current.flatpickr.open();
                  }}
                ></i>
              </div>
            </Col>
            <Col md={4} style={{ display: "flex", justifyContent: "end", textAlign: "right", marginTop: isMobile ? "8px" : "", }}>
              <div className="d-flex" style={{ justifyContent: "end", gap: "13px" }}>
                <div>
                  {isGlobalFilter && (
                    <div style={{ color: "#0F6E25", fontSize: "15px" }}>
                      Total Bill Amount:  {financeAmountShowHide ? <span><i className="mdi mdi-currency-inr"></i>
                        {new Intl.NumberFormat('en-IN', {
                          currency: 'INR',
                          minimumFractionDigits: 2,
                        }).format(totalBillAmount || 0)}
                      </span> : "*****"} <br />
                      Total Pending Amount:  {financeAmountShowHide ? <span><i className="mdi mdi-currency-inr"></i>
                        {new Intl.NumberFormat('en-IN', {
                          currency: 'INR',
                          minimumFractionDigits: 2,
                        }).format(totalPendingAmount || 0)}
                      </span> : "*****"}</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row >

      <div className="table-responsive react-table">
        <Table bordered  {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        const { MinStatus } = cell.row.original;
                        return (
                          <td
                            key={cell.id}
                            className={`${cell.column.Header == "Status" && (MinStatus == 1 ? "booking-status-booking" : MinStatus == 2 ? "deliverd-status-booking" : MinStatus == 3 ? "return-status-booking" : "cancle-status-booking")} text-nowrap`}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong style={{ marginLeft: "5px" }}>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex + 1)}
              disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment >
  )
}

PendingBillTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PendingBillTable

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    FormGroup,
} from "reactstrap"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import ExportProductTable from "./ExportProductTable"
import noImage from "../../../assets/images/no_image.jpg"
import UpdateGroomWellProduct from "../Product/UpdateGroomWellProduct"


function ExportProductGroomWell() {
    let { pathname } = useLocation()
    const { postApi, getApi } = useApi()
    document.title = `Export Product | ${localStorage.getItem("ShopName")}`

    // useStates
    const [tableData, setTableData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [TotalRecords, setTotalRecords] = useState(0)
    const [categories, setCategories] = useState([])
    const [dispalyStart, setDisplayStart] = useState(0)
    const [categoryId, setCategoryId] = useState(0)
    const [modal, setModal] = useState(false)
    const [editProductId, setEditProductId] = useState(0)
    const [isEdit, setIsEdit] = useState(true)
    const [productData, setProductData] = useState(null)

    useEffect(() => {
        // category lookup api
        getApi("Category/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Value: "Select category",
                    Key: 0
                });
                setCategories(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })

    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue, categoryId) => {
        setSearchText(serchValue == undefined ? "" : serchValue)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setRowsPerPage(pageSize)
        setCategoryId(categoryId)
    }

    // productList Api
    const productListApi = () => {
        setIsLoading(true)
        postApi("product/list", {
            DisplayStart: dispalyStart,
            PageSize: rowsPerPage,
            SearchText: searchText,
            CategoryId: parseInt(categoryId),
            SortColumn: "",
            SortOrder: "",
            IsRetire: false,
            IsNotInStock: false,
            ForGroomWell: true,
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data.ProductList)
                setTotalRecords(data.TotalRecords)
                setIsLoading(false)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                setIsLoading(false)
            }
        })
    }

    // product list api
    useEffect(() => {
        productListApi()
    }, [dispalyStart, rowsPerPage, searchText, categoryId,])


    // images urls state update

    // product export submit function
    const handleSubmit = (Id, value) => {
        if (Id) {
            setProductData(value)
            setEditProductId(Id)
            toggle()
            setIsEdit(false)
        }
    }

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Images",
                // accessor: "ImageUrl",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <>
                            <div>
                                <FormGroup>
                                    <div
                                        style={{
                                            border: "1px solid #0000004d",
                                            height: "75px",
                                            width: "65px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "2%",
                                        }}
                                    >
                                        <>
                                            <img
                                                style={{
                                                    height: "75px",
                                                    width: "65px",
                                                }}
                                                src={(row.original.ImageUrl || noImage)}
                                            />
                                        </>
                                    </div>
                                </FormGroup>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Code",
                accessor: "Code",
                width: "7%",
            },
            {
                Header: "Category",
                accessor: "CategoryName",
                width: "7%",
            },
            {
                Header: "Product Title",
                accessor: "Name",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            {
                                <>
                                    <li className="mytooltip1">
                                        <i
                                            id={`deletetooltip${row.original.Id}`}
                                            className="far fa-plus-square btn-edit"
                                            style={{ fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
                                            onClick={() => handleSubmit(row.original.Id, row.original)}
                                        ></i>
                                        <span className="mytext1">Product Add<br /> in GroomWell</span>
                                    </li>
                                </>
                            }
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <ExportProductTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={true}
                            customPageSize={20}
                            className="custom-header-css"
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            categories={categories}
                            categoryId={categoryId}
                            setCategoryId={setCategoryId}
                        />
                    </CardBody>
                </Card>
                {!isEdit &&
                    <UpdateGroomWellProduct
                        toggle={toggle}
                        modal={modal}
                        editProductId={editProductId}
                        isEdit={isEdit}
                        productData={productData}
                        listApi={productListApi}
                    />
                }
            </div>
        </div>
    )
}
ExportProductGroomWell.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default ExportProductGroomWell

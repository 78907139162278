import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Input,
} from "reactstrap"
import DeleteModel from "../../../common/deleteModel/deleteModel"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"
import HoldTable from "pages/Transiction/HoldBills/HoldTable"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"


function Holdbills() {
    document.title = `Hold Bills | ${localStorage.getItem("ShopName")}`
    const { deleteApi, postApi, putApi } = useApi()
    const navigate = useNavigate()
    let { pathname } = useLocation()

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [searchText, setSearchText] = useState("")
    const [statusCancel, setStatusCancel] = useState(false)
    const [status, setStatus] = useState(0)
    const [displayStart, setDisplayStart] = useState(1)
    const [TotalRecords, setTotalRecords] = useState(0.0)
    const [dateDisble, setdateDisble] = useState(true)
    const [date, setdate] = useState([new Date(), new Date()])
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "" })
    const [modal1, setModal1] = useState(false)

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setRowsPerPage(pageSize)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
    }

    const statusValue = status => {
        if (status) {
            setStatus(status)
        }
    }

    const HoldBillsReportApi = () => {
        setIsLoading(true)
        postApi("Booking/hold-settled-booking-list", {
            fromDate: !dateDisble
                ? null
                : date[0]
                    ? dateFormat(date[0], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            toDate: !dateDisble
                ? null
                : date[1]
                    ? dateFormat(date[1], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            displayStart: displayStart,
            status: parseInt(status),
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.BookingList)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // booking list API useEffect
    useEffect(() => {
        setStatusCancel(false)
        if (date[1] != undefined) {
            HoldBillsReportApi()
        }
    }, [
        statusCancel,
        status,
        date,
        rowsPerPage,
        searchText,
        displayStart,
        dateDisble,
    ])

    //  Delete Booking handler
    const handleDelete = bookingDeleteId => {
        setIsLoading(true)
        if (bookingDeleteId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this booking permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`booking/delete?id=${bookingDeleteId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== bookingDeleteId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("Booking deleted successfully", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Booking",
                                    text: data,
                                })
                            }
                            setIsLoading(false)
                        }
                    )
                } else {
                    setIsLoading(false)
                }
            })
        }
    }

    // Booking table in change status handler
    const handleBookingStatus = (bookingId, statusId, row) => {
        setIsLoading(true)
        if ((bookingId, statusId)) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure you want to change status?",
                text: "If you change the status of this item, the status of all the product inside it will be changed.",
                isMobile,
            }).then(response => {
                if (response == true) {
                    const productId = ""
                    putApi("Booking/change-Status", {
                        bookingId: bookingId == "" ? 0 : bookingId,
                        bookingItemId: productId == "" ? 0 : productId,
                        BookingStatus: parseInt(statusId),
                    }).then(({ data, status }) => {
                        if (status === 200) {
                            HoldBillsReportApi()
                            toast.success("Status change sucessfully", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                        setIsLoading(false)
                    })
                } else {
                    setStatusCancel(true)
                    setIsLoading(false)
                }
            })
        } else {
            setIsLoading(false)
        }
    }

    const toggle1 = () => {
        setModal1(!modal1)
    }

    // view booking
    const handleViewBooking = bookingId => {
        setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
        toggle1()
    }

    // hold bills table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "4%",
                Cell: ({ row }) => {

                    return <>
                        <div
                            style={{ cursor: "pointer", color: "#556ee6" }}
                            onClick={() => {
                                handleViewBooking(row.original.Id)
                            }}
                        >
                            {row.original.BillNo}
                        </div>
                    </>
                },
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "ContactNo1",
                width: "6%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.NetAmount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Advance
                    </div>
                ),
                accessor: "Advance",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Advance || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Remarks",
                accessor: "Remarks",
                width: "15%",
                Cell: ({ row }) => (
                    <p
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
                    >
                        {row.original.Remarks.length > 15
                            ? `${row.original.Remarks.substr(0, 15)}...`
                            : row.original.Remarks == ""
                                ? "N/A"
                                : row.original.Remarks}
                    </p>
                ),
            },
            {
                Header: "Expire Date",
                accessor: "ExpireDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.ExpireDate == null
                            ? "N/A"
                            : dateFormat(
                                row.original?.ExpireDate?.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Booking Date",
                accessor: "BookingDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.BookingDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.BookingDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Status
                    </div>
                ),
                width: "8%",
                // width: 100,
                accessor: "MinStatus",
                Cell: ({ row }) => {
                    const { MinStatus } = row.original
                    return <>
                        <div
                            className="textWrap"
                            style={{
                                height: "8px",
                                textAlign: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Input
                                type="select"
                                onChange={e =>
                                    handleBookingStatus(
                                        row.original.Id,
                                        e.target.value,
                                        row.original
                                    )
                                }
                                disabled={row.original.MinStatus == 6}
                                defaultValue={row.original.MinStatus}
                                bsSize="sm"
                                className={`${MinStatus == 1 ? "booking-status-booking" : MinStatus == 2 ? "deliverd-status-booking" : MinStatus == 3 ? "return-status-booking" : 'cancle-status-booking'} form-control`}
                            >
                                <option className="bg-light text-dark" value={5}>
                                    Hold
                                </option>
                                <option className="bg-light text-dark" value={6}>
                                    Settled
                                </option>
                            </Input>
                        </div>
                    </>
                }
                ,
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "Action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <>
                            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                                <li className="mytooltip">
                                    <i
                                        className="fs-6 fas fa-trash-alt btn-delete"
                                        id={`deletetooltip${row.original.id}`}
                                        onClick={() => handleDelete(row.original.Id)}
                                    ></i>
                                    <span className="mytext">Delete</span>
                                </li>
                            </ul>
                        </>
                    )
                },
            },
        ],
        [tableData]
    )

    // Create Booking handler
    const handleBookingClicks = () => {
        navigate(routes.createbooking)
    }

    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <HoldTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            statusValue={statusValue}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                            dateDisble={dateDisble}
                            setdateDisble={setdateDisble}
                            date={date}
                            setdate={setdate}
                        />

                        <InvoiceDetalis
                            toggle={toggle1}
                            modal={modal1}
                            bookingId={BookingIdandStatusId.bookingId}
                        />

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Holdbills.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Holdbills

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import { toast, Toaster, ToastBar } from "react-hot-toast"
import CustomSwitch from "constants/CustomSwitch"
import { routesRoles } from "common/routesRoles"
import { routes } from "routes/path"
import initializeApp from "common/initializeApp"
// import ErrorBoundry from "./pages/Utility/InternalServerError"


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}


// initializeApp()

const App = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('');

  useEffect(() => {
    const originalFetch = window.fetch;

    const handleOfflineStatusChange = () => {
      setError('offline');
    };

    const handleOnlineStatusChange = () => {
      setError('contact us');
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOfflineStatusChange);

    window.fetch = function (...args) {
      const [url, options] = args;

      return originalFetch.apply(this, args)
        .then(response => {
          setError('');
          return response;
        })
        .catch(error => {
          setError('error occurred');
          document.getElementsByClassName('page-content')[0].className =
            'page-content-warning';
          throw error;
        });
    };

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOfflineStatusChange);
    };
  }, []);

  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  const Layout = getLayout(layoutType)
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      routesRoles[pathname] == undefined &&
      (pathname != "/inquiry" && pathname != routes.plans && pathname != routes.decryptData)
    ) {
      localStorage.clear()
      navigate("/login")
    }
  }, [routesRoles[pathname] == undefined])

  // authProtectedRoutes.map((route, idx) => console.log(route))

  return (
    <React.Fragment>




      <CustomSwitch>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </CustomSwitch>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Form,
    Modal,
    ModalBody,
    Input,
    UncontrolledTooltip,
    ModalHeader,
    Button,
    Label,
    FormFeedback,
    Row,
    Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import { dateFormat } from "pages/Common"
import JournalVouchersTable from "./JournalVouchersTable"
import GetObjectsByValues, { GetObjectsByAccountGroups } from "_mock/Static_data"

function JournalVouchers() {
    document.title = `Journal Vouchers | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { deleteApi, getApi, postApi, putApi } = useApi()
    // useStates
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [tableData, setTableData] = useState([])
    const [voucherDetails, setVoucherDetails] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [screenLoad, setScreenLoad] = useState(false)
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [searchText, setSearchText] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [voucherId, setVoucherId] = useState(0)
    const [accounts, setAccounts] = useState([])

    const filterData = textFilter => {
        setSearchText(textFilter == undefined ? '' : textFilter)
    }

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    // Account list api
    const JournalVoucherApi = () => {
        setScreenLoad(true)
        setIsLoading(true)
        postApi("JournalVouchers/list", {
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.JournalVouchersList)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
    }

    // Account list api useEffect
    useEffect(() => {
        JournalVoucherApi()
    }, [rowsPerPage, searchText, displayStart])

    useEffect(() => {
        setScreenLoad(true)
        getApi(`Accounts/accounts-lookup?accountGroup=${GetObjectsByAccountGroups(0)[0].Value}`).then(({ data, status }) => {
            if (status === 200) {
                setAccounts(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })

    }, [])

    // toggle handlers
    const toggle = () => {
        setModal(!modal)
        validation.handleReset()
    }

    // cancle handler
    const handleCancle = () => {
        toggle()
    }

    // Delete Account handler
    const handleDelete = ItemId => {
        setScreenLoad(true)
        if (ItemId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this Voucher permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`JournalVouchers/delete?id=${ItemId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== ItemId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("successfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Category",
                                    text: data,
                                })
                            }
                            setScreenLoad(false)
                        }
                    )
                } else {
                    setScreenLoad(false)
                }
            })
        }
    }

    // Create Account handler
    const handleJournalVoucherClick = () => {
        setIsEdit(false)
        toggle()
    }

    const handleEditVoucher = voucherId => {
        setScreenLoad(true)
        setIsEdit(true)
        if (voucherId) {
            setVoucherId(voucherId)
            getApi(`JournalVouchers/details?id=${voucherId}`).then(({ data, status }) => {
                if (status === 200) {
                    setVoucherDetails(data);
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
                setScreenLoad(false)
            })
            setModal(!modal)
        }
    }

    // JournalVouchers Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Date: isEdit ? dateFormat(
                voucherDetails?.Date?.split("T")[0],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            Amount: isEdit ? voucherDetails?.Amount : 0,
            Remark: isEdit ? voucherDetails?.Remarks : "",
            DrAccount: isEdit ? voucherDetails?.DrAccountId : 0,
            CrAccount: isEdit ? voucherDetails?.CrAccountId : 0,
        },
        validationSchema: Yup.object({
            Date: Yup.string().required("The date is required"),
            Remark: Yup.string().required("The remark is required"),
            Amount: Yup.number().min(1, "The amount is required").required("The amount is required"),
            DrAccount: Yup.number().min(1, "The dr.amount is required"),
            CrAccount: Yup.number().min(1, "The cr. amount is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                setScreenLoad(true)
                const Api = isEdit ? putApi : postApi
                const endPoint = isEdit ? "JournalVouchers/update" : "JournalVouchers/create";
                const payload = {
                    id: isEdit ? voucherId : 0,
                    date: values.Date,
                    remarks: values.Remark,
                    amount: parseFloat(values.Amount) || 0,
                    crAccountId: parseInt(values.CrAccount),
                    drAccountId: parseInt(values.DrAccount)
                }
                const messageToster = isEdit ? "successfully updated" : "successfully created";
                Api(endPoint, payload).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 200) {
                        toast.success(messageToster, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        toggle()
                        JournalVoucherApi()
                        validation.handleReset()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setScreenLoad(false)
                })
            }
        },
    })

    // Category table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Remarks",
                accessor: "Remarks",
                Cell: ({ row }) => (
                    <span
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
                    >
                        {row.original.Remarks.length > 15
                            ? `${row.original.Remarks.substr(0, 15)}...`
                            : row.original.Remarks == ""
                                ? "N/A"
                                : row.original.Remarks}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Amount
                    </div>
                ),
                accessor: "Amount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Amount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Sale Date",
                accessor: "Date",
                width: "10%",
                Cell: ({ row }) => (
                    <span className=" mb-0">
                        {row.original.Date == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.Date.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </span>
                ),
            },
            {
                Header: "Date",
                accessor: "CreatedOn",
                width: "10%",
                Cell: ({ row }) => (
                    <span className=" mb-0">
                        {row.original.CreatedOn == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.CreatedOn.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit"
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleEditVoucher(row.original.Id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    id={`deletetooltip${row.original.Id}`}
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screenLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <JournalVouchersTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isAccountList={true}
                            handleJournalVoucherClick={handleJournalVoucherClick}
                            customPageSize={10}
                            className="custom-header-css"
                            filterData={filterData}
                            isLoading={isLoading}
                            TotalRecords={totalRecords}
                            pagePerSize={pagePerSize}
                        />

                        <Modal
                            isOpen={modal}
                            toggle={toggle}
                            centered={true}
                            size="small"
                            style={{ width: "450px" }}
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggle} tag="h5">
                                    {isEdit ? "Update Journal Vouchers" : "Create Journal Vouchers"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <Row>
                                            <Col md={6} className="mb-2">
                                                <div className="mb-2">
                                                    <Label
                                                        htmlFor="productDetailsValidationCustom01"
                                                        className="required"
                                                    >
                                                        Date
                                                    </Label>
                                                    <Input
                                                        name="Date"
                                                        placeholder="date placeholder"
                                                        type="date"
                                                        // min={new Date().toISOString().slice(0, 10)}
                                                        dateFormat="DD/M/YYYY"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values
                                                                .Date
                                                        }
                                                        invalid={
                                                            validation.touched
                                                                .Date &&
                                                                validation.errors.Date
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Date &&
                                                        validation.errors.Date ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Date}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6} className="mb-2">
                                                <div className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom01"
                                                        className="form-label required"
                                                    >
                                                        Amount
                                                    </Label>
                                                    <Input
                                                        name="Amount"
                                                        className="form-control-sm"
                                                        placeholder="0.00"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        onKeyPress={handleKeyPress}
                                                        onFocus={(e) => { e.target.select() }}
                                                        value={validation.values.Amount}
                                                        invalid={
                                                            validation.touched.Amount &&
                                                                validation.errors.Amount
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Amount &&
                                                        validation.errors.Amount ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Amount}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} className="mb-2">
                                                <div className="mb-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="required form-label"
                                                    >
                                                        Dr. Account
                                                    </label>
                                                    <Input
                                                        type="select"
                                                        id="validationCustom02"
                                                        bsSize="sm"
                                                        className="form-control-sm"
                                                        name="DrAccount"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.DrAccount}
                                                        invalid={
                                                            validation.touched.DrAccount &&
                                                                validation.errors.DrAccount
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value={0}>Select...</option>
                                                        {accounts.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.Id}>
                                                                    {item.Name}
                                                                </option>
                                                            )
                                                        })}
                                                    </Input>
                                                    {validation.touched.DrAccount &&
                                                        validation.errors.DrAccount ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.DrAccount}
                                                        </FormFeedback>
                                                    ) : null}</div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="required form-label"
                                                    >
                                                        Cr. Account
                                                    </label>
                                                    <Input
                                                        type="select"
                                                        id="validationCustom02"
                                                        bsSize="sm"
                                                        className="form-control-sm"
                                                        name="CrAccount"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.CrAccount}
                                                        invalid={
                                                            validation.touched.CrAccount &&
                                                                validation.errors.CrAccount
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value={0}>Select...</option>
                                                        {accounts.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.Id}>
                                                                    {item.Name}
                                                                </option>
                                                            )
                                                        })}
                                                    </Input>
                                                    {validation.touched.CrAccount &&
                                                        validation.errors.CrAccount ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.CrAccount}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-2">
                                                    <Label htmlFor="validationCustom03" className="required">
                                                        Remark
                                                    </Label>
                                                    <Input
                                                        name="Remark"
                                                        placeholder="Remark"
                                                        type="textarea"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Remark || ""}
                                                        invalid={
                                                            validation.touched.Remark && validation.errors.Remark
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Remark && validation.errors.Remark ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Remark}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="text-end">
                                            {isEdit ? (
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2 btn-sm"
                                                    onClick={handleCancle}
                                                >
                                                    Cancle
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2 btn-sm "
                                                    type="reset"
                                                >
                                                    Reset
                                                </Button>
                                            )}

                                            <>
                                                {formSumitLoading ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm "
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
JournalVouchers.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default JournalVouchers

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,

} from "reactstrap"
// import logoSm from "../../assets/images/logo/logo.png"
import logoSm from "../../assets/images/logo/logo.png"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert"
import toast from "react-hot-toast"

// action
// import { apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link, useLocation, useNavigate } from "react-router-dom"

// import images
import profileImg from "../../assets/images/loginpage/inquiry.png"
import useApi from "common/ApiMiddlewere"
import { decryptData, encryptData } from "middleware/encryption"
import appConfig from "Api/app.config"

const Register = props => {
  const navigate = useNavigate()
  const { postApi } = useApi()
  const { state } = useLocation()

  // const dispatch = useDispatch()
  const [isLoading, setisLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      shopname: "",
      address: "",
      contactno: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("The name is required."),
      shopname: Yup.string().required("The shop name is required."),
      address: Yup.string().required("The address is required."),
      contactno: Yup.string()
        .required("The contact number is required.")
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number"),
    }),
    onSubmit: async values => {

      postApi("Inquiry/create", {
        Name: values.name,
        ContactNo: values.contactno,
        ShopName: values.shopname,
        Address: values.address,
        Description: values.description,
        PlanName: "",
      }).then(async ({ data, status }) => {
        if (status === 200) {
          setisLoading(false)
          navigate("/submission-inquiry")
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // const { user, registrationError, loading } = useSelector(state => ({
  //   user: state.Account.user,
  //   registrationError: state.Account.registrationError,
  //   loading: state.Account.loading,
  // }))

  // useEffect(() => {
  //   dispatch(apiError(""))
  // }, [])

  document.title = `Rentopus`

  return (
    <React.Fragment>

      <div className="" style={{ backgroundImage: "linear-gradient(to right, #27374D ,#27374dd6)" }}>
        <Container>
          <Row className="justify-content-center align-items-center" style={{ height: "100vh" }}>
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="d-flex " style={{ backgroundColor: "#eef2f3" }}>
                  <Row className="mb-2">


                    <Col xs={7} className="">
                      <div className="p-4 d-flex justify-content-start align-items-center">
                        <div>
                          <h5 className="" style={{ color: "#394867", marginTop: "5px" }}>Create An Inquiry.</h5>
                          <p style={{ color: "#394867", marginBottom: 0 }}>We will get in touch with you shortly.</p>
                        </div>

                      </div>
                    </Col>
                    <Col className="col-5 d-flex justify-content-center align-items-center">

                      <img src={"https://whitecoregroup.com/img/logo.png"} width={130} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pb-1">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null} */}

                      <div className="mb-3">
                        <Label className="form-label required">Name</Label>
                        <Input
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Enter Name"
                          type="name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="mb-3">
                            <Label className="form-label required">
                              Shop Name
                            </Label>
                            <Input
                              name="shopname"
                              type="text"
                              placeholder="Shop Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.shopname || ""}
                              invalid={
                                validation.touched.shopname &&
                                  validation.errors.shopname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.shopname &&
                              validation.errors.shopname ? (
                              <FormFeedback type="invalid">
                                {validation.errors.shopname}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label className="form-label required">
                              Contact Number
                            </Label>
                            <Input
                              name="contactno"
                              type="text"
                              placeholder="Contact number"
                              maxLength="10"
                              onChange={validation.handleChange}
                              onKeyPress={handleKeyPress}
                              onBlur={validation.handleBlur}
                              value={validation.values.contactno || ""}
                              invalid={
                                validation.touched.contactno &&
                                  validation.errors.contactno
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contactno &&
                              validation.errors.contactno ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contactno}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <Label className="form-label required">Address</Label>
                        <Input
                          name="address"
                          type="textarea"
                          Row={2}
                          placeholder="Enter Address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address &&
                              validation.errors.address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address &&
                          validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <Input
                          name="description"
                          type="textarea"
                          Row={2}
                          placeholder="Enter Description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                              validation.errors.description
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description &&
                          validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-2 text-sm-end">
                        {isLoading ? (
                          <button type="button" className="btn " style={{
                            backgroundColor: "#394867 ",
                            color: "#F1F6F9 ",
                            border: "none "
                          }}>
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary"
                            type="submit"
                            style={{
                              backgroundColor: "#394867 ",
                              color: "#F1F6F9 ",
                              border: "none "
                            }}

                          >
                            Submit
                          </Button>
                        )}
                      </div>

                      <div className="mt-2 text-center" style={{ color: "#394867" }}>
                        <p>
                          Already have an account ?
                          <Link
                            to="/login"
                            className="font-weight-medium "

                          >
                            Login
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default Register

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Input, FormGroup } from "reactstrap"

import FeedbackTable from "./FeedbackTable"
import useApi from "common/ApiMiddlewere"
import { dateFormat } from "pages/Common"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"

function Feedback() {
  const { activeInavtiveApi, postApi } = useApi()
  document.title = `Feedback | ${localStorage.getItem("ShopName")}`

  // useStates
  let { pathname } = useLocation()
  const [tableData, setTableData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [Loding, setLoding] = useState(false)

  const pagePerSize = pageSize => {
    setRowsPerPage(pageSize)
  }

  useEffect(() => {
    setLoding(true)
    postApi("Feedback/feedback-list", {
      displayStart: 0,
      pageSize: rowsPerPage,
      searchText: "",
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setLoding(false)
        setTableData(data.FeedbackList)
      } else {
        setLoding(false)
        seterror(data)
      }
    })
  }, [rowsPerPage])

  const handleSwitch = id => {
    activeInavtiveApi(`Feedback/Reviewe?id=${id}`)
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "User Full Name",
        accessor: "FullName",
      },
      {
        Header: "Description",
        accessor: "Description",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Description}`}
          >
            {row.original.Description.length > 50
              ? `${row.original.Description.substr(0, 50)}...`
              : row.original.Description}
          </p>
        ),
      },
      {
        Header: "Date",
        accessor: "Date",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.Date == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.Date.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Is Reviewed",
        width: "8%",
        accessor: "IsReviewed",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            {row.original.IsReviewed ? (
              "Review"
            ) : (
              <Input
                type="switch"
                disabled={row.original.IsReviewed}
                defaultChecked={row.original.IsReviewed}
                onClick={e => {
                  handleSwitch(row.original.Id)
                }}
              />
            )}
          </FormGroup>
        ),
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <FeedbackTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isColorList={true}
              isLoading={Loding}
              customPageSize={10}
              className="custom-header-css"
              pagePerSize={pagePerSize}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

Feedback.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Feedback

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup } from "reactstrap"

const PlanTable = ({
    columns,
    data,
    customPageSize,
    className,
    isSizeList,
    isTimeslotList,
    handleTimeslot,
    handleSizeClick,
    isLoading,
    isPlanList,
    handlePlanClick,
    RoleName,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 2000,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )

    const handlesort = column => {
        (column)
    }

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }


    return (
        <Fragment>
            <div className="row">
                <div className="col-md-9 mb-sm-2">
                    <div className="row">
                    </div>
                </div>
                <div className="col-md-3">
                    {isSizeList && (
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="primary"
                                className="btn  mb-2 "
                                onClick={handleSizeClick}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create Size
                            </Button>
                        </div>
                    )}
                    {isPlanList && (
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="primary"
                                className="btn  mb-2 "
                                onClick={handlePlanClick}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create Plan
                            </Button>
                        </div>
                    )}
                    {isTimeslotList && (
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="primary"
                                className="btn  mb-2 "
                                onClick={handleTimeslot}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create TimeSlot
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => {
                                    return (
                                        <th
                                            key={column.id}
                                            onClick={() => {
                                                handlesort(column)
                                            }}
                                            className={` ${column.Header == 'Allow Vendor' && RoleName.RoleName == "Vendor" ? 'column-display' : ""} `}
                                            {...column.getHeaderProps({
                                                style: { minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width },
                                            })}
                                        >
                                            <div className={`mb-0`}>
                                                {column.render("Header")}
                                                {generateSortingIndicator(column)}
                                            </div>
                                        </th>
                                    )
                                }


                                )
                                }
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className={`text-nowrap ${(cell.column.Header == 'Allow Vendor' && RoleName.RoleName == "Vendor") ? 'column-display' : ""} `}
                                                        {...cell.getCellProps({ style: { minWidth: cell.minWidth, maxWidth: cell.maxWidth, width: cell.width }, })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? "Loading..." : "No Record Found"}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </Fragment>
    )
}

PlanTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default PlanTable

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import DeleteModel from "common/deleteModel/deleteModel"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip,
} from "reactstrap"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../common/Breadcrumbs"
import { routes } from "routes/path"
import TutorialTable from "./TutorialTable"
import YouTube from 'react-youtube';

function TutorialListForVendor() {

    const { postApi } = useApi()
    let { pathname } = useLocation()

    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getTutorialData = () => {
        setIsLoading(true)
        postApi("Tutorials/list", '').then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    useEffect(() => {
        getTutorialData()
    }, [])



    const getYouTubeVideoId = (url) => {
        const urlParams = new URLSearchParams(new URL(url).search);
        return urlParams.get("v");
    };

    const handleUrlClick = (url) => {
        window.open(url, '_blank');
    };

    document.title = `Tutorials | ${localStorage.getItem("ShopName")}`

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                {tableData.length ?
                    <div>
                        <Row>
                            {tableData?.map((data, key) => (
                                <Col xl={3} sm={6} key={"_col_" + key}>
                                    <Card>
                                        <CardBody>
                                            <div className="product-img position-relative"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <a
                                                    href={data.Url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src={`https://img.youtube.com/vi/${getYouTubeVideoId(data.Url)}/0.jpg`}
                                                        alt="YouTube Thumbnail"
                                                        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                                                    />
                                                    <div className="play-button-overlay">
                                                        <i className="bx bxl-youtube" style={{ fontSize: "54px", color: "#FF0000" }}></i>
                                                    </div>
                                                    <div className="play-button-overlay1">
                                                        <i className="fas fa-play-circle" style={{ fontSize: "24px", color: "#FF0000" }}></i>
                                                    </div>
                                                    <div className="play-button-overlay2">
                                                        <i className="fas fa-play" style={{ fontSize: "12px", color: "white" }}>
                                                        </i>
                                                    </div>
                                                    <div className="text-nowrap" style={{ color: "black", fontSize: "12px", marginTop: "5px", width: '100%' }}>
                                                        <div style={{ fontWeight: "500" }}>{data?.Title}</div>
                                                        <div style={{ fontSize: "10px" }}>
                                                            <span
                                                                className=" mb-0"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title={`${data?.Discription}`}
                                                            >
                                                                {data?.Discription.length > 39
                                                                    ? `${data?.Discription.substr(0, 39)}...`
                                                                    : data?.Discription == ""
                                                                        ? ""
                                                                        : data?.Discription}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    :
                    <Row>
                        <Col md="12">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {
                                    "No Record Found"
                                }
                            </div>

                        </Col>
                    </Row>
                }
            </div>
        </div>
    )
}
TutorialListForVendor.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default TutorialListForVendor

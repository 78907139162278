export const InvoiceTable = `    <table style="
font-size: 8px;
width: 295px !important;
height: 425px !important;
border-collapse: collapse !important;
border: 0.1px solid black !important;
font-family: 'Times New Roman', Times, serif !important;
color: black !important;
line-height: normal !important;
">
      <tr style="
  height: 52px !important;
  border-bottom: 0.1px solid black !important;
">
          <td colspan="6" align="center" style="vertical-align: top !important">
              <div style="font-size: 11px !important">{COMPANY_NAME}</div>
              <div style="font-size: 8px !important; margin-top: 3px !important">
                  {COMPANY_ADDRESS}
              </div>
              <div style="font-size: 8px !important; margin-top: 3px !important">
                  Mo: {COMPANY_MOBILENUMER}
              </div>
          </td>
      </tr>
      <tr style="
  height: 15px !important;
  background-color: #dfdfdf !important;
  font-weight: bold !important;
  border-bottom: 0.1px solid black !important;
  vertical-align: top !important;
" align="center">
          <td colspan="4" style="border-right: none !important">
              Customer Details
          </td>
          <td colspan="2" align="right" style="border-left: none !important">
              Bill No.: {BILL_NO}
          </td>
      </tr>
      <tr style="
  height: 15px !important;
  vertical-align: top !important;
  border-bottom: 0.1px solid black !important;
">
          <td colspan="4" style="border-right: none !important">
              <div style="
      font-weight: bold !important;
      float: left !important;
      margin-right: 3px !important;
    ">
                  Name:
              </div>
              {CUSTOMER_NAME}
          </td>
          <td colspan="2" style="padding-left: 15px !important; border-left: none !important" align="right">
              <div style="font-weight: bold !important">Date: {BOOKING_DATE}</div>
          </td>
      </tr>
      <tr style="
  height: 30px !important;
  vertical-align: top !important;
  border-bottom: 0.1px solid black !important;
">
          <td colspan="6">
              <div style="
      font-weight: bold !important;
      float: left !important;
      margin-right: 3px !important;
    ">
                  Address:
              </div>
              {CUSTOMER_ADDRESS}
          </td>
      </tr>
      <tr style="
  height: 15px !important;
  vertical-align: top !important;
  border-bottom: 0.1px solid black !important;
">
          <td colspan="6">
              <div style="
      font-weight: bold !important;
      float: left !important;
      margin-right: 3px !important;
    ">
                  Mo:
              </div>
              {CUSTOMER_MOBILENUM1}|| {CUSTOMER_MOBILENUM2}
          </td>
      </tr>
      <tr style="
  height: 15px !important;
  background-color: #dfdfdf !important;
  font-weight: bold !important;
  border-bottom: 0.1px solid black !important;
">
          <td style="
    width: 12px !important;
    border-right: 0.1px solid black !important;
  " align="center">
              No
          </td>
          <td style="
    width: 52px !important;
    border-right: 0.1px solid black !important;
  "
  colspan="2"
  >
              Description
          </td>
          <!-- <td style="border-right: 0.1px solid black !important">Name</td> -->
          <td style="
    width: 40px !important;
    border-right: 0.1px solid black !important;
  ">
              Delivery
          </td>
          <td style="
    width: 40px !important;
    border-right: 0.1px solid black !important;
  ">
              Return
          </td>
          <td style="width: 50px !important;text-align: end;">Rent</td>
      </tr>

      {BOOKINT_ITEMS}

      <tr style="
  height: 15px !important;
  border-top: 0.1px solid black !important;
">
          <td rowspan="5" colspan="3" style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
    vertical-align: top !important;
  ">
              {REMARKS}
          </td>
          <td style="
    font-weight: bold !important;
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  " colspan="2">
              Total Rent
          </td>
          <td style="
    font-weight: bold !important;
    border-bottom: 0.1px solid black !important;
  text-align: end;">
              <span style="font-family: NotoSansGujarati-Regular !important;">₹</span> {TOTALRENT}
          </td>
      </tr>
      <tr style="height: 15px !important; font-weight: bold !important">
          <td colspan="2" style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  ">
              Discount
          </td>
          <td style="border-bottom: 0.1px solid black !important; text-align: end;">
              <span style="font-family: NotoSansGujarati-Regular !important; ">₹</span> {DISCOUNT}
          </td>
      </tr>
      <tr style="height: 15px !important; font-weight: bold !important">
          <td colspan="2" style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  ">
              Advance
          </td>
          <td style="border-bottom: 0.1px solid black !important; text-align: end;">
              <span style="font-family: NotoSansGujarati-Regular !important; ">₹</span> {ADVANCE}
          </td>
      </tr>
      <tr style="height: 15px !important; font-weight: bold !important">
          <td colspan="2" style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  ">
              Payble Amount
          </td>
          <td style="border-bottom: 0.1px solid black !important; text-align: end;">
              <span style="font-family: NotoSansGujarati-Regular !important;">₹</span> {PAYBLE_AMOUNT}
          </td>
      </tr>
      <tr style="height: 15px !important; font-weight: bold !important">
          <td colspan="2" style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  ">
              Deposit
          </td>
          <td style="border-bottom: 0.1px solid black !important ; text-align: end;">
          <span style="font-family: NotoSansGujarati-Regular !important;">₹</span> {Deposit}
              
          </td>
      </tr>
      <tr>
          <td colspan="6" style="vertical-align: top !important">
              <div>
                 {NOTE_1}
              </div>
             
          </td>
      </tr>
  </table>`
// export const InvoiceTable = ` `
export const BookingItemHtml = `<tr style="height: 20px !important">
<td style="border-right: 0.1px solid black !important" align="center">
    {RE_NO}
</td>
<td style="border-right: 0.1px solid black !important" colspan="2">{PRODUCT_CODE} {PRODUCT_NAME}<br/><div style="font-size: 6px;">{DyanamicData}</div></td>
<!-- <td style="border-right: 0.1px solid black !important">{PRODUCT_NAME}</td> -->
<td style="border-right: 0.1px solid black !important">{DELIVERY} <br> <span style="font-size: 6px;font-weight: 100; font-family: NotoSansGujarati-Regular !important;">{DeliveryTimeSlot}</span> </td>
<td style="border-right: 0.1px solid black !important">{RETURN} <br> <span style="font-size: 6px;font-weight: 100; font-family: NotoSansGujarati-Regular !important;">{ReturnTimeSlot}</span> </td>
<td style="float: right;">
    <div style="float: left; font-family: NotoSansGujarati-Regular !important;">{RUPPES} </div> {RENT}
</td>
</tr>`
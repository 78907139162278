import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Form,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import { GetObjectsByAccountGroups } from "_mock/Static_data"
import { dateFormat, getCurrentFinancialYear } from "pages/Common"

let isSelectedFinancialYear = false
function WashingModel({ modal, toggle, IncomeDetails, washingListApi }) {
  const { postApi, putApi, getApi } = useApi()
  const [isLoading, setisLoading] = useState(false)
  const [incomeAccounts, setIncomeAccounts] = useState([])
  const startDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[0]
  const endDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[1]
  const endDateYear = endDate.split('-')[2] + "-" + endDate.split('-')[1] + '-' + endDate.split('-')[0]
  const [paymentMethod, setpaymentMethod] = useState("1")

  useEffect(() => {
    const SelectedFinancialYear = startDate?.split("-")[2] + "-" + (endDate?.split("-")[2])
    if (SelectedFinancialYear == getCurrentFinancialYear()) {
      isSelectedFinancialYear = true
      validation.setFieldValue("Date", new Date())
    } else {
      isSelectedFinancialYear = false
      validation.setFieldValue("Date", endDateYear)
    }
  }, [localStorage.getItem("FinancialYearDate")])

  useEffect(() => {
    getApi(`Accounts/accounts-lookup?accountGroup=${GetObjectsByAccountGroups(6)[0].Value}`).then(({ data, status }) => {
      if (status === 200) {
        setIncomeAccounts(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [])

  useEffect(() => {
    validation.handleReset()
  }, [modal])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      fromDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
      productCode: IncomeDetails ? IncomeDetails.productCode : "",
      Amount: IncomeDetails ? IncomeDetails.Amount : "",
      remarks: IncomeDetails ? IncomeDetails.remarks : "",
      paymentMode: 1,
    },
    validationSchema: Yup.object({
      productCode: Yup.string().required("The product code is required"),
    }),
    onSubmit: values => {
      setisLoading(true)
      values.Amount = parseFloat(values.Amount || 0)
      getApi(`Product/product-by-code?code=${values.productCode}`).then(({ data, status }) => {
        if (status === 200) {
          values.ProductId = data?.Id
          postApi('Washing/create', values).then(({ data, status }) => {
            setisLoading(false)
            if (status === 200) {
              setisLoading(false)
              toggle()
              washingListApi()
              validation.handleReset()
              toast.success("Add wash product successfully", {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              setisLoading(false)
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
          })
        } else {
          setisLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  // allow only number key
  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle} tag="h4">
            {IncomeDetails ? "Update Wash Product" : "Add Wash Product"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation.handleReset()
                return false
              }}
            >
              <Row>
                <Col md={8}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="formrow-InputState" className="required">
                      Product Code
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      id="formrow-InputState"
                      className="form-control"
                      name="productCode"
                      placeholder="Code"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.productCode || ""}
                      invalid={
                        validation.touched.productCode && validation.errors.productCode
                          ? true
                          : false
                      }
                    />

                    {validation.touched.productCode && validation.errors.productCode ? (
                      <FormFeedback type="invalid">
                        {validation.errors.productCode}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="formrow-InputState" className="">
                      Amount
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      id="formrow-InputState"
                      classAmount="form-control"
                      name="Amount"
                      placeholder="0.00"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      onKeyPress={handleKeyPress}
                      value={validation.values.Amount || ""}
                      invalid={
                        validation.touched.Amount && validation.errors.Amount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.Amount && validation.errors.Amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Amount}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validation1Custom01">From Date</Label>
                    <Input
                      name="fromDate"
                      placeholder="date placeholder"
                      type="date"
                      min={dateFormat(startDate, "yyyy-dd-MM")}
                      max={endDateYear}
                      dateFormat="DD/M/YYYY"
                      className="form-control-sm"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.fromDate}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validation1Custom01">To Date</Label>
                    <Input
                      name="toDate"
                      placeholder="date placeholder"
                      type="date"
                      min={dateFormat(startDate, "yyyy-dd-MM")}
                      max={endDateYear}
                      dateFormat="DD/M/YYYY"
                      className="form-control-sm"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.toDate}
                    />
                  </FormGroup></Col>
              </Row>

              <FormGroup className="mb-2">
                <Label htmlFor="validationCustom03" className="">
                  Remarks
                </Label>
                <Input
                  name="remarks"
                  placeholder="type your remark here"
                  type="textarea"
                  rows={3}
                  className="form-control-sm"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.remarks || ""}
                  invalid={
                    validation.touched.remarks && validation.errors.remarks
                      ? true
                      : false
                  }
                />
                {validation.touched.remarks && validation.errors.remarks ? (
                  <FormFeedback type="invalid">
                    {validation.errors.remarks}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    {isLoading ? (
                      <button type="button" className="btn btn-primary btn-sm">
                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                        Loading
                      </button>
                    ) : (
                      <Button
                        color="primary"
                        className="btn btn-primary btn-sm "
                        type="submit"
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>


            </Form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default WashingModel

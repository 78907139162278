import { saveAs } from 'file-saver';
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export function exportDataExcel(sheetData, mainTitle, widthObj, extention, totalAmount, TotalDiscount, TotalAdvance, TotalDeposit, TotalCGSTAmt, TotalSGSTAmt) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });

        sheet1.cell("A1").value(mainTitle);
        const titleRange = sheet1.range(`A1:${endColumn}1`);
        titleRange.merged(true);
        titleRange.value(mainTitle);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });
        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        if (totalAmount != "" && totalAmount > 0) {
            const totalAmountRow = sheetData.length + 2;
            sheet1.cell(`D${totalAmountRow}`).value("Total").style({
                bold: true,
            });
            sheet1.cell(`E${totalAmountRow}`).value(TotalDiscount).style({
                bold: true,
            });
            sheet1.cell(`F${totalAmountRow}`).value(TotalAdvance).style({
                bold: true,
            });
            sheet1.cell(`G${totalAmountRow}`).value(TotalDeposit).style({
                bold: true,
            });
            sheet1.cell(`H${totalAmountRow}`).value(Math.round(TotalCGSTAmt)).style({
                bold: true,
            });
            sheet1.cell(`I${totalAmountRow}`).value(Math.round(TotalSGSTAmt)).style({
                bold: true,
            });
            sheet1.cell(`J${totalAmountRow}`).value(totalAmount).style({
                bold: true,
            });
        }

        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}

export function IncomeExpenseExportDataExcel(sheetData, mainTitle1, mainTitle2, widthObj, extention, totalIncome, totalExpense) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });
        sheet1.range("F1:F2").style({
            bold: false,
            border: true,
            fill: {
                type: "pattern",
                patternType: "solid",
                fgColor: "ffffff",
            }
        });

        sheet1.cell("A1").value(mainTitle1);
        const titleRange = sheet1.range(`A1:E1`);
        titleRange.merged(true);
        titleRange.value(mainTitle1);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 14,
            fill: "E1DFDF"
        });
        sheet1.cell("A1").value(mainTitle2);
        const titleRange1 = sheet1.range(`G1:K1`);
        titleRange1.merged(true);
        titleRange1.value(mainTitle2);
        titleRange1.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 14,
            fill: "E1DFDF"
        });

        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        const TotalIncome = sheetData.length + 2;
        sheet1.cell(`D${TotalIncome}`).value("Total").style({
            bold: true,
        });
        sheet1.cell(`E${TotalIncome}`).value(totalIncome).style({
            bold: true,
            horizontalAlignment: "right"
        });
        const TotalExpenses = sheetData.length + 2;
        sheet1.cell(`J${TotalExpenses}`).value("Total").style({
            bold: true,
        });
        sheet1.cell(`K${TotalExpenses}`).value(totalExpense).style({
            bold: true,
            horizontalAlignment: "right"
        });


        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}

export function exportDataExcelSales(sheetData, mainTitle, widthObj, extention, TotalBillAmount, TotalCGSTAmt, TotalSGSTAmt, TotalIGSTAmt, TotalDiscount) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });

        sheet1.cell("A1").value(mainTitle);
        const titleRange = sheet1.range(`A1:${endColumn}1`);
        titleRange.merged(true);
        titleRange.value(mainTitle);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });
        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        if (TotalBillAmount != "" && TotalBillAmount > 0) {
            const totalAmountRow = sheetData.length + 2;
            sheet1.cell(`E${totalAmountRow}`).value("Total").style({
                bold: true,
            });
            sheet1.cell(`F${totalAmountRow}`).value(TotalDiscount).style({
                bold: true,
            });
            sheet1.cell(`G${totalAmountRow}`).value(Math.round(TotalCGSTAmt)).style({
                bold: true,
            });
            sheet1.cell(`H${totalAmountRow}`).value(Math.round(TotalSGSTAmt)).style({
                bold: true,
            });
            sheet1.cell(`I${totalAmountRow}`).value(Math.round(TotalIGSTAmt)).style({
                bold: true,
            });
            sheet1.cell(`J${totalAmountRow}`).value(TotalBillAmount).style({
                bold: true,
            });
        }

        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}


export function exportDataExcelStock(sheetData, mainTitle, widthObj, extention) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });

        sheet1.cell("A1").value(mainTitle);
        const titleRange = sheet1.range(`A1:${endColumn}1`);
        titleRange.merged(true);
        titleRange.value(mainTitle);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });
        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}

export function exportDataExcelDepoosit(sheetData, mainTitle, widthObj, extention, TotalReceive, TotalReturn) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });

        sheet1.cell("A1").value(mainTitle);
        const titleRange = sheet1.range(`A1:${endColumn}1`);
        titleRange.merged(true);
        titleRange.value(mainTitle);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });
        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })

        const totalAmountRow = sheetData.length + 2;
        sheet1.cell(`E${totalAmountRow}`).value("Total").style({
            bold: true,
        });
        sheet1.cell(`F${totalAmountRow}`).value(TotalReceive).style({
            bold: true,
        });
        sheet1.cell(`G${totalAmountRow}`).value(Math.round(TotalReturn)).style({
            bold: true,
        });

        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import ReminderTable from "./ReminderTable"
import { dateFormat } from "pages/Common"

function Reminder() {
  document.title = `Reminders | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()
  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState("")
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [screenLoad, setScreenLoad] = useState(false)
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [totalRecords, setTotalRecords] = useState(0)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [pageSize, setpageSize] = useState()
  const [displayStart, setDisplayStart] = useState(1)
  const [searchText, setSearchText] = useState("")

  const filterData = textFilter => {
  }
  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setpageSize(pageSize)
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
  }
  // reminder list api
  const reminderListApi = () => {
    setScreenLoad(true)
    setIsLoading(true)
    postApi("Reminder/list", {
      displayStart: displayStart,
      pageSize: pageSize,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.ReminderList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setScreenLoad(false)
    })
  }

  // reminder list api useEffect
  useEffect(() => {
    reminderListApi()
  }, [pageSize, displayStart, searchText])

  // toggle handlers
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  // cancle handler
  const handleCancle = () => {
    toggle()
  }

  // Delete reminder handler
  const handleDelete = (reminderId) => {
    setScreenLoad(true)
    if (reminderId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this reminder permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          deleteApi(`Reminder/delete?Id=${reminderId}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== reminderId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("successfully deleted", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Reminder",
                  text: data,
                })
              }
              setScreenLoad(false)
            }
          )
        } else {
          setScreenLoad(false)
        }
      })
    }
  }

  // Update reminder handler
  const handleEditReminder = reminderData => {
    if (reminderData) {
      setIsEdit(true)
      setModal(!modal)
      setEditData(reminderData)
    }
  }

  // Create reminder handler
  const handleReminderClick = () => {
    setIsEdit(false)
    toggle()
  }

  // Reminder Validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      description: isEdit ? editData?.Description : "",
      assignee: isEdit ? editData?.Assignee : "",
      reminderDate: isEdit ? dateFormat(
        editData?.ReminderDate.split("T")[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
    },
    validationSchema: Yup.object({
      description: Yup.string().required("The description is required"),
      assignee: Yup.string().required("The assignee is required"),
      reminderDate: Yup.string().required("The reminder date is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)

      if (values) {
        isEdit
          ? putApi("Reminder/update", {
            id: editData?.Id,
            reminderDate: values.reminderDate,
            assignee: values.assignee,
            description: values.description,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("successfully updated", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              reminderListApi()
              validation.handleReset()
            }
          })
          : postApi("Reminder/create", {
            reminderDate: values.reminderDate,
            assignee: values.assignee,
            description: values.description,
          }).then(({ data, status }) => {
            setformSumitLoading(false)

            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("successfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              reminderListApi()
              validation.handleReset()
            }
          })
      }
    },
  })

  // reminder table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Assignee",
        accessor: "Assignee",
        width: "12%",
      },
      {
        Header: "Date",
        accessor: "ReminderDate",
        width: "10%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReminderDate == "0001-01-01T00:00:00"
              ? "N/A"
              : row.original.ReminderDate == ""
                ? "N/A" : dateFormat(
                  row.original.ReminderDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
          </p>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "10%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEditReminder(row.original)}
                ></i>
                <span className="mytext">Edit</span>
              </li>
              <li className="mytooltip">
                <i
                  id={`deletetooltip${row.original.Id}`}
                  className="fs-6 fas fa-trash-alt btn-delete"
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        {screenLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <ReminderTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isCreateReminder={true}
              handleUserClick={handleReminderClick}
              customPageSize={20}
              className="custom-header-css"
              filterData={filterData}
              pagePerSize={pagePerSize}
              isLoading={isLoading}
              TotalRecords={totalRecords}
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "350px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update Reminder" : "Create Reminder"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Description
                      </Label>
                      <Input
                        name="description"
                        placeholder="Reminder Description"
                        type="textarea"
                        rows="2"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description && validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Assignee
                      </Label>
                      <Input
                        name="assignee"
                        placeholder="Assignee"
                        type="text"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.assignee || ""}
                        invalid={
                          validation.touched.assignee && validation.errors.assignee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.assignee && validation.errors.assignee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.assignee}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Date
                      </Label>
                      <Input
                        name="reminderDate"
                        placeholder="date placeholder"
                        type="date"
                        // min={new Date().toISOString().slice(0, 10)}
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.reminderDate || new Date().toISOString().slice(0, 10)}
                        invalid={
                          validation.touched.reminderDate && validation.errors.reminderDate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.reminderDate && validation.errors.reminderDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.reminderDate}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>




                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm"
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}

                      <>
                        {formSumitLoading ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm "
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Reminder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Reminder

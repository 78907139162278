import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import ItemsTable from "./ItemsTable"
import { dateFormat } from "pages/Common"
import CreateItemModel from "pages/CreateItemModal"
import { ItemsBarcodePDF } from "./ItemsBarcodePDF"
import jsPDF from "jspdf"
import { UserFont } from "pages/Master/User/UserFont"
import Barcode from "react-barcode"
import html2canvas from 'html2canvas';
import BarcodeGenerator from "./BarCodeComponent"
import ItemsBarcodeCountTable from "./ItemsBarcodeCountTable"

function Items() {
    document.title = `Items | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const barcodeRef = useRef([]);
    const { deleteApi, getApi, postApi, putApi } = useApi()
    // useStates
    const [modal, setModal] = useState(false)
    const [modalBarcode, setModalBarcode] = useState(false)
    const [tableData, setTableData] = useState([])
    const [itemEditData, setItemEditData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [screenLoad, setScreenLoad] = useState(false)
    const [barcodeScreenLoad, setBarcodeScreenLoad] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [searchText, setSearchText] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [itemId, setItemId] = useState(0)
    const [isInactive, setIsInactive] = useState(false)
    const [checkedItems, setCheckedItems] = useState([]);
    const [formSumitLoading, setformSumitLoading] = useState(false)

    const filterData = textFilter => {
        setSearchText(textFilter == undefined ? '' : textFilter)
    }

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    // Create Account handler
    const handleItemClick = () => {
        toggle()
        setIsEdit(false)
    }

    // Account list api
    const ItemsApi = () => {
        setScreenLoad(true)
        setIsLoading(true)
        postApi("Items/list", {
            active: isInactive == true ? false : true,
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.List)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
    }

    // Account list api useEffect
    useEffect(() => {
        ItemsApi()
    }, [rowsPerPage, searchText, displayStart, isInactive])

    // toggle handlers
    const toggle = () => {
        setModal(!modal)
        validation.handleReset()
    }

    // toggle handlers
    const toggleBarcode = () => {
        setModalBarcode(!modalBarcode)
    }

    // Delete Account handler
    const handleDelete = ItemId => {
        setScreenLoad(true)
        if (ItemId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this Item permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Items/delete?id=${ItemId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== ItemId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("successfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Category",
                                    text: data,
                                })
                            }
                            setScreenLoad(false)
                        }
                    )
                } else {
                    setScreenLoad(false)
                }
            })
        }
    }

    const handleEdit = ItemId => {
        setScreenLoad(true)
        setItemId(ItemId)
        getApi(`Items/details?id=${ItemId}`).then(({ data, status }) => {
            if (status === 200) {
                setItemEditData(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
        setIsEdit(true)
        setModal(!modal)
    }

    // Items Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: isEdit ? itemEditData.Name : "",
            ItemCode: isEdit ? itemEditData.ItemCode : "",
            MRP: isEdit ? itemEditData.MRP : 0,
            CGST: isEdit ? itemEditData.CGST : 0,
            SGST: isEdit ? itemEditData.SGST : 0,
            OpeningStock: isEdit ? itemEditData.OpeningStock : 0,
            IGST: isEdit ? itemEditData.IGST : 0,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("The name is required"),
            ItemCode: Yup.string().required("The item code is required"),
        }),
        onSubmit: values => {

            if (values.CGST && !values.SGST) {
                return false
            }

            if (values.SGST && !values.CGST) {
                return false
            }

            if (values) {
                setScreenLoad(true)
                isEdit
                    ? putApi("Items/update", {
                        id: itemId,
                        name: values.Name,
                        mrp: parseFloat(values.MRP) || 0,
                        itemCode: values.ItemCode || "",
                        cgst: parseFloat(values.CGST) || 0,
                        sgst: parseFloat(values.SGST) || 0,
                        igst: parseFloat(values.IGST) || 0,
                    }).then(({ data, status }) => {
                        if (status == 400) {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        } else {
                            toast.success("successfully updated", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            ItemsApi()
                            validation.handleReset()
                        }
                        setScreenLoad(false)
                    })
                    :

                    postApi("Items/create", {
                        name: values.Name,
                        mrp: parseFloat(values.MRP) || 0,
                        openingStock: parseFloat(values.OpeningStock) || 0,
                        itemCode: values.ItemCode || "",
                        cgst: parseFloat(values.CGST) || 0,
                        sgst: parseFloat(values.SGST) || 0,
                        igst: parseFloat(values.IGST) || 0,
                    }
                    ).then(({ data, status }) => {
                        if (status == 200) {
                            toast.success("successfully created", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            ItemsApi()
                            validation.handleReset()
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                        setScreenLoad(false)
                    })
            }
        },
    })

    // product active handler
    const handleSwitch = (id, Active, e) => {
        if (id) {
            putApi("Items/active", { id: String(id) }).then(({ data, status }) => {
                e.preventDefault()
                if (status === 200) {
                    toast.success("Item sucessfully changed", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                    ItemsApi()
                } else {
                    $("#IsActive_" + id + "").prop("checked", Active)
                    ErrorModel.errormodel({
                        title: "Verify Item",
                        text: data,
                    })
                }
            })
        }
    }

    // cancle handler
    const handleCancle = () => {
        toggleBarcode()
    }

    const handleCheckboxChange = async (id, barcodeNo, checked, index) => {
        if (checked) {
            setCheckedItems(prevItems => [
                ...prevItems,
                { id, barcodeNo, barcodeCount: 1 }
            ]);
        } else {
            setCheckedItems(checkedItems.filter(item => item.id !== id));
        }
    };

    // Generate Barcode handler
    const handleGenerateBarcode = () => {
        setBarcodeScreenLoad(true)
        if (checkedItems.length == 0) {
            setBarcodeScreenLoad(false)
            toast.error("Please select at least one item to generate barcodes.", {
                icon: "⚠️",
                style: {
                    fontSize: "15px",
                },
            })
        } else {
            setBarcodeScreenLoad(false)
            // captureBarcodes()
            toggleBarcode()
        }
    }

    const handleDownloadBarcodePdf = async () => {
        const html = await ItemsBarcodePDF(checkedItems);
        const doc = new jsPDF({
            format: "a4",
            unit: "px",
            filters: ["ASCIIHexEncode"],
            compress: true,
        })
        // var imgData = "data:image/jpeg;base64," + imagebase64
        doc.addFileToVFS("NotoSansGujarati-Regular.ttf", UserFont)
        doc.addFont(
            "NotoSansGujarati-Regular.ttf",
            "NotoSansGujarati-Regular",
            "normal"
        )
        doc.setFont("NotoSansGujarati-Regular") // set font
        doc.html(html, {
            async callback(doc) {
                setIsLoading(false)
                await doc.save(`invoice_Barcode.pdf`)
            },
            margin: [10, 10, 10, 10],
        })
        toggleBarcode()
    }

    // const captureBarcode = (index, barcodeNo) => {
    //     const element = barcodeRef.current[index];
    //     if (element) {
    //         html2canvas(element).then((canvas) => {
    //             const barcodeBase64 = canvas.toDataURL('image/png');
    //             setBarcodeScreenLoad(false)
    //             setCheckedItems((prevItems) =>
    //                 prevItems.map((item) =>
    //                     item.barcodeNo === barcodeNo ? { ...item, barcodrUrl: barcodeBase64 } : item
    //                 )
    //             );
    //         });
    //     }
    // };

    // Items table header & accessor
    const columns = useMemo(
        () => [
            // {
            //     Header: "#",
            //     width: "3%",
            //     Cell: (row, index) => {
            //         const { Id, BarcodeNo } = row.row.original;
            //         const isChecked = checkedItems.some(item => item.id === Id);
            //         return (
            //             <>
            //                 <div key={index}>
            //                     <input
            //                         type="checkbox"
            //                         className="form-check-input"
            //                         checked={isChecked}
            //                         onChange={e => handleCheckboxChange(Id, BarcodeNo, e.target.checked, index)}
            //                     />

            //                 </div>

            //                 {/* <div ref={barcodeRef} style={{ display: "block" }}>
            //                     <Barcode value={BarcodeNo} />
            //                 </div> */}
            //             </>
            //         );
            //     },
            // },
            {
                Header: "Item Code",
                accessor: "ItemCode",
                width: "7%",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Barcode No.",
                accessor: "BarcodeNo",
                width: "9%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`Opening Stock`}
                    >
                        Op. Stock
                    </div>
                ),
                accessor: "OpeningStock",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original?.OpeningStock}
                        </div>
                    )
                },
                width: "7%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        MRP
                    </div>
                ),
                accessor: "MRP",
                width: "7%",
                textAlign: "right",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.MRP || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        CGST
                    </div>
                ),
                accessor: "CGST",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <span style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.CGST}%
                        </span>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        SGST
                    </div>
                ),
                accessor: "SGST",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <span style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.SGST}%
                        </span>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        IGST
                    </div>
                ),
                accessor: "IGST",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <span style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.IGST}%
                        </span>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Is Active
                    </div>
                ),
                width: "7%",
                accessor: "IsActive",

                Cell: ({ row }) => (
                    <FormGroup switch className="d-flex justify-content-center">
                        <Input
                            type="switch"
                            id={`IsActive_${row.original.Id}`}
                            defaultChecked={row.original.Active}
                            onClick={e => {
                                handleSwitch(row.original.Id, row.original.Active, e)
                            }}
                        />
                    </FormGroup>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit "
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleEdit(row.original.Id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    id={`deletetooltip${row.original.Id}`}
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, checkedItems]
    )

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    const handleBarcodeCountChange = (id, newCount) => {
        newCount = newCount || 1
        setCheckedItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, barcodeCount: newCount } : item
            )
        );
    };

    const columns1 = useMemo(
        () => [
            {
                Header: "Barcode",
                accessor: "barcodeNo",
                width: "7%",
            },
            {
                Header: "No. of Count",
                accessor: "barcodeCount",
                width: "7%",
                Cell: (row, index) => {
                    const { id, barcodeCount } = row.row.original;
                    return (
                        <>
                            <Input
                                name="barcodeCount"
                                placeholder="0"
                                type="text"
                                className="form-control-sm"
                                onChange={e => {
                                    handleBarcodeCountChange(id, e.target.value)
                                }}
                                onKeyPress={handleKeyPress}
                                defaultValue={barcodeCount}
                            />
                        </>
                    );
                },
            },
            {
                Header: "Barcode",
                Cell: (row, index) => {
                    const { barcodeNo } = row.row.original;
                    useEffect(() => {
                        captureBarcode(index, barcodeNo);
                    }, []);
                    return (
                        <>
                            <div ref={el => (barcodeRef.current[index] = el)} className="custom-barcode">
                                <Barcode value={barcodeNo} displayValue={true} marginTop={0} marginBottom={0} marginLeft={0} marginRight={0} />
                            </div>
                        </>
                    );
                },
            },
        ],
        []
    )

    useEffect(() => {
        if (modalBarcode && checkedItems.length > 0) {
            setTimeout(() => {
                captureBarcodes();
            }, 100);
        }
    }, [modalBarcode]);

    const elRefs = React.useRef([]);

    // Refs initialization logic
    useEffect(() => {
        elRefs.current = checkedItems.map((_, i) => elRefs.current[i] || React.createRef());
    }, [checkedItems]);

    const captureBarcodes = () => {
        setTimeout(() => {
            checkedItems.forEach((item, index) => {
                const element = elRefs.current[index]?.current;
                if (element) {
                    html2canvas(element).then((canvas) => {
                        const barcodeBase64 = canvas.toDataURL('image/png');
                        setCheckedItems((prevItems) =>
                            prevItems.map((prevItem) =>
                                prevItem.barcodeNo === item.barcodeNo
                                    ? { ...prevItem, barcodeUrl: barcodeBase64 }
                                    : prevItem
                            )
                        );
                    });
                } else {
                }
            });
        }, 500);
    };


    return (
        <div className="page-content">
            <div className="container-fluid">
                {screenLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <ItemsTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isAccountList={true}
                            handleItemClick={handleItemClick}
                            handleGenerateBarcode={handleGenerateBarcode}
                            barcodeScreenLoad={barcodeScreenLoad}
                            customPageSize={20}
                            className="custom-header-css"
                            filterData={filterData}
                            isLoading={isLoading}
                            TotalRecords={totalRecords}
                            pagePerSize={pagePerSize}
                            isInactive={isInactive}
                            setIsInactive={setIsInactive}
                        />

                        <CreateItemModel
                            modal={modal}
                            toggle={toggle}
                            isEdit={isEdit}
                            ItemsApi={ItemsApi}
                            flag={"item"}
                            itemEditData={itemEditData}
                            setIsEdit={setIsEdit}
                            itemId={itemId}
                        />

                        <Modal
                            isOpen={modalBarcode}
                            toggle={toggleBarcode}
                            centered={true}
                            size="xl"
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggleBarcode} tag="h5">
                                    {"Generate Barcodes"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            return false
                                        }}
                                    >
                                        <div>
                                            <>
                                                <Row>
                                                    {checkedItems.map((el, i) => (
                                                        <Col md={3} key={i} >
                                                            <div style={{ fontSize: "15px" }}>
                                                                {el.Name}
                                                            </div>
                                                            <div>
                                                                <Barcode
                                                                    ref={elRefs.current[i]}
                                                                    value={el.barcodeNo}
                                                                    displayValue={true}
                                                                    marginTop={0}
                                                                    marginBottom={0}
                                                                    marginLeft={0}
                                                                    marginRight={0}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </>
                                        </div>

                                        <div className="text-end mt-3">
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2 btn-sm"
                                                onClick={handleCancle}
                                            >
                                                Cancle
                                            </Button>
                                            <>
                                                {formSumitLoading ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm "
                                                        type="button"
                                                        onClick={() => handleDownloadBarcodePdf()}
                                                    >
                                                        Print Barcode
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        </Modal>


                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Items.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Items

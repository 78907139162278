import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import DeleteModel from "common/deleteModel/deleteModel"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import {
    Card,
    CardBody,
} from "reactstrap"
import toast from "react-hot-toast"
import { dateFormat, getFinancialYear } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import ExpensesModel from "./ExpensesModel"
import ExpensesTable from "./ExpensesTable"

function Expenses() {
    const { deleteApi, postApi } = useApi()
    let { pathname } = useLocation()
    const startDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[0]
    const endDate = localStorage.getItem("FinancialYearDate")?.split(" to ")[1]
    const [modal, setModal] = useState(false)
    const [tableData, setTableData] = useState([])
    const [ExpensesDetails, setExpensesDetails] = useState({
        Id: "",
        Date: "",
        Amount: 0,
        Name: "",
        PaymentMode: 1,
        Details: ""
    })
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [searchText, setSearchText] = useState("")
    const [TotalRecords, setTotalRecords] = useState(0.0)
    const [displayStart, setDisplayStart] = useState(1)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)

    // pageSize function
    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    const handleFollowUp = Details => {
        const SelectedFinancialYear = startDate?.split("-")[2] + "-" + (endDate?.split("-")[2])
        if (SelectedFinancialYear == getFinancialYear(Details?.Date)) {
            setExpensesDetails(Details)
            toggle()
        } else {
            toast.error("You cannot update the date due to a financial year mismatch.", {
                style: {
                    fontSize: "15px",
                },
            })
        }
    }

    const toggle = () => {
        setModal(!modal)
    }

    const getTableData = () => {
        setIsLoading(true)
        postApi("expenses/list", {
            status: 0,
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.List)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    useEffect(() => {
        getTableData()
    }, [rowsPerPage, searchText, displayStart])

    const confirmDelete = id => {
        DeleteModel.confirmDeleteInquiry({
            title: "Are you sure? ",
            text: "You want to delete this expense permanently? ",
            isMobile,
        })
            .then(response => {
                setIsLoading(true)
                if (response == true) {
                    deleteApi(`Expenses/delete?id=${id}`).then(({ data, status }) => {
                        if (status === 200) {
                            const updatedArray = tableData
                                .map(obj => (obj.Id !== id ? obj : null))
                                .filter(Boolean)
                            setTableData(updatedArray)
                            toast.success("Sucessfully deleted", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            setIsLoading(false)
                        } else {
                            setIsLoading(false)
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                    })
                } else {
                    setIsLoading(false)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const columns = useMemo(
        () => [

            {
                Header: "Date",
                accessor: "Date",
                width: "7%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.Date == null
                            ? "N/A"
                            : dateFormat(row.original.Date.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
            {
                Header: "Account",
                accessor: "DrAccountName",
                width: "15%",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Amount
                    </div>
                ),
                accessor: "Amount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Amount || 0)}
                        </div>
                    )
                },

            },
            {
                Header: "Payment",
                accessor: "PaymentMode",
                width: "7%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.PaymentMode == "1"
                            ? "Cash"
                            : row.original.PaymentMode == "2"
                                ? "Bank" : ''}
                    </p>
                ),
            },
            {
                Header: "Details",
                accessor: "Details",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0"
                        // style={{width:"120px"}}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Details == null ? '' : row.original.Details}`}
                    >
                        {row.original.Details.length > 40
                            ? `${row.original.Details.substr(0, 40)}...`
                            : row.original.Details == ""
                                ? "N/A"
                                : row.original.Details}
                    </p>
                ),
            },
            {
                Header: "Create On",
                accessor: "CreatedOn",
                width: "7%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.Date == null
                            ? "N/A"
                            : dateFormat(row.original.Date.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">

                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit"
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleFollowUp(row.original)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>

                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.Id}`}
                                    onClick={() => confirmDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>

                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    const handleAddExpenses = () => {
        setExpensesDetails()
        toggle();
    }

    document.title = `Expenses | ${localStorage.getItem("ShopName")}`

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <ExpensesTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            handleUserClick={handleAddExpenses}
                            customPageSize={10}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                        />
                    </CardBody>
                </Card>
                <ExpensesModel
                    modal={modal}
                    toggle={toggle}
                    ExpensesDetails={ExpensesDetails}
                    getTableData={getTableData}
                />
            </div>
        </div>
    )
}
Expenses.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Expenses

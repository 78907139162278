export const ItemsBarcodeTable = `
<style type="text/css">
    table { 
        page-break-inside: auto; 
        width: 100%;
        margin-top: 10px;
    }
    tr { 
        page-break-inside: avoid; 
        page-break-after: auto; 
    }
</style>
<div style="font-size: 8px; width: 427px !important; height: 607px !important; border: 0.1px solid black !important; font-family: 'Times New Roman', Times, serif !important; color: black !important; padding:10px; margin-top: 3px;">
    <div class="row">
        <table border="0" style="page-break-inside:auto;">
        {BARCODES}
        </table>
    </div>
</div>
`;

export const BarcodesHtml = `
<td style="text-align: center; padding-bottom: 10px !important;">
    <img src="{BARCODE_BASE64}" style="height: 35px; width: 65px;" alt="{BARCODE_VALUE}" />
</td>
`;
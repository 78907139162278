/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    Container,
    FormGroup,
    FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import { GoogleApiWrapper } from "google-maps-react"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useLocation } from "react-router-dom"
import toast from "react-hot-toast"
import Compressor from "compressorjs"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"


function GroomWellProfile(props) {
    const { postApi, getApi } = useApi()
    document.title = `GroomWell Profile | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()

    // useStates
    const [groomWellUserData, setGroomWellUserData] = useState(null)
    const [imageFlag, setImageFlag] = useState(false)
    const [previewUrl, setPreviewUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [gender, setGender] = useState(true);

    const groomWellUserDetail = () => {
        setIsLoading(true)
        getApi("GroomWell/user-details").then(({ data, status }) => {
            if (status === 200) {
                setGender(data?.Gender)
                setPreviewUrl(data?.Url)
                setGroomWellUserData(data)
                if (data?.Id != 0) {
                    validation.setFieldValue("username", data?.Username)
                }

            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        groomWellUserDetail()
    }, [])




    // user details validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            fullname: groomWellUserData?.FullName || "",
            shopname: groomWellUserData?.ShopName || "",
            contactNo: groomWellUserData?.ContactNo || "",
            username: groomWellUserData?.Id != 0 ? groomWellUserData?.Username || "" : "",
            password: groomWellUserData?.Id != 0 ? "53456486486" : "",
            email: groomWellUserData?.Email || "",
            latitude: groomWellUserData?.Latitude || "",
            longitude: groomWellUserData?.Longitude || "",
            address: groomWellUserData?.Address || "",
            profilePic: groomWellUserData?.Url || "",
        },
        validationSchema: Yup.object().shape({
            fullname: Yup.string().required("The full name is required"),
            shopname: Yup.string().required("The shop name is required"),
            contactNo: Yup.string()
                .required("The contact number is required")
                .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number"),
            username: Yup.string().required("The username is required"),
            password: Yup.string().required("The password is required"),
            latitude: Yup.string().required("The latitude is required"),
            longitude: Yup.string().required("The longitude is required"),
            address: Yup.string().required("The address is required"),
        }),
        onSubmit: values => {
            setIsLoading(true)
            const body = {
                userId: groomWellUserData?.Id,
                username: groomWellUserData?.Id == 0 ? values.username : '',
                password: groomWellUserData?.Id == 0 ? values.password : '',
                fullName: values.fullname,
                contactNo: values.contactNo,
                shopName: values.shopname,
                address: values.address,
                email: values.email,
                latitude: values.latitude,
                longitude: values.longitude,
                gender: gender,
                avatar: imageFlag ? values.profilePic.split(",")[1] : "",
            }
            postApi(groomWellUserData?.Id == 0 ? "GroomWell/create-user" : "GroomWell/update-user", body).then(({ data, status }) => {
                if (status === 200) {
                    setIsLoading(false)
                    setImageFlag(false)
                    groomWellUserDetail()
                    toast.success("User Update Successfully", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                    // navigate(routes.dashboard)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
                setIsLoading(false)
            })
        },
    })

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    // profile pic toBase64 convert handle
    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })

    // profile pic select handle & compressor
    const handleDrop = async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        setImageFlag(true)
        new Compressor(selectedFile, {
            quality: 0.4,
            success: async compressedResult => {
                setPreviewUrl(URL.createObjectURL(compressedResult))
                validation.setFieldValue("profilePic", await toBase64(compressedResult))
            },
        })
    }

    // profile pic display & set
    const handleclick = () => {
        validation.setFieldValue("profilePic", null)
        setPreviewUrl(null)
    }

    // google map select address get lat-lng
    useEffect(() => {
        const mapOptions = {
            center: new google.maps.LatLng(21.2310051, 72.901111),
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        const map = new google.maps.Map(document.getElementById('lat-lng-Map'), mapOptions);
        google.maps.event.addListener(map, 'click', function (e) {
            validation.setFieldValue("latitude", String(e.latLng.lat()).substring(0, 10))
            validation.setFieldValue("longitude", String(e.latLng.lng()).substring(0, 10))
        });
    }, [window.onload, window.pathname])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={breadcrumbs[pathname].title}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                    />
                    {isLoading && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        <div className="row">
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="d-flex flex-column align-items-center text-center" >Profile Pic</Label>
                                                    <Dropzone
                                                        onDrop={handleDrop}
                                                        accept="image/*"
                                                        multiple={false}
                                                        id="profilePic"
                                                        name="profilePic"
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <>
                                                                <div className="d-flex flex-column align-items-center text-center p-2 py-0">
                                                                    {previewUrl ? (
                                                                        <>
                                                                            <input {...getInputProps()} />
                                                                            <img
                                                                                className="rounded-circle pe-auto"
                                                                                style={{ cursor: "pointer" }}
                                                                                width="150px"
                                                                                height="150px"
                                                                                src={previewUrl}
                                                                                {...getRootProps()}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <input {...getInputProps()} />
                                                                            <img
                                                                                className="rounded-circle"
                                                                                style={{ cursor: "pointer" }}
                                                                                width="150px"
                                                                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                                                                {...getRootProps()}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/* {previewUrl && (
                                                                    <div className="d-flex justify-content-center align-item-center ">
                                                                        <button
                                                                            onClick={() => handleclick()}
                                                                            type="button"
                                                                            className="btn btn-sm btn-soft-danger"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    </div>
                                                                )} */}
                                                            </>
                                                        )}
                                                    </Dropzone>
                                                    {validation.touched.profilePic &&
                                                        validation.errors.profilePic ? (
                                                        <div className="error">
                                                            {validation.errors.profilePic}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                                <div className="mb-2 "
                                                    style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}
                                                >
                                                    <div className="mb-2 " style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}>
                                                        <div className=" d-flex gap-2" style={{ marginTop: "2px" }}>
                                                            <input
                                                                type="radio"
                                                                value={true}
                                                                name="gender"
                                                                id="customRadioInline1"
                                                                className="form-check-input"
                                                                checked={gender === true}
                                                                onChange={(e) => setGender(e.target.value === 'true')}
                                                            />
                                                            <label className="form-check-label font-size-13" htmlFor="customRadioInline1">
                                                                Male
                                                            </label>

                                                            <input
                                                                type="radio"
                                                                value={false}
                                                                name="gender"
                                                                id="customRadioInline2"
                                                                className="form-check-input"
                                                                checked={gender === false}
                                                                onChange={(e) => setGender(e.target.value === 'true')}
                                                            />
                                                            <label className="form-check-label font-size-13" htmlFor="customRadioInline2">
                                                                Female
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <Col md={groomWellUserData?.Id == 0 ? 3 : 4}>
                                                        <FormGroup>
                                                            <Label for="fullname" className="required">
                                                                Full Name
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control-sm"
                                                                name="fullname"
                                                                id="fullname"
                                                                placeholder="fullName"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.fullname}
                                                                invalid={
                                                                    validation.touched.fullname &&
                                                                        validation.errors.fullname
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.fullname &&
                                                                validation.errors.fullname ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.fullname}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={groomWellUserData?.Id == 0 ? 3 : 4}>
                                                        <FormGroup>
                                                            <Label for="shopname" className="required">
                                                                Shop Name
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control-sm"
                                                                name="shopname"
                                                                id="shopname"
                                                                placeholder="shopname"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.shopname}
                                                                invalid={
                                                                    validation.touched.shopname &&
                                                                        validation.errors.shopname
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.shopname &&
                                                                validation.errors.shopname ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.shopname}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={groomWellUserData?.Id == 0 ? 2 : 3}>
                                                        <FormGroup>
                                                            <Label for="contactNo" className="required">
                                                                Contact No.
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control-sm"
                                                                name="contactNo"
                                                                maxLength="10"
                                                                id="contactNo"
                                                                onKeyPress={handleKeyPress}
                                                                placeholder="contact number"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.contactNo}
                                                                invalid={
                                                                    validation.touched.contactNo &&
                                                                        validation.errors.contactNo
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.contactNo &&
                                                                validation.errors.contactNo ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.contactNo}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    {groomWellUserData?.Id == 0 &&
                                                        <>
                                                            <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="username" className="required">
                                                                        Username
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        disabled={groomWellUserData?.Id}
                                                                        className="form-control-sm"
                                                                        name="username"
                                                                        id="username"
                                                                        placeholder="username"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.username}
                                                                        invalid={
                                                                            validation.touched.username &&
                                                                                validation.errors.username
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.username &&
                                                                        validation.errors.username ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.username}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="username" className="required">
                                                                        Password
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        disabled={groomWellUserData?.Id}
                                                                        className="form-control-sm"
                                                                        name="password"
                                                                        maxLength={20}
                                                                        minLength={5}
                                                                        id="password"
                                                                        placeholder="password"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.password}
                                                                        invalid={
                                                                            validation.touched.password &&
                                                                                validation.errors.password
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.password &&
                                                                        validation.errors.password ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.password}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                    }
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="email">Email</Label>
                                                            <Input
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                className="form-control-sm"
                                                                placeholder="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <div className="col-md-8">
                                                        <FormGroup>
                                                            <Label for="address" className="required">
                                                                Address
                                                            </Label>
                                                            <Input
                                                                type="textarea"
                                                                className="form-control-sm"
                                                                name="address"
                                                                id="address"
                                                                row={2}
                                                                placeholder="address..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.address}
                                                                invalid={
                                                                    validation.touched.address &&
                                                                        validation.errors.address
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.address &&
                                                                validation.errors.address ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.address}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                    <Row>
                                                        <Col className="col-12" sm={12} md={12} lg={12} xl={8}>
                                                            <div id="lat-lng-Map" style={{ height: "200px", width: "550px" }} />
                                                        </Col>
                                                        <Col className="col-12" sm={12} md={12} lg={12} xl={4}>
                                                            <Row>
                                                                <Col sm={12} md={10}>
                                                                    <FormGroup>
                                                                        <Label className="required" for="latitude">Latitude</Label>
                                                                        <Input
                                                                            type="latitude"
                                                                            disabled
                                                                            name="latitude"
                                                                            id="latitude"
                                                                            className="form-control-sm"
                                                                            placeholder="latitude"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation.values.latitude}
                                                                            invalid={
                                                                                validation.touched.latitude &&
                                                                                    validation.errors.latitude
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                        {validation.touched.latitude &&
                                                                            validation.errors.latitude ? (
                                                                            <FormFeedback type="invalid">
                                                                                {validation.errors.latitude}
                                                                            </FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12} md={10}>
                                                                    <FormGroup>
                                                                        <Label className="required" for="longitude">Longitude</Label>
                                                                        <Input
                                                                            type="longitude"
                                                                            disabled
                                                                            name="longitude"
                                                                            id="longitude"
                                                                            className="form-control-sm"
                                                                            placeholder="longitude"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation.values.longitude}
                                                                            invalid={
                                                                                validation.touched.longitude &&
                                                                                    validation.errors.longitude
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                        {validation.touched.longitude &&
                                                                            validation.errors.longitude ? (
                                                                            <FormFeedback type="invalid">
                                                                                {validation.errors.longitude}
                                                                            </FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md="12" className="text-end ">
                                                {isLoading ? (
                                                    <button type="button" className="btn btn-primary">
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

// export default GroomWellProfile
export default GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    v: "3",
})(GroomWellProfile)
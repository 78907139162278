/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import Compressor from "compressorjs"
import {
    Row,
    Col,
    Form,
    Label,
    Input,
    Button,
    FormGroup,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Dropzone from "react-dropzone"
import uploadImage from "../../../assets/images/uploadImage.png"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ImageCrop from "pages/Inventory/Products/ImageCrop"
import ImagesCrops from "./ImagesCrops"

let gender = 1
let imagePreviews = []
let images = ''
function UpdateGroomWellProduct({ toggle, modal, editProductId, isEdit, setIsEdit, productData, listApi }) {
    document.title = `Update GroomWell Product | ${localStorage.getItem("ShopName")}`
    const { getApi, postApi } = useApi()
    gender = productData?.TargetGender || 1
    const [previewUrl, setPreviewUrl] = useState(uploadImage)
    const [mainImageUrl, setMainImageUrl] = useState(uploadImage)
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [targetGender, setTargetGender] = useState(productData?.TargetGender || 1)
    const [previewUrl1, setPreviewUrl1] = useState(uploadImage)
    const [previewUrl2, setPreviewUrl2] = useState(uploadImage)
    const [previewUrl3, setPreviewUrl3] = useState(uploadImage)
    const [previewUrl4, setPreviewUrl4] = useState(uploadImage)
    const [cropImage1, setCropImage1] = useState(uploadImage)
    const [cropImage2, setCropImage2] = useState(uploadImage)
    const [cropImage3, setCropImage3] = useState(uploadImage)
    const [cropImage4, setCropImage4] = useState(uploadImage)
    const [colors, setColots] = useState([])
    const [editProductData, setEditProductData] = useState()
    const [isUrl, setIsUrl] = useState(false)
    const [cropImage, setCropImage] = useState('')
    const [modal1, setModal1] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [imagesOndrops, setImagesOndrops] = useState(0)
    const [imagesPreviews1, setImagesPreviews] = useState([])

    useEffect(() => {
        getApi("GroomWell/color-lookup").then(({ status, data }) => {
            if (status === 200) {
                data.unshift({
                    Name: "Select color",
                    Id: 0
                });
                setColots(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })

    }, [])

    const toggle1 = () => {
        setModal1(!modal1)
    }

    const toggle2 = () => {
        setModal2(!modal2)
    }

    useEffect(() => {
        setIsUrl(false)
        validation.handleReset()
        if (editProductId && isEdit) {
            // toggle()
            getApi(`GroomWell/product-details?productId=${editProductId}`).then(
                ({ data, status }) => {
                    if (status === 200) {
                        images = data?.ImageUrls
                        setPreviewUrl1(data?.ImageUrls?.split(",")[0] || uploadImage)
                        setPreviewUrl2(data?.ImageUrls?.split(",")[1] || uploadImage)
                        setPreviewUrl3(data?.ImageUrls?.split(",")[2] || uploadImage)
                        setPreviewUrl4(data?.ImageUrls?.split(",")[3] || uploadImage)
                        setCropImage1(data?.ImageUrls?.split(",")[0] || uploadImage)
                        setCropImage2(data?.ImageUrls?.split(",")[1] || uploadImage)
                        setCropImage3(data?.ImageUrls?.split(",")[2] || uploadImage)
                        setCropImage4(data?.ImageUrls?.split(",")[3] || uploadImage)
                        setPreviewUrl(data?.MainImageUrl)
                        setCropImage(data?.MainImageUrl)
                        setMainImageUrl(data?.MainImageUrl)
                        setEditProductData(data)

                        data?.ImageUrls.split(",").map((item, index) => {
                            const updatedPreviews = [...imagePreviews];
                            updatedPreviews[index] = {
                                isUrl: false,
                                url: item,
                                base64: "",
                            };
                            imagePreviews = updatedPreviews;
                        })
                        data?.ImageUrls.split(",").map((item, index) => {
                            imagesPreviews1.push(
                                {
                                    isUrl: false,
                                    url: item,
                                    base64: "",
                                })
                            // const updatedPreviews1 = [...imagesPreviews1];
                            // setImagesPreviews(updatedPreviews1);
                        })
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                }
            )
        } else {
            setPreviewUrl1(uploadImage)
            setPreviewUrl2(uploadImage)
            setPreviewUrl3(uploadImage)
            setPreviewUrl4(uploadImage)
            setCropImage1(uploadImage)
            setCropImage2(uploadImage)
            setCropImage3(uploadImage)
            setCropImage4(uploadImage)
            setPreviewUrl(productData?.ImageUrl)
            setCropImage(uploadImage)
            setMainImageUrl(productData?.ImageUrl)
            let obj = {
                Title: productData.Name,
                ColorId: 0,
                Description: '',
                Rent: productData.Rent,
                MainImageUrl: productData.ImageUrl,
                BrandName: localStorage.getItem('ShopName'),
                Discount: 0,
                Deposit: 0,
            }
            setEditProductData(obj)
        }

        gender = (productData?.TargetGender)
        setTargetGender(productData?.TargetGender)

    }, [editProductId, modal])

    // product validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            color: editProductData?.ColorId || 0,
            title: editProductData?.Title || '',
            description: editProductData?.Description || '',
            rent: editProductData?.Rent == 0 ? "" : editProductData?.Rent || '',
            profilePic: isEdit ? editProductData?.MainImageUrl : "",
            brandName: editProductData?.BrandName || "",
            discount: editProductData?.Discount || "",
            deposit: editProductData?.Deposit || "",
        },
        validationSchema: Yup.object({
            color: Yup.number().min(1, "The color is required"),
            title: Yup.string().required("The title is required"),
            description: Yup.string().required("The description is required"),
            rent: Yup.string().required("The rent is required"),
            profilePic: Yup.string().required("The main Image is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                if (!isEdit && productData?.GWCategoryId == null || productData?.GWCategoryId == 0) {
                    toast.error("Please mapped all categories", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                    setformSumitLoading(false)
                } else {
                    const updateBody = {
                        id: editProductId,
                        title: values.title,
                        brandName: values.brandName,
                        colorId: parseInt(values.color),
                        rent: parseInt(values.rent || 0),
                        deposit: parseInt(values.deposit || 0),
                        discount: parseInt(values.discount || 0),
                        targetGender: parseInt(targetGender),
                        description: values.description,
                        images: imagesPreviews1,
                        mainImageUrl: mainImageUrl,
                        mainImage: isUrl ? cropImage.split(",")[1] : "",
                    }
                    const cretaeBody = {
                        rentopusProductCode: productData?.Code,
                        categoryId: productData?.GWCategoryId,
                        title: values.title,
                        brandName: values.brandName,
                        colorId: parseInt(values.color),
                        rent: parseInt(values.rent || 0),
                        deposit: parseInt(values.deposit || 0),
                        discount: parseInt(values.discount || 0),
                        targetGender: parseInt(targetGender),
                        description: values.description,
                        images: imagesPreviews1,
                        mainImageUrl: mainImageUrl,
                        mainImage: isUrl ? cropImage.split(",")[1] : "",
                    }
                    const URL = isEdit ? "GroomWell/update-product" : "GroomWell/create-product";
                    const Payload = isEdit ? updateBody : cretaeBody;
                    postApi(URL, Payload).then(({ data, status }) => {
                        if (status === 200) {
                            if (!isEdit) {
                                postApi("GroomWell/set-product-as-groomwell", {
                                    productId: productData?.Id,
                                    inGroomWell: true
                                }).then(({ data, status }) => {
                                })
                                toast.success(`${isEdit ? "Product Update Successfully" : "Product sucessfully exported"}`, {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })

                                setformSumitLoading(false)
                                setImagesPreviews([])
                                listApi()
                                toggle()
                            } else {
                                setIsEdit(false)
                                toast.success(`${isEdit ? "Product Update Successfully" : "Product sucessfully exported"}`, {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })

                                listApi()
                                setImagesPreviews([])
                                toggle()
                                setformSumitLoading(false)
                            }
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                        setformSumitLoading(false)
                    })
                }
            }
        }
    })

    // product image convert toBase64 string
    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })

    // image select handler
    const handleDrop = acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            success: async compressedResult => {
                setIsUrl(true);
                setPreviewUrl(URL.createObjectURL(compressedResult))
                toggle1()
            },
        })
    }

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    // image 2 onDrop function
    const handleDrop1 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.1,
            success: async compressedResult => {
                setImagesOndrops(0)
                setPreviewUrl1(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    const handleRemoveImage = (index) => {
        index == 0 ?
            setPreviewUrl1(uploadImage) : index == 1 ? setPreviewUrl2(uploadImage) : index == 2 ? setPreviewUrl3(uploadImage) : setPreviewUrl4(uploadImage)
        index == 0 ?
            setCropImage1(uploadImage) : index == 1 ? setCropImage2(uploadImage) : index == 2 ? setCropImage3(uploadImage) : setCropImage4(uploadImage)
        const updatedPreviews = [...imagePreviews];
        updatedPreviews[index] = {
            isUrl: true,
            url: '',
            base64: '',
        };
        imagePreviews = updatedPreviews;
    }

    // image 3 onDrop function
    const handleDrop2 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.1,
            success: async compressedResult => {
                setImagesOndrops(1)
                setPreviewUrl2(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    // image 4 onDrop function
    const handleDrop3 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.1,
            success: async compressedResult => {
                setImagesOndrops(2)
                setPreviewUrl3(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    // image 5 onDrop function
    const handleDrop4 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.1,
            success: async compressedResult => {
                setImagesOndrops(3)
                setPreviewUrl4(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    const handleCancel = () => {
        toggle()
    }

    const handleGenderChange = (e) => {
        setTargetGender(parseInt(e))
        gender = (parseInt(e))
    };

    const handleImageCrop = () => {
        toggle1()
    }

    return (
        <React.Fragment>

            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="xl"
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        {isEdit ? "Update Product" : "Export Product"}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl="12">
                                <Form
                                    className="needs-validation"
                                    onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                    }}
                                    onReset={e => {
                                        e.preventDefault()
                                        validation.handleReset()
                                        return false
                                    }}
                                >
                                    <Row>
                                        <Col md="3">
                                            <FormGroup>
                                                <Dropzone
                                                    onDrop={handleDrop}
                                                    onClick={handleDrop}
                                                    accept="image/*"
                                                    multiple={false}
                                                    id="profilePic"
                                                    name="profilePic"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <>
                                                            <div className="d-flex flex-column align-items-center text-center p-2">
                                                                <Label
                                                                    htmlFor="validationCustom03"
                                                                    className="required"
                                                                >
                                                                    Main Image
                                                                </Label>
                                                                <>
                                                                    <div className="position-relative overflow-hidden product-image" style={{
                                                                        width: "180px",
                                                                        height: "215px",
                                                                        border: "1px solid #ced4da",
                                                                        cursor: "pointer",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderRadius: "3px",
                                                                    }}>
                                                                        <input {...getInputProps()} />
                                                                        <img
                                                                            className="img-fluid"
                                                                            style={{ height: "214px", width: "179px" }}
                                                                            src={cropImage ? cropImage : uploadImage}
                                                                            {...getRootProps()}
                                                                        />
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </>
                                                    )}
                                                </Dropzone>
                                                <span style={{ justifyContent: 'center', display: "flex", alignItems: "center", textAlign: "center" }}>
                                                    {validation.touched.profilePic && validation.errors.profilePic ? (
                                                        <FormFeedback type="invalid" className="d-block">{validation.errors.profilePic}</FormFeedback>
                                                    ) : null}
                                                </span>
                                            </FormGroup>


                                            <div className="mb-2" style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}>
                                                <div className="d-flex gap-3" style={{ marginTop: "0px" }}>
                                                    <div className="d-flex gap-1">
                                                        <input
                                                            type="radio"
                                                            value={1}
                                                            name="gender"
                                                            id="customRadioInline1"
                                                            className="form-check-input"
                                                            defaultChecked={gender == 1}
                                                            onChange={() => handleGenderChange(1)}
                                                        />
                                                        <Label
                                                            className="form-check-label font-size-13"
                                                            style={{ marginTop: "1px" }}
                                                            htmlFor="customRadioInline1"
                                                        >
                                                            Male
                                                        </Label>
                                                    </div>
                                                    <div className="d-flex gap-1">
                                                        <input
                                                            type="radio"
                                                            value={2}
                                                            name="gender"
                                                            id="customRadioInline2"
                                                            className="form-check-input"
                                                            defaultChecked={gender == 2}
                                                            onChange={() => handleGenderChange(2)}
                                                        />
                                                        <Label
                                                            className="form-check-label font-size-13"
                                                            style={{ marginTop: "1px" }}
                                                            htmlFor="customRadioInline2"
                                                        >
                                                            Female
                                                        </Label>
                                                    </div>
                                                    <div className="d-flex gap-1">
                                                        <input
                                                            type="radio"
                                                            value={3}
                                                            name="gender"
                                                            id="customRadioInline3"
                                                            className="form-check-input"
                                                            defaultChecked={gender == 3}
                                                            onChange={() => handleGenderChange(3)}
                                                        />
                                                        <Label
                                                            className="form-check-label font-size-13"
                                                            style={{ marginTop: "1px" }}
                                                            htmlFor="customRadioInline3"
                                                        >
                                                            Unisex
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col md="9">
                                            <Row>
                                                <Col md="5">
                                                    <FormGroup className="mb-2">
                                                        <Label
                                                            htmlFor="validationCustom03"
                                                            className="required"
                                                        >
                                                            Title
                                                        </Label>
                                                        <Input
                                                            name="title"
                                                            placeholder="Title"
                                                            type="text"
                                                            className="form-control-sm"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.title || ""}
                                                            invalid={
                                                                validation.touched.title &&
                                                                    validation.errors.title
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.title &&
                                                            validation.errors.title ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.title}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-2">
                                                        <Label
                                                            htmlFor="validationCustom03"
                                                            className=""
                                                        >
                                                            Brand Name
                                                        </Label>
                                                        <Input
                                                            name="brandName"
                                                            placeholder="brand name"
                                                            type="text"
                                                            className="form-control-sm"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.brandName || ""}
                                                            invalid={
                                                                validation.touched.brandName &&
                                                                    validation.errors.brandName
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.brandName &&
                                                            validation.errors.brandName ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.brandName}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup className="mb-2">
                                                        <Label className="required" htmlFor="validationCustom01">Color</Label>
                                                        <Input
                                                            type="select"
                                                            bsSize="sm"
                                                            id="validationCustom01"
                                                            className="form-control-sm"
                                                            name="color"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.color}
                                                            invalid={
                                                                validation.touched.color &&
                                                                    validation.errors.color
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            {colors.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.Id}>
                                                                        {item.Name}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Input>
                                                        {validation.touched.color &&
                                                            validation.errors.color ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.color}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-2">
                                                        <Label className="form-label required">
                                                            Rent
                                                        </Label>
                                                        <Input
                                                            name="rent"
                                                            className="form-control-sm"
                                                            placeholder="0"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            onKeyPress={handleKeyPress}
                                                            value={validation.values.rent}
                                                            invalid={
                                                                validation.touched.rent &&
                                                                    validation.errors.rent
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.rent &&
                                                            validation.errors.rent ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.rent}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-2">
                                                        <Label className="form-label">
                                                            Discount
                                                        </Label>
                                                        <Input
                                                            name="discount"
                                                            className="form-control-sm"
                                                            placeholder="0"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            onKeyPress={handleKeyPress}
                                                            value={validation.values.discount}
                                                            invalid={
                                                                validation.touched.discount &&
                                                                    validation.errors.discount
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.discount &&
                                                            validation.errors.discount ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.discount}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-2">
                                                        <Label className="form-label">
                                                            Desposit
                                                        </Label>
                                                        <Input
                                                            name="deposit"
                                                            className="form-control-sm"
                                                            placeholder="0"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            onKeyPress={handleKeyPress}
                                                            value={validation.values.deposit}
                                                            invalid={
                                                                validation.touched.deposit &&
                                                                    validation.errors.deposit
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.deposit &&
                                                            validation.errors.deposit ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.deposit}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-2">
                                                        <Label className="required" >Description</Label>
                                                        <Input
                                                            name="description"
                                                            rows="4"
                                                            placeholder="Type your remark here"
                                                            type="textarea"
                                                            className="form-control-sm"
                                                            value={validation.values.description || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.description &&
                                                                    validation.errors.description
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                        </Input>
                                                        {validation.touched.description &&
                                                            validation.errors.description ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.description}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Label
                                                    htmlFor=""
                                                    className=""
                                                >
                                                    Images
                                                </Label>
                                                <div>
                                                    <div className="d-flex">
                                                        <FormGroup>
                                                            <Dropzone
                                                                onDrop={handleDrop1()}
                                                                onClick={handleDrop1()}
                                                                accept="image/*"
                                                                multiple={false}
                                                                id="profilePic1"
                                                                name="profilePic1"
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <>
                                                                        <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                            <input {...getInputProps()} />
                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    border: "2px solid #0000004d",
                                                                                    height: "123px",
                                                                                    width: "110px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "2%",
                                                                                }}
                                                                            >
                                                                                <>
                                                                                    <img
                                                                                        style={{
                                                                                            cursor: "pointer", height: "122px",
                                                                                            width: "110px",
                                                                                        }}
                                                                                        src={cropImage1 || previewUrl1 || uploadImage}
                                                                                        {...getRootProps()}
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Dropzone>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Dropzone
                                                                onDrop={handleDrop2()}
                                                                onClick={handleDrop2()}
                                                                accept="image/*"
                                                                multiple={false}
                                                                id="profilePic2"
                                                                name="profilePic2"
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <>
                                                                        <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                            <input {...getInputProps()} />
                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    border: "2px solid #0000004d",
                                                                                    height: "123px",
                                                                                    width: "110px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "2%",
                                                                                }}
                                                                            >
                                                                                <>
                                                                                    <img
                                                                                        style={{
                                                                                            cursor: "pointer", height: "122px",
                                                                                            width: "110px",
                                                                                        }}
                                                                                        src={cropImage2 || previewUrl2 || uploadImage}
                                                                                        {...getRootProps()}
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Dropzone>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Dropzone
                                                                onDrop={handleDrop3()}
                                                                onClick={handleDrop3()}
                                                                accept="image/*"
                                                                multiple={false}
                                                                id="profilePic3"
                                                                name="profilePic3"
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <>
                                                                        <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                            <input {...getInputProps()} />
                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    border: "2px solid #0000004d",
                                                                                    height: "123px",
                                                                                    width: "110px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "2%",
                                                                                }}
                                                                            >
                                                                                <>
                                                                                    <img
                                                                                        style={{
                                                                                            cursor: "pointer", height: "122px",
                                                                                            width: "110px",
                                                                                        }}
                                                                                        src={cropImage3 || previewUrl3 || uploadImage}
                                                                                        {...getRootProps()}
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Dropzone>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Dropzone
                                                                onDrop={handleDrop4()}
                                                                onClick={handleDrop4()}
                                                                accept="image/*"
                                                                multiple={false}
                                                                id="profilePic4"
                                                                name="profilePic4"
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <>
                                                                        <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                            <input {...getInputProps()} />
                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    border: "2px solid #0000004d",
                                                                                    height: "123px",
                                                                                    width: "110px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "2%",
                                                                                }}
                                                                            >
                                                                                <>
                                                                                    <img
                                                                                        style={{
                                                                                            cursor: "pointer", height: "122px",
                                                                                            width: "110px",
                                                                                        }}
                                                                                        src={cropImage4 || previewUrl4 || uploadImage}
                                                                                        {...getRootProps()}
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Dropzone>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="text-end">
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2"
                                                type="reset"
                                                onClick={() => handleCancel()}
                                            >
                                                Cancel
                                            </Button>

                                            <>
                                                {formSumitLoading ? (
                                                    <button type="button" className="btn btn-primary ">
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary "
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ModalBody>
                </div>
            </Modal>

            <Modal
                isOpen={modal1}
                toggle={toggle1}
                centered={true}
                size="lg"
                style={{ width: "580px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle1} tag="h5">
                        {"Crop Image"}
                    </ModalHeader>
                    <ModalBody style={{ height: "490px" }}>
                        <ImageCrop
                            image={previewUrl}
                            setCropImage={setCropImage}
                            toggle={toggle1}
                            validation={validation}
                        />
                    </ModalBody>
                </div>
            </Modal>

            <Modal
                isOpen={modal2}
                toggle={toggle2}
                centered={true}
                size="lg"
                style={{ width: "580px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle2} tag="h5">
                        {"Crop Image"}
                    </ModalHeader>
                    <ModalBody style={{ height: "490px" }}>
                        <ImagesCrops
                            image={imagesOndrops == 0 ? previewUrl1 : imagesOndrops == 1 ? previewUrl2 : imagesOndrops == 2 ? previewUrl3 : previewUrl4}
                            imagesOndrops={imagesOndrops}
                            setCropImage={imagesOndrops == 0 ? setPreviewUrl1 : imagesOndrops == 1 ? setPreviewUrl2 : imagesOndrops == 2 ? setPreviewUrl3 : setPreviewUrl4}
                            setCropImages1={imagesOndrops == 0 ? setCropImage1 : imagesOndrops == 1 ? setCropImage2 : imagesOndrops == 2 ? setCropImage3 : setCropImage4}
                            toggle={toggle2}
                            images={images}
                            setImagesPreviews={setImagesPreviews}
                            imagesPreviews1={imagesPreviews1}
                        />
                    </ModalBody>
                </div>
            </Modal>

        </React.Fragment >
    )
}

export default UpdateGroomWellProduct

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup, Label } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import { useState } from "react"


const MapCategoryTable = ({
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    className,
    pagePerSize,
    isLoading,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize },
        state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            manualPagination: true,
            initialState: {
                // pageIndex: 0,
                pageSize: 20,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination,
    )
    const [pageIndex, setpageIndex] = useState(1)
    const [prevValue, setPrevValue] = useState();

    const handlesort = column => { }

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    return (
        <Fragment>
            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className="text-nowrap"
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? "Loading..." : "No Record Found"}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </Fragment >

    )
}

MapCategoryTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default MapCategoryTable

// reducer.js

import {
    ADD_TO_CART_REQUEST,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAILURE,
    FINANCE_AMOUNT_REQUEST,
    FINANCE_AMOUNT_SUCCESS,
    FINANCE_AMOUNT_FAILURE
} from "./actionType"


const initialState = {
    addToCartStatus: 0, // Possible values: 'idle', 'loading', 'success', 'error'
    finaceAmountShow: false,
};

const ReduxData = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART_REQUEST:
            return { ...state, addToCartStatus: action.payload };
        case ADD_TO_CART_SUCCESS:
            return { ...state, addToCartStatus: 'success' };
        case ADD_TO_CART_FAILURE:
            return { ...state, addToCartStatus: 'error' };
        case FINANCE_AMOUNT_REQUEST:
            return { ...state, finaceAmountShow: action.payload };
        case FINANCE_AMOUNT_SUCCESS:
            return { ...state, finaceAmountShow: 'success' };
        case FINANCE_AMOUNT_FAILURE:
            return { ...state, finaceAmountShow: 'error' };
        default:
            return state;
    }
};

export default ReduxData;

import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import useApi from "common/ApiMiddlewere"
import * as Yup from "yup"
import toast, { Toaster } from "react-hot-toast"
import Select from "react-select";

const colourStyles = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid  #ced4da",
      minHeight: "28px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}

const colourStyles1 = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid #f46a6a",
      minHeight: "28px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}

const SubscriptionModel = ({
  modal,
  toggle,
  subscriptionApi,
  username,
  subscriptionUserId,
  userListApi,
}) => {
  const { getApi, postApi } = useApi()
  const [planControls, setPlanControls] = useState([])
  const [userControls, setUserControls] = useState([])
  const [SubscriptionData, setSubscriptionData] = useState({
    userId: "",
    planId: "",
    amount: 0,
    discount: "",
    totalAmount: 0,
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    remarks: "",
  })
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [days, setDays] = useState()
  const [planData, setPlanData] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [userId, setUserId] = useState(subscriptionUserId || '')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const { data: { RoleName }, } = await getApi(`User/user-details?id=-1`)
      if (RoleName == "Admin") {
        getApi("Plan/list", "").then(({ data, status }) => {
          if (status === 200) {
            setPlanData(data)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
        getApi("User/vendor-lookup").then(({ data, status }) => {
          if (status === 200) {
            const itemData = data.map(item => ({
              label: item.Value,
              value: item.Key,
            }));
            itemData.unshift({
              label: "Select user",
              value: '',
            });
            setSelectedUser(itemData[0])
            setUserControls(itemData)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
        getApi("Plan/plan-lookup").then(({ data, status }) => {
          if (status === 200) {
            setPlanControls(data)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
        validation.setFieldValue("userId", username)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    validation.handleReset()
    setSubscriptionData({
      userId: userId,
      planId: "",
      amount: 0,
      discount: "",
      totalAmount: 0,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      remarks: "",
      username: username ? false : true,
    })
  }, [modal])

  // subscription Validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: SubscriptionData,
    validationSchema: Yup.object({
      planId: Yup.string().required("The Plan is required"),
      startDate: Yup.string().required("The StartDate is required"),
      endDate: Yup.string().required("The endDate is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (
        (userId == "" ||
          parseInt(values.userId) == parseInt(0) ||
          userId == undefined) && (username == undefined || subscriptionUserId == undefined)
      ) {
        setformSumitLoading(false)
        setIsError(true)
      } else {
        setIsError(false)
        if (values) {
          postApi("SubscriptionLogs/renew-subscription", {
            userId: subscriptionUserId ?? userId,
            planId: values.planId,
            amount: parseInt(values.amount) || 0,
            discount: parseInt(values.discount) || 0,
            startDate: values.startDate,
            endDate: values.endDate,
            remarks: values.remarks,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            toggle()
            if (status == 200) {
              if (subscriptionUserId) {
                userListApi()
              } else {
                subscriptionApi()
              }
              toast.success("Sucessfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              validation.handleReset()
              setSubscriptionData({
                userId: "",
                planId: "",
                amount: 0,
                discount: "",
                totalAmount: 0,
                startDate: new Date().toISOString().slice(0, 10),
                endDate: new Date().toISOString().slice(0, 10),
                remarks: "",
              })
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
              validation.handleReset()
            }
          })
        }
      }
    },
  })

  // useId handler
  const handleUserId = e => {
    setSubscriptionData({
      ...SubscriptionData,
      userId: e.target.value,
    })
  }

  // start date handler
  const handleDate = (e, planName) => {
    setSubscriptionData({
      ...SubscriptionData,
      startDate: e.target.value,
    })
    if (e.target.value) {
      if (planName == "Custom") {
        setSubscriptionData({
          ...SubscriptionData,
          startDate: e.target.value,
          // endDate: new Date(
          //   new Date(e.target.value).getTime() + days * 24 * 60 * 60 * 1000
          // )
          //   .toISOString()
          //   .slice(0, 10),
        })
      } else {
        setSubscriptionData({
          ...SubscriptionData,
          startDate: e.target.value,
          endDate: new Date(
            new Date(e.target.value).getTime() + days * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .slice(0, 10),
        })
      }
    }
  }

  const handleEndDate = (e, planName) => {
    setSubscriptionData({
      ...SubscriptionData,
      startDate: e.target.value,
    })
    if (e.target.value) {
      if (planName == "Custom") {
        setSubscriptionData({
          ...SubscriptionData,
          endDate: e.target.value,
          // startDate: new Date(
          //   new Date(e.target.value).getTime() - days * 24 * 60 * 60 * 1000
          // )
          //   .toISOString()
          //   .slice(0, 10),
        })
      } else {
        setSubscriptionData({
          ...SubscriptionData,
          endDate: e.target.value,
          startDate: new Date(
            new Date(e.target.value).getTime() - days * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .slice(0, 10),
        })
      }
    }
  }

  const autoSelect = (e, selectedValue, name) => {
    if (name == "plan") {
      let TargetValue = e
      const filterData = planData.filter(item => item.Id == TargetValue)
      if (filterData.length) {
        setDays(filterData[0].Days - 1)
        setSubscriptionData({
          ...SubscriptionData,
          planId: filterData[0].Id,
          amount: filterData[0].Amount,
          discount:
            filterData[0].Discount == null ? "0" : filterData[0].Discount,
          totalAmount: filterData[0]?.Amount - filterData[0]?.Discount,
          startDate: new Date().toISOString().slice(0, 10),
          endDate: selectedValue == "Custom" ? new Date().toISOString().slice(0, 10) : new Date(
            new Date().getTime() +
            (filterData[0].Days - 1) * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .slice(0, 10),
        })
      }
    }
  }

  const handleTotalAmountChange = (discountAmount, amount) => {
    discountAmount = discountAmount == "" ? 0 : discountAmount
    amount = amount == "" ? 0 : amount
    const discount = parseInt(discountAmount)
    const planAmount = parseInt(amount)
    validation.setFieldValue("discount", discount)
    validation.setFieldValue("amount", planAmount)
    validation.setFieldValue("totalAmount", planAmount - discount)
  }

  // allow only number key
  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
    }
  };


  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle} tag="h5">
            {"Create Subscription"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation.handleReset()
                return false
              }}
            >
              {username ? (
                <Row>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom01" className="required">
                      User
                    </Label>
                    <Input
                      type="text"
                      id="validationCustom01"
                      className="form-control-sm"
                      name="userId"
                      disabled
                      defaultValue={username}
                      value={username}
                      invalid={
                        validation.touched.userId && validation.errors.userId
                          ? true
                          : false
                      }
                    ></Input>
                    {validation.touched.userId && validation.errors.userId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.userId}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Row>
              ) : (
                <Row>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom01" className="required">
                      Users
                    </Label>
                    <Select
                      name="userId"
                      unstyled
                      styles={isError ? colourStyles1 : colourStyles}
                      value={selectedUser}
                      onChange={(e) => {
                        setSelectedUser(e)
                        setUserId(e?.value)
                        setIsError(false);
                      }}
                      options={userControls}
                      className="select2-selection"
                    />
                    {isError ? (
                      <div className="name-required">
                        {"The User is required"}
                      </div>
                    ) : null}
                  </FormGroup>
                </Row>
              )}

              <Row>
                <FormGroup className="mb-2">
                  <Label htmlFor="validationCustom01" className="required">
                    Plans
                  </Label>
                  <Input
                    type="select"
                    bsSize="sm"
                    id="validationCustom01"
                    className="form-control-sm"
                    name="planId"
                    onChange={e => {
                      const selectedKey = e.target.value;
                      const selectedPlan = planControls.find(item => item.Key == selectedKey);
                      const selectedValue = selectedPlan ? selectedPlan.Value : ""; // If selectedPlan is null, set an empty string
                      autoSelect(selectedKey, selectedValue, "plan");
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.planId || ""}
                    invalid={
                      validation.touched.planId && validation.errors.planId
                        ? true
                        : false
                    }
                  >
                    <option value=''>Select plan</option>
                    {planControls.map((item, index) => {
                      return (
                        <option key={index} value={item.Key}>
                          {item.Value}
                        </option>
                      )
                    })}
                  </Input>
                  {validation.touched.planId && validation.errors.planId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.planId}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom03" className="">
                      Amount
                    </Label>
                    <Input
                      name="amount"
                      placeholder="0"
                      type="text"
                      className="form-control-sm"
                      // onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      onChange={e => {
                        handleTotalAmountChange(validation.values.discount, e.target.value)
                      }}
                      value={validation.values.amount || ""}
                      invalid={
                        validation.touched.amount && validation.errors.amount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.amount && validation.errors.amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.amount}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                {/* <Col md={4}>
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Amount
                      </Label>
                      <Input
                        name="amount"
                        placeholder="0"
                        type="text"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.amount || ""}
                        invalid={
                          validation.touched.amount && validation.errors.amount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.amount}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col> */}

                <Col md={4}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom03">Discount</Label>
                    <Input
                      name="discount"
                      placeholder="0"
                      type="text"
                      className="form-control-sm"
                      // onChange={validation.handleChange}
                      onChange={e => {
                        handleTotalAmountChange(e.target.value, validation.values.amount)
                      }}
                      onKeyPress={handleKeyPress}
                      onBlur={validation.handleBlur}
                      value={validation.values.discount || ""}
                      invalid={
                        validation.touched.discount &&
                          validation.errors.discount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.discount &&
                      validation.errors.discount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.discount}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom03">Total</Label>
                    <Input
                      name="totalAmount"
                      placeholder="0"
                      type="text"
                      disabled
                      className="form-control-sm"
                      min={1}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.totalAmount || ""}
                      invalid={
                        validation.touched.totalAmount &&
                          validation.errors.totalAmount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.totalAmount &&
                      validation.errors.totalAmount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.totalAmount}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-2">
                    <Label htmlFor="validation1Custom01" className="required">
                      Start Date
                    </Label>
                    <Input
                      name="startDate"
                      placeholder="date placeholder"
                      type="date"
                      dateFormat="DD/M/YYYY"
                      className="form-control-sm"
                      onChange={e => {
                        const selectedPlan = planControls.find(item => item.Key == validation.values.planId);
                        const selectedValue = selectedPlan ? selectedPlan.Value : ""; // If selectedPlan is null, set an empty string
                        handleDate(e, selectedValue)
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate}
                      invalid={
                        validation.touched.startDate &&
                          validation.errors.startDate
                          ? true
                          : false
                      }
                    />
                    {validation.touched.startDate &&
                      validation.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <Label htmlFor="validation1Custom01" className="required">
                      End Date
                    </Label>
                    <Input
                      name="endDate"
                      placeholder="date placeholder"
                      min={validation.values.startDate}
                      type="date"
                      dateFormat="DD/M/YYYY"
                      className="form-control-sm"

                      onChange={e => {
                        const selectedPlan = planControls.find(item => item.Key == validation.values.planId);
                        const selectedValue = selectedPlan ? selectedPlan.Value : "";
                        handleEndDate(e, selectedValue)
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate}
                      invalid={
                        validation.touched.endDate && validation.errors.endDate
                          ? true
                          : false
                      }
                    />
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <FormGroup className="mb-2">
                  <Label htmlFor="validationCustom03">Remark</Label>
                  <Input
                    name="remarks"
                    placeholder="Type your remark here"
                    type="textarea"
                    className="form-control-sm"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.remarks || ""}
                    invalid={
                      validation.touched.remarks && validation.errors.remarks
                        ? true
                        : false
                    }
                  />
                  {validation.touched.remarks && validation.errors.remarks ? (
                    <FormFeedback type="invalid">
                      {validation.errors.remarks}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Row>
              <div className="text-end">
                <Button
                  color="secondary"
                  className="btn btn-primary me-2 btn-sm "
                  type="reset"
                >
                  Reset
                </Button>

                {formSumitLoading ? (
                  <button type="button" className="btn btn-primary btn-sm">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                    Loading
                  </button>
                ) : (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm "
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default SubscriptionModel

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Row,
    Col,
    Label,
    FormFeedback,
    Button,
    Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import MapCategoryTable from "./MapCategoryTable"
import { GetObjByTargetGender } from "_mock/Static_data"


function MapCategoriesGroomWell() {
    let { pathname } = useLocation()
    const { postApi, getApi } = useApi()
    document.title = `Map Categories | ${localStorage.getItem("ShopName")}`

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [groomWellCategories, setGroomWellCategories] = useState([])
    const [rentopusCategories, setRentopusCategories] = useState([])
    const [groomWellUSerDetails, setGroomWellUserDetails] = useState()

    useEffect(() => {
        getApi("Category/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Value: "Select category",
                    Key: 0
                });
                setRentopusCategories(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })
        getApi("GroomWell/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                const filterData = data.filter(item => item.IsLast == true)
                filterData.unshift({
                    Id: 0,
                    NamePath: "Select category",
                    IsLast: true
                });
                setGroomWellCategories(filterData)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })

        getApi("GroomWell/user-details").then(({ data, status }) => {
            if (status === 200) {
                setGroomWellUserDetails(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })

        groomWellMappingListApi()
    }, [])

    const groomWellMappingListApi = () => {
        setIsLoading(true)
        getApi("GroomWell/category-mapping-list").then(({ data, status }) => {
            if (status === 200) {
                setTableData(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })
    }

    // product validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            rentopusCategory: 0,
            gwCategory: 0,
            targetGender: 0,
        },
        validationSchema: Yup.object({
            rentopusCategory: Yup.number().min(1, "The rentopus category is required"),
            gwCategory: Yup.number().min(1, "The groomWell category is required"),
            targetGender: Yup.number().min(1, "The targetGender is required"),
        }),
        onSubmit: values => {
            setButtonLoading(true)
            const groomWellCategoryName = groomWellCategories.filter(item => item.Id == parseInt(values?.gwCategory))
            const rentopusCategoryName = rentopusCategories.filter(item => item.Key == parseInt(values?.rentopusCategory))
            const body = {
                categoryId: parseInt(values.rentopusCategory),
                gwCategoryId: parseInt(values.gwCategory),
                targetGender: parseInt(values.targetGender),
                CategoryName: rentopusCategoryName[0].Value,
                GWCategoryName: groomWellCategoryName[0].Name,
            }

            if (groomWellUSerDetails.ContactNo == null || groomWellUSerDetails.Latitude == null || groomWellUSerDetails.Longitude == null) {
                toast.error("GroomWell user does not exist; please create one first.", {
                    style: {
                        fontSize: "15px",
                    },
                })
            } else {
                postApi("GroomWell/create-category-mapping", body).then(({ data, status }) => {
                    if (status == 200) {
                        toast.success("Successfully mapped", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        groomWellMappingListApi()
                        validation.handleReset()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setButtonLoading(false)
                })
            }
        },
    })


    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Rentopus Category",
                accessor: "CategoryName",
            },
            {
                Header: "GroomWell Category",
                accessor: "GWCategoryName",
                Cell: ({ row }) => (
                    <span>
                        {row.original.GWCategoryName ? row.original.GWCategoryName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Target Gender",
                accessor: "TargetGender",
                Cell: ({ row }) => (
                    <span>
                        {row.original.TargetGender == 1 ? "Male" : row.original.TargetGender == 2 ? "Female" : "Unisex"}
                    </span>
                ),
            },
        ],
        [tableData]
    )

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <Col md={3}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom01" className="required">Rentopus Category</Label>
                                        <Input
                                            type="select"
                                            bsSize="sm"
                                            id="validationCustom01"
                                            className="form-control-sm"
                                            name="rentopusCategory"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.rentopusCategory || ""}
                                            invalid={
                                                validation.touched.rentopusCategory &&
                                                    validation.errors.rentopusCategory
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {rentopusCategories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.Key}>
                                                        {item.Value}
                                                    </option>
                                                )
                                            })}
                                        </Input>
                                        {validation.touched.rentopusCategory &&
                                            validation.errors.rentopusCategory ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.rentopusCategory}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom02" className="required">GroomWell Category</Label>
                                        <Input
                                            type="select"
                                            bsSize="sm"
                                            id="validationCustom01"
                                            className="form-control-sm"
                                            name="gwCategory"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.gwCategory || ""}
                                            invalid={
                                                validation.touched.gwCategory &&
                                                    validation.errors.gwCategory
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {groomWellCategories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.Id}>
                                                        {item.IsLast && item.NamePath}
                                                    </option>
                                                )
                                            })}
                                        </Input>
                                        {validation.touched.gwCategory &&
                                            validation.errors.gwCategory ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.gwCategory}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03" className="required">Target Gender</Label>
                                        <Input
                                            type="select"
                                            bsSize="sm"
                                            id="validationCustom02"
                                            className="form-control-sm"
                                            name="targetGender"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.targetGender || ""}
                                            invalid={
                                                validation.touched.targetGender &&
                                                    validation.errors.targetGender
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <option value={0}>
                                                Select gender 
                                            </option>

                                            {GetObjByTargetGender(1, 2, 3).map((item) => {
                                                return (

                                                    <option key={item.Key} value={item.Value}>
                                                        {item.DisplayName}
                                                    </option>
                                                )
                                            })}

                                            {/* <option value={1}>
                                                Male
                                            </option>
                                            <option value={2}>
                                                Female
                                            </option>
                                            <option value={3}>
                                                Unisex
                                            </option> */}
                                        </Input>
                                        {validation.touched.targetGender &&
                                            validation.errors.targetGender ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.targetGender}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <div style={{ marginTop: "22px" }}>
                                        {buttonLoading ? (
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                            >
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                Loading
                                            </button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                className="btn btn-primary btn-sm "
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>

                                </Col>
                            </Row>
                        </Form>
                        <MapCategoryTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={10}
                            className="custom-header-css"
                            isLoading={isLoading}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
MapCategoriesGroomWell.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default MapCategoriesGroomWell

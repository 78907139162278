function GetObjectsByLanguage(...values) {
    const array = [
        {
            Key: "English",
            Value: 1,
            DisplayName: "English",
        },
        {
            Key: "Gujarati",
            Value: 2,
            DisplayName: "Gujarati",
        },
        {
            Key: "Hindi",
            Value: 3,
            DisplayName: "Hindi",
        },
    ];

    return array.filter(obj => values.includes(obj.Value));
}

export default GetObjectsByLanguage
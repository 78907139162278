// export default Earning
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import toast from "react-hot-toast"
import { routes } from "routes/path"
import { useSelector } from "react-redux"

const Earning = ({ loader }) => {

  const { postApi } = useApi()
  const [day, setDay] = useState([])
  const [dates, setDates] = useState([])
  const [seletedMonth, setSeletedMonth] = useState()
  const [CurrentMonthSales, setCurrentMonthSales] = useState()
  const [PreviousMonthSales, setPreviousMonthSales] = useState()
  const [salesDetailInPercentage, setsalesDetailInPercentage] = useState()
  const [FilterMonthSales, setFilterMonthSales] = useState()

  const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);


  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const salesChartAPICall = async (fromDate, toDate) => {
    loader(true);
    try {
      const { data, status } = await postApi("Report/sales-chart", {
        fromDate,
        toDate,
      })

      if (status === 200) {
        const {
          SalesChart,
          PreviousMonthSales,
          CurrentMonthSales,
          FilterMonthSales
        } = data

        setDay(SalesChart)
        setFilterMonthSales(FilterMonthSales.toLocaleString('en-IN', { currency: 'INR' }) + ".00")
        setCurrentMonthSales(CurrentMonthSales.toLocaleString('en-IN', { currency: 'INR' }) + ".00")
        setPreviousMonthSales(PreviousMonthSales.toLocaleString('en-IN', { currency: 'INR' }) + ".00")

        let percentageChange = 100;
        if (PreviousMonthSales > 0) {
          percentageChange = ((CurrentMonthSales - PreviousMonthSales) / PreviousMonthSales) * 100;
        }
        setsalesDetailInPercentage(percentageChange.toFixed(2))
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      loader(false);
    } catch (error) {
      loader(false);
    }
  }

  useEffect(() => {
    const toDate = dateFormat(new Date(), "yyyy-MM-dd")
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
    const fromDate = dateFormat(thirtyDaysAgo, "yyyy-MM-dd")

    salesChartAPICall(fromDate, toDate);
  }, [])

  const onChangeMonth = value => {
    var toDate = dateFormat(new Date(), "yyyy-MM-dd")
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
    var fromDate = dateFormat(thirtyDaysAgo, "yyyy-MM-dd")
    if (value !== "Select") {
      fromDate = dates[value].firstDate
      toDate = dates[value].lastDate
    }

    salesChartAPICall(fromDate, toDate);
    setSeletedMonth(value)
  }

  const getFirstAndLastDateOfPreviousMonth = monthOffset => {
    const currentDate = new Date()
    const targetMonth = currentDate.getMonth() - monthOffset
    const year = currentDate.getFullYear()
    const targetMonthIndex = (targetMonth + 12) % 12
    const targetYear = targetMonth < 0 ? year - 1 : year
    const monthName = monthNames[targetMonthIndex]
    const firstDate = new Date(targetYear, targetMonthIndex, 1)
    const lastDate = new Date(targetYear, targetMonthIndex + 1, 0)

    return { firstDate, lastDate, monthName }
  }

  useEffect(() => {
    const generatedDates = Array.from({ length: 12 }, (_, index) => {
      const { firstDate, lastDate, monthName } =
        getFirstAndLastDateOfPreviousMonth(index)
      return {
        firstDate: dateFormat(firstDate, "yyyy-MM-dd"),
        lastDate: dateFormat(lastDate, "yyyy-MM-dd"),
        month: monthName,
      }
    })

    setDates(generatedDates.reverse())
  }, [])

  const apexEarningChartColors = ["#556ee6"]

  const options = {
    chart: {
      toolbar: false,
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: apexEarningChartColors,
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: day.map(({ CreatedOn }) => new Date(CreatedOn).getDate()),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (
            "₹" +
            new Intl.NumberFormat('en-IN', {
              currency: 'INR',
              minimumFractionDigits: 2,
            }).format(val || 0)
          )

          //"₹" +val.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });

        },
      },
    },
  };

  const series = [
    {
      name: "Sales",
      data: day.map(({ Amount }) => {
        return Amount;
      }),
    },
  ]

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end d-flex gap-2">
                <div className="text-nowrap pt-1" style={{ fontSize: "15px", fontWeight: '500' }}>

                  Total Amount :
                  {financeAmountShowHide ? <span>
                    <span style={{ fontFamily: "monospace", fontSize: "17px", paddingRight: "2px", fontWeight: '500' }}>₹</span>{FilterMonthSales}
                  </span>
                    : <span style={{ fontFamily: "monospace", fontSize: "17px", paddingRight: "2px", fontWeight: '500' }}>***** </span>

                  }
                </div>

                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={e => onChangeMonth(e.target.value)}
                  >
                    <option>Select</option>
                    {dates.map(({ month }, index) => (
                      <option value={index} key={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <label className="input-group-text">Month</label>
                </div>
              </div>
              <h3 className="card-title mb-4">Earning</h3>
            </div>

            <Row>
              <Col lg="3">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>This month</p>
                    {financeAmountShowHide ? <h4 style={{ fontSize: "25px" }}>
                      <span style={{ fontFamily: "monospace", fontSize: "27px", paddingRight: "2px" }}>₹</span>
                      {CurrentMonthSales}
                    </h4> : <h4 style={{ fontSize: "25px" }}>
                      *****
                    </h4>}

                    <div>
                      <span
                        className={`badge ${salesDetailInPercentage <= 1
                          ? `badge-soft-danger`
                          : `badge-soft-success`
                          }   font-size-12 me-1`}
                      >
                        {salesDetailInPercentage == NaN ? 0 : salesDetailInPercentage} %
                      </span>
                      From previous period
                    </div>
                  </div>

                  <div>
                    <Link
                      to={routes.BookedOrderReport}
                      className="btn btn-primary  btn-sm"
                    >
                      View Details
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">Last month</p>
                    {financeAmountShowHide ?
                      <h5 style={{ fontSize: "18px" }}>
                        <span style={{ fontFamily: "monospace", fontSize: "20px", paddingRight: "2px" }}>₹</span>
                        {PreviousMonthSales}
                      </h5> :
                      <h5 style={{ fontSize: "18px" }}> ***** </h5>
                    }
                  </div>
                </div>
              </Col>

              <Col lg="9">
                <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Earning

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card, CardBody, Col, Input, Label, Row, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import IncomeExpenceTable from './IncomeExpenceTable'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from 'react-router-dom'
import { dateFormat, getCurrentFinancialYear, timeFormate } from 'pages/Common'
import useApi from 'common/ApiMiddlewere'
import Select, { components } from "react-select";
import { toast } from 'react-hot-toast'
import appConfig from 'Api/app.config'
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const borderRadius = "1px";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex",
        borderRadius: borderRadius,
    };

    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style,
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <div className='mt-2 m-1'>
                <input type="checkbox" checked={isSelected} />
            </div>
            {children}
        </components.Option>
    );
};

const allOptions = [
    { value: "Name", label: "Name" },
    { value: "Details", label: "Details" },
    { value: "Mode", label: "Mode" },
];

const selectStyles = {
    control: (provided, { isFocused }) => (
        {
            label: "control",
            alignItems: "center",
            cursor: "default",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            minHeight: 38,
            outline: "0 !important",
            position: "relative",
            transition: "all 100ms",
            backgroundColor: "hsl(0, 0%, 100%)",
            borderColor: "hsl(0, 0%, 80%)",
            borderRadius: 1,
            borderStyle: "solid",
            borderWidth: 1,
            '&:hover': {
                borderColor: "none",
            },
            boxSizing: "border-box",
        }
    ),
    placeholder: (provided) => ({
        ...provided,
        color: "gray",
    }),
};

let IsCurrentYear = false
const IncomExpense = () => {

    document.title = `IncomeExpense | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { postApi } = useApi()
    const flatpickrRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadiButtonng, setIsButtonLoading] = useState(false)
    const [isMobile, setisMobile] = useState(window.innerWidth < 520)
    const [isIpad, setisIpad] = useState(window.innerWidth >= 520 && window.innerWidth < 769);
    const [totalIncome, setTotalIncome] = useState()
    const [totalExpense, setTotalExpense] = useState()
    const [totlaCashIncome, setTotalCashIncome] = useState(0)
    const [totalBankIncome, setTotalBankIncome] = useState(0)
    const [totalCashExpense, setTotalCashExpense] = useState(0)
    const [totalBankExpense, setTotalBankExpense] = useState(0)
    const [IncomeData, setIncomeData] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [date, setdate] = useState([new Date(), new Date()])
    const [isSearchDate, setSearchDate] = useState(false)
    const [visible, setVisible] = useState(false)

    const handaleDateChange = (e) => {
        setdate(e)
    }

    useEffect(() => {
        const startYear = localStorage.getItem("FinancialYearDate")?.split(" to ")[0]
        const endYear = localStorage.getItem("FinancialYearDate")?.split(" to ")[1]
        const SelectedFinancialYear = startYear?.split("-")[2] + "-" + (endYear?.split("-")[2])
        if (SelectedFinancialYear == getCurrentFinancialYear()) {
            setdate([new Date(), new Date()])
        } else {
            let Date1 = [`01-04-${SelectedFinancialYear?.split('-')[0]}`, `31-03-${SelectedFinancialYear?.split('-')[1]}`]
            const convertedDates = Date1.map(dateStr => {
                const [day, month, year] = dateStr.split('-');
                const dateObj = new Date(year, month - 1, day);
                return dateObj
            });
            IsCurrentYear = true
            setdate(convertedDates)
        }
    }, [localStorage.getItem("FinancialYearDate")])


    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 550) {
            setVisible(true)
        }
        else if (scrolled <= 550) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    const incomeExpenseApi = () => {
        if (date[1] == undefined) {
            toast("Please Select date", {
                icon: "⚠️",
                style: {
                    fontSize: "15px",
                },
            })
        } else {
            setIsButtonLoading(true)
            postApi("Report/income-expense-report", {
                fromDate: date[0] ? dateFormat(
                    date[0],
                    "yyyy-MM-dd"
                ) : new Date().toISOString().slice(0, 10),
                toDate: date[1] ? dateFormat(
                    date[1],
                    "yyyy-MM-dd"
                ) : new Date().toISOString().slice(0, 10),
            }).then(({ data, status }) => {
                console.log("data", data)
                IsCurrentYear = false
                if (status === 200) {
                    setIncomeData(data?.IncomeExpenseCombineList)
                    setTotalIncome(data?.TotalIncome)
                    setTotalExpense(data?.TotalExpenses)
                    setTotalBankExpense(data?.TotalBankExpenses)
                    setTotalBankIncome(data?.TotalBankIncome)
                    setTotalCashExpense(data?.TotalCashExpenses)
                    setTotalCashIncome(data?.TotalCashIncome)
                    setSearchDate(false)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
                setIsButtonLoading(false)
            })
        }

    }

    useEffect(() => {
        incomeExpenseApi()
        setSearchDate(false)
    }, [IsCurrentYear])

    const handleDownloadPDF = async () => {
        setIsLoading(true)
        postApi("Report/export-income-expense-report", {
            fromDate: date[0] ? dateFormat(
                date[0],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            toDate: date[1] ? dateFormat(
                date[1],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
        }).then(({ data, status }) => {
            if (status === 200) {
                const byteCharacters = atob(data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Income_Expense_Report.pdf`;

                link.click();

                window.URL.revokeObjectURL(link.href);
                setIsLoading(false)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "Date",
                width: "6%",
                Cell: ({ row }) => (
                    <p className=" mb-0  text-wrap">
                        {row.original.Name == '' ? ""
                            : dateFormat(
                                row.original.Date.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Name",
                accessor: "Name",
                width: "7%",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Name}`}
                    >
                        {row.original.Name == '' ? "" : row.original.Name}
                    </p>
                ),
            },
            {
                Header: "Details",
                accessor: "Details",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Details == null ? '' : row.original.Details}`}
                    >
                        {row.original.Details == ""
                            ? ""
                            : row.original.Details}
                    </p>
                ),
            },
            {
                Header: "Mode",
                accessor: "PaymentMode",
                width: "4%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.PaymentMode == 1
                            ? "Cash"
                            : row.original.PaymentMode == 2
                                ? "Bank" : ''}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Amount
                    </div>
                ),
                accessor: "Amount",
                textAline: "end",
                borderRight: "1px solid #959595b0",
                // width: "13%",
                Cell: ({ row }) => {
                    return (
                        <>
                            <div style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end",
                                textAlign: "end"
                            }}
                            >
                                {row.original.Name ?
                                    <>
                                        <i className="mdi mdi-currency-inr"></i>
                                        {new Intl.NumberFormat('en-IN', {
                                            currency: 'INR',
                                            minimumFractionDigits: 2,
                                        }).format(Math.round(row?.original?.Amount) || 0)}</>
                                    : ""}

                            </div>
                        </>

                    )
                },
            },
            {
                Header: "Date",
                accessor: "ExpenseDate",
                width: "6%",
                Cell: ({ row }) => (
                    <p className=" mb-0 text-wrap">
                        {row.original.ExpenseName == ""
                            ? ""
                            : dateFormat(
                                row.original.ExpenseDate?.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Name",
                accessor: "ExpenseName",
                width: "7%",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.ExpenseName}`}
                    >
                        {row.original.ExpenseName == ""
                            ? ""
                            : row.original.ExpenseName}
                    </p>
                ),
            },
            {
                Header: "Details",
                accessor: "ExpenseDetails",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        // style={{width:"120px"}}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.ExpenseDetails == null ?
                            '' : row.original.ExpenseDetails}`}

                    >
                        {row.original.ExpenseDetails == ""
                            ? ""
                            : row.original.ExpenseDetails}
                    </p>
                ),
            },
            {
                Header: "Mode",
                accessor: "ExpensePaymentMode",
                width: "4%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ExpensePaymentMode == 1
                            ? "Cash"
                            : row.original.ExpensePaymentMode == 2
                                ? "Bank" : ''}
                    </p>
                ),
            },
            {
                Header: "Amount",
                accessor: "ExpenseAmount",
                textAline: "end",
                // width: "13%",
                Cell: ({ row }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end",
                                textAlign: "end"
                            }}
                        >
                            {
                                row?.original?.ExpenseName ? (
                                    <>
                                        <i className="mdi mdi-currency-inr"></i><>
                                            {new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(row?.original?.ExpenseAmount || 0)}</>
                                    </>
                                ) : ("")
                            }
                        </div>
                    )
                },
            },
        ],
        [selectedOptions]
    )

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={9} style={{ marginTop: "23px" }}>
                                <Row>
                                    <Col md={isIpad ? 12 : 5} className={`${isMobile ? 'serchButton' : 'serchButtonMargin'} mb-2 `}   >
                                        <Select
                                            defaultValue={[]}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={(options) => {
                                                if (Array.isArray(options)) {
                                                    setSelectedOptions(options.map((opt) => opt.value));
                                                }
                                            }}
                                            options={allOptions}
                                            components={{
                                                Option: InputOption,
                                            }}
                                            placeholder="Hide columns"
                                            styles={selectStyles}
                                        />
                                    </Col>
                                    <Col md={4} className="table-header" >
                                        <div className=" d-flex">
                                            <Flatpickr
                                                ref={flatpickrRef}
                                                className="form-control d-block"
                                                placeholder="dd M,yyyy"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y",
                                                    // minDate: "",
                                                    // maxDate: "",
                                                    minDate: localStorage.getItem("FinancialYearDate")?.split(" to ")[0],
                                                    maxDate: localStorage.getItem("FinancialYearDate")?.split(" to ")[1],
                                                }}
                                                value={date}
                                                onChange={(e) => handaleDateChange(e)}
                                            />
                                            <i
                                                className="fas fa-calendar-alt calander-icon"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    flatpickrRef.current.flatpickr.open();
                                                }}
                                            ></i>
                                        </div>
                                    </Col>
                                    <Col md={1} className="table-header mb-2 pe-0" >
                                        <div className={`${isMobile ? 'serchButton' : 'serchButtonMargin'} mb-2 `}>
                                            {isLoadiButtonng ? (
                                                <button
                                                    title='Search'
                                                    type="button" className="btn btn-primary">
                                                    <i className="bx bx-hourglass bx-spin font-size-13 align-middle"></i>
                                                    {/* Loading */}
                                                </button>
                                            ) : (
                                                <Button
                                                    title='Search'
                                                    className="btn btn-primary"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        incomeExpenseApi()
                                                    }}
                                                >
                                                    <i className="bx bx-search-alt font-size-16 align-middle"></i>
                                                    {/* Search */}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={1} className="table-header mb-2 p-0">
                                        <div className={`${isMobile ? 'serchButton' : 'serchButtonMargin'} mb-2 `}>
                                            {IncomeData.length > 0 ? (
                                                <Button
                                                    title='Export PDF'
                                                    className="btn btn-pdf"
                                                    // color="primary"
                                                    type="button"
                                                    onClick={handleDownloadPDF}
                                                >
                                                    {/* Export */}
                                                    <i className="far fa-file-pdf font-size-16"></i>
                                                </Button>) :
                                                (<Button
                                                    title='Export PDF'
                                                    className="btn btn-pdf"
                                                    // color="primary"
                                                    type="button"
                                                    style={{ cursor: "not-allowed" }}
                                                >
                                                    {/* Export */}
                                                    <i className="far fa-file-pdf font-size-16"></i>
                                                </Button>)
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3 mb-3'>
                            <Col md={3}>
                                <Row>
                                    <Col md={5} style={{
                                        textAlign: "start"
                                    }}>
                                        <div style={{ fontSize: "14px", color: "" }}>
                                            <>
                                                Cash Income:
                                            </>
                                        </div>
                                        <div style={{ fontSize: "14px", color: "" }}>
                                            <>
                                                Bank Income:
                                            </>
                                        </div>
                                        <div style={{ fontSize: "14px", color: "" }}>
                                            <>
                                                Total:
                                            </>
                                        </div>
                                    </Col>
                                    <Col md={7} style={{ textAlign: isMobile ? "start" : "start" }}>
                                        <div style={{ fontSize: "14.5px", color: "green", wordSpacing: "-5px" }}>    <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {<>{new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(totlaCashIncome) || 0)}</>}
                                        </></div>
                                        <div style={{ fontSize: "14.5px", color: "Blue", wordSpacing: "-5px" }}> <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {<>{new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(totalBankIncome) || 0)}</>}
                                        </>
                                        </div>
                                        <div style={{ fontSize: "14.5px", color: "green", wordSpacing: "-5px" }}>    <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {<>{new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(totalIncome) || 0)}</>}
                                        </>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Row>
                                    <Col md={5} style={{
                                        textAlign: "start"
                                    }}>
                                        <div style={{ fontSize: "14px", color: "" }}>
                                            <>
                                                Cash Expense:
                                            </>
                                        </div>
                                        <div style={{ fontSize: "14px", color: "" }}>
                                            <>
                                                Bank Expense:
                                            </>
                                        </div>
                                        <div style={{ fontSize: "14px", color: "" }}>
                                            <>
                                                Total:
                                            </>
                                        </div>
                                    </Col>
                                    <Col md={7} style={{ textAlign: isMobile ? "start" : "start" }}>
                                        <div style={{ fontSize: "14.5px", color: "Blue", wordSpacing: "-5px" }}>
                                            <>
                                                <i className="mdi mdi-currency-inr"></i>
                                                {<>{new Intl.NumberFormat('en-IN', {
                                                    currency: 'INR',
                                                    minimumFractionDigits: 2,
                                                }).format(Math.round(totalCashExpense) || 0)}</>}
                                            </>
                                        </div>
                                        <div style={{ fontSize: "14.5px", color: "green", wordSpacing: "-5px" }}> <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {<>{new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(totalBankExpense) || 0)}</>}
                                        </>
                                        </div>
                                        <div style={{ fontSize: "14.5px", color: "Blue", wordSpacing: "-5px" }}> <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {<>{new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(totalExpense) || 0)}</>}
                                        </>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Row>
                                    <Col md={3} style={{
                                        textAlign: "start"
                                    }}>
                                        <div style={{ fontSize: "14px", fontWeight: "" }}>
                                            <>
                                                {totalIncome - totalExpense > 0 ? "Profit:" : "Loss:"}
                                            </>
                                        </div>
                                    </Col>
                                    <Col md={5} style={{ textAlign: isMobile ? "start" : "start" }}>
                                        <div style={{ fontSize: "14.5px", wordSpacing: "-5px", fontWeight: "" }}> <>
                                            {totalIncome - totalExpense > 0 ?

                                                <span style={{ color: "green" }}>
                                                    <i className="mdi mdi-currency-inr"></i>
                                                    {<>{new Intl.NumberFormat('en-IN', {
                                                        currency: 'INR',
                                                        minimumFractionDigits: 2,
                                                    }).format(Math.round(totalIncome - totalExpense) || 0)}</>}
                                                </span>
                                                :
                                                <span style={{ color: "red" }}>
                                                    <i className="mdi mdi-currency-inr"></i>
                                                    {<>{new Intl.NumberFormat('en-IN', {
                                                        currency: 'INR',
                                                        minimumFractionDigits: 2,
                                                    }).format(Math.round(totalIncome - totalExpense) || 0)}</>}
                                                </span>
                                            }
                                        </>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <IncomeExpenceTable
                                    columns={columns}
                                    data={IncomeData}
                                    isLoading={isLoading}
                                    totalIncome={totalIncome}
                                    totalExpense={totalExpense}
                                    selectedOptions={selectedOptions}
                                />
                            </Col>
                        </Row>
                        {/* <Button> */}
                        <div onClick={scrollToTop} style={{ position: "fixed", zIndex: "1", bottom: "45px", left: "97%", display: visible ? 'inline' : 'none' }}>
                            <i className="bx bx-chevron-up font-size-25 align-middle me-1" style={{ border: "2px solid #808080ba", borderRadius: "50%", padding: "5px", fontSize: "30px", color: "#808080ba" }}></i>
                            {/* <FaArrowCircleUp onClick={scrollToTop}
                                style={{ display: visible ? 'inline' : 'none' }} /> */}
                            {/* </Button> */}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div >
    )
}

export default IncomExpense


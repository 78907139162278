import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Form,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"

function InquiryModel({ modal, toggle, userId, getTableData }) {
  const navigate = useNavigate()
  const [followUpdetails, setfollowUpdetails] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const { postApi } = useApi()

  useEffect((
  ) => {
    validation.handleReset()
  }, [modal])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Status: "",
      NextFollowup: "",
      Comment: "",
    },
    validationSchema: Yup.object({
      Status: Yup.string().required("The status is required"),
      Comment: Yup.string().required("The comment is required"),
    }),
    onSubmit: values => {
      setisLoading(true)
      const { Status, Comment, NextFollowup } = values

      postApi("Inquiry/followup", {
        id: userId,
        status: parseInt(Status),
        NextFollowup: NextFollowup ? NextFollowup : null,
        comment: Comment,
      }).then(({ data, status }) => {
        setisLoading(false)
        if (status === 200) {
          toggle()
          getTableData()
          validation.handleReset()
          toast.success("Update successfully", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle} tag="h4">
            Followup
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation.handleReset()
                return false
              }}
            >
              <FormGroup className="mb-2">
                <Label htmlFor="formrow-InputState" className="required">
                  Status
                </Label>
                <Input
                  type="select"
                  bsSize="sm"
                  id="formrow-InputState"
                  className="form-control"
                  name="Status"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Status || ""}
                  invalid={
                    validation.touched.Status && validation.errors.Status
                      ? true
                      : false
                  }
                >
                  <option>Select</option>
                  <option value={1}>Lead</option>
                  <option value={2}>Followup</option>
                  <option value={3}>Convert</option>
                  <option value={4}>Fail</option>
                </Input>
                {validation.touched.Status && validation.errors.Status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Status}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup className="mb-2">
                <div>
                  <Label htmlFor="validation1Custom01">
                    Next Followup Date
                  </Label>
                  <Input
                    name="NextFollowup"
                    placeholder="date placeholder"
                    type="date"
                    // min={new Date().toISOString().slice(0, 10)}
                    dateFormat="DD/M/YYYY"
                    className="form-control-sm"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.NextFollowup}
                  />
                </div>
              </FormGroup>

              <FormGroup className="mb-2">
                <Label htmlFor="validationCustom03" className="required">
                  Comment
                </Label>
                <Input
                  name="Comment"
                  placeholder="type your comment here"
                  type="textarea"
                  row={2}
                  className="form-control-sm"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Comment || ""}
                  invalid={
                    validation.touched.Comment && validation.errors.Comment
                      ? true
                      : false
                  }
                />
                {validation.touched.Comment && validation.errors.Comment ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Comment}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <div className="text-end">
                <Button
                  color="secondary"
                  className="btn btn-primary me-2 btn-sm "
                  type="reset"
                >
                  Reset
                </Button>

                {isLoading ? (
                  <button type="button" className="btn btn-primary btn-sm">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                    Loading
                  </button>
                ) : (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm "
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default InquiryModel

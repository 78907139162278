import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Input,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { DefaultColumnFilter } from "components/Common/filters"


const SalesmanTable = ({
  columns,
  data,
  salemanList,
  isGlobalFilter,
  customPageSize,
  className,
  dateValue,
  isLoading = { isLoading },
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 5000,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )

  const handlesort = column => {
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const flatpickrRef = useRef(null);

  const [isMobile, setisMobile] = useState(window.innerWidth < 520)
  const [isIpad, setIsIpad] = useState(window.innerWidth >= 520 && window.innerWidth < 769);

  const [month, setMonth] = useState(new Date().toISOString().slice(0, 10))
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [saleamanId, setSalesmanId] = useState(0)
  const [transactionType, setTransactionType] = useState(1)

  if (dateValue) {
    dateValue(month, saleamanId,transactionType)
  }

  const margin = {
    marginTop: isMobile ? "8px" : "",
  }
  return (
    <Fragment>
      <Row className="table-header mb-2" >
        <Col md={7}>
          <Row>
            <Col md={5} style={margin}>
              {isGlobalFilter && (
                <div>
                  <select
                    className="form-select"
                    value={saleamanId}
                    onChange={e => setSalesmanId(e.target.value)}
                  >
                    <option value={0}>Select salesman</option>
                    {salemanList.map((item, index) => {
                      return (
                        <option key={index} value={item.Key}>
                          {item.Value}
                        </option>
                      )
                    })}

                  </select>
                </div>
              )}
            </Col>
            <Col md={4} style={margin} >
              {isGlobalFilter && (
                <div>
                  <Input
                    name="form"
                    type="month"
                    dateFormat="M/YYYY"
                    className="form-control"
                    defaultValue={new Date().toISOString().slice(0, 7)}
                    onChange={e => setMonth(new Date(e.target.value).toISOString().slice(0, 10))}
                  />
                </div>
              )}
            </Col>
            <Col md={3} style={margin} >
              <Input
                type="select"
                id="validationCustom02"
                className="form-control"
                name="salesman"
                onChange={e => {
                  setTransactionType(e.target.value)
                }}
                value={transactionType}
              >
                <option value={1}>Booking</option>
                <option value={2}>Sale</option>
              </Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="text-nowrap"
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </Fragment>
  )
}

SalesmanTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SalesmanTable

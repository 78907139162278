import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
    Button,
    Form,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap"
import useApi from "common/ApiMiddlewere"
import GetObjectsByLanguage from "_mock/Languages"
import { toast } from "react-hot-toast"

function TutorialModel({ modal, toggle, getTutorialData, tutorialData, IsUpdate }) {
    const [isLoading, setisLoading] = useState(false)
    const { postApi, putApi } = useApi()

    useEffect(() => {
        validation.handleReset()
    }, [modal])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: tutorialData ? tutorialData.Title : "",
            url: tutorialData ? tutorialData.Url : "",
            order: tutorialData ? tutorialData.Order : 0,
            language: tutorialData ? tutorialData.Language : 1,
            discription: tutorialData ? tutorialData.Discription : "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("The title is required"),
            url: Yup.string().required("The url is required"),
            language: Yup.string().required("The language is required"),
            order: Yup.string().required("The order is required"),
        }),
        onSubmit: values => {
            setisLoading(true)

            values.order = parseInt(values.order)
            values.language = parseInt(values.language)
            let url = IsUpdate ? "Tutorials/update" : "Tutorials/create"
            let Api = IsUpdate ? putApi : postApi
            if (IsUpdate) {
                values.Id = tutorialData.Id
            }

            const tostMessage = IsUpdate
                ? "Update Tutorial Successfully"
                : "Create Tutorial Successfully"
            Api(url, values).then(({ data, status }) => {
                setisLoading(false)
                if (status === 200) {
                    setisLoading(false)
                    toggle()
                    getTutorialData()
                    validation.handleReset()
                    toast.success(tostMessage, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                } else {
                    setisLoading(false)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
        },
    })
    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
                style={{ width: "350px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h4">
                        {IsUpdate ? "Update Tutorial" : "Create Tutorial"}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <FormGroup className="mb-2">
                                    <Label htmlFor="formrow-InputState" className="required">
                                        Title
                                    </Label>
                                    <Input
                                        type="text"
                                        bsSize="sm"
                                        id="formrow-InputState"
                                        className="form-control-sm"
                                        name="title"
                                        placeholder="Question Title"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.title || ""}
                                        invalid={
                                            validation.touched.title && validation.errors.title
                                                ? true
                                                : false
                                        }
                                    />

                                    {validation.touched.title && validation.errors.title ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.title}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="mb-2">
                                    <Label htmlFor="formrow-InputState" className="required">
                                        URL
                                    </Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputState"
                                        className="form-control-sm"
                                        name="url"
                                        placeholder="Question URL"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.url || ""}
                                        invalid={
                                            validation.touched.url && validation.errors.url
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.url && validation.errors.url ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.url}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="mb-2">
                                    <Label htmlFor="validationCustom03" className="">
                                        Discription
                                    </Label>
                                    <Input
                                        name="discription"
                                        placeholder="type your discription here"
                                        type="textarea"
                                        row={2}
                                        className="form-control-sm-sm"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.discription || ""}
                                        invalid={
                                            validation.touched.discription && validation.errors.discription
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.discription && validation.errors.discription ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.discription}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="formrow-InputState" className="required">
                                            Language
                                        </Label>
                                        <Input
                                            type="select"
                                            bsSize="sm"
                                            id="formrow-InputState"
                                            className="form-control-sm"
                                            name="language"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.language || ""}
                                            invalid={
                                                validation.touched.language &&
                                                    validation.errors.language
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {GetObjectsByLanguage(1, 2, 3).map((item, index) => {
                                                return (
                                                    <option key={index} className="bg-light text-dark" value={item.Value}>
                                                        {item.DisplayName}
                                                    </option>
                                                )
                                            })}
                                        </Input>
                                        {validation.touched.language &&
                                            validation.errors.language ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.language}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="formrow-InputState" className="required">
                                            Order
                                        </Label>
                                        <Input
                                            type="number"
                                            bsSize="sm"
                                            id="formrow-InputState"
                                            classorder="form-control-sm"
                                            name="order"
                                            placeholder="order"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.order}
                                            invalid={
                                                validation.touched.order && validation.errors.order
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.order && validation.errors.order ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.order}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                </Col>
                            </Row>
                            <div className="text-end">
                                {isLoading ? (
                                    <button type="button" className="btn btn-primary btn-sm">
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                        Loading
                                    </button>
                                ) : (
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-sm "
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}

export default TutorialModel

/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Container,
  FormFeedback,
  CardBody,
} from "reactstrap"
import Select from "react-select";
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useRef } from "react"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import AccessoriesReportTable from "./AccessoriesReportTable";


const colourStyles = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid  #ced4da",
      cursor: isDisabled ? "not-allowed" : "default",
      backgroundColor: isDisabled ? "#e8e9ebd1" : "default",
      minHeight: "36px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}

let AccessoriesData = []
function AccessoriesReport() {
  const { getApi, postApi } = useApi()
  let { pathname } = useLocation()
  // useStates
  const [categories, setCategories] = useState([])
  const [accessories, setAccessories] = useState([])
  const [accessoryValue, setAccessoryValue] = useState([])
  const [date, setdate] = useState([new Date(), new Date()])
  const [screeLoad, setScreenLoad] = useState(false)
  const [categoryDataList, setCategoryDataList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formSumitLoading2, setformSumitLoading2] = useState(false)
  const [accessoryValueName, setAccessoryValueName] = useState({
    label: "Select accessory",
    value: 0,
    id: 0
  })
  const [accessoryValueId, setAccessoryValueId] = useState(0)
  const [dateDisble, setdateDisble] = useState(true)

  const handleClick = () => {
    setdateDisble(!dateDisble);
  }

  useEffect(() => {
    setScreenLoad(true)
    getApi("Category/accessory-category-lookup").then(({ data, status }) => {
      if (status === 200) {
        setCategories(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setScreenLoad(false)
    })

    getApi("Category/accessory-lookup").then(({ data, status }) => {
      if (status === 200) {
        AccessoriesData = data.map(item => ({
          label: item.Value,
          value: item.Key,
          Id: item.Key,
        }));
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

  }, [])

  function handleSelectGroup(selectedId) {
    setAccessoryValueName(selectedId);
    setAccessoryValueId(selectedId.value)
  }

  // avalability validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: 0,
      accessory: 0,
      accessoryValueId: 0,
    },
    validationSchema: Yup.object({
      category: Yup.number().min(1, "The category is required"),
      accessory: Yup.number().min(1, "The sub category is required"),
    }),
    onSubmit: values => {
      values.accessoryValueId = accessoryValueId || 0
      if (values) {
        if (date[1] == undefined) {
          toast("Please Select date", {
            icon: "⚠️",
            style: {
              fontSize: "15px",
            },
          })
        } else {
          setformSumitLoading2(true)
          setIsLoading(true)
          postApi("Report/accessory-report", {
            fromDate: !dateDisble ? null : date[0] ? dateFormat(
              date[0],
              "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            toDate: !dateDisble ? null : date[1] ? dateFormat(
              date[1],
              "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            categoryId: parseInt(values.category),
            accessoryId: parseInt(values.accessory),
            accessoryValueId: parseInt(values.accessoryValueId),
          }).then(({ data, status }) => {
            if (status == 200) {
              setCategoryDataList(data)
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
            setformSumitLoading2(false)
            setIsLoading(false)
          })
        }
      }
    },
  })

  useEffect(() => {
    setScreenLoad(true)
    if (validation.values.category) {
      getApi(`Category/details?id=${validation.values.category}`).then(({ data, status }) => {
        if (status === 200) {
          setCategoryDataList([])
          setAccessoryValueName({
            label: "Select accessory",
            value: 0,
            id: 0
          })
          setAccessoryValueId(0)
          setAccessoryValue('')
          const selectedAccessoryIds = data?.AccessoryIds?.split(',').map(Number);
          const selectedAccessories = AccessoriesData?.filter(accessory => selectedAccessoryIds?.includes(accessory?.Id));
          // setAccessories(selectedAccessories)
          setAccessories(data.AccessoryList)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
        setScreenLoad(false)
      })
    }
    validation.setFieldValue("accessory", 0)
  }, [validation.values.category])

  useEffect(() => {
    if (validation.values.accessory > 0) {
      setScreenLoad(true)
      getApi(`Category/accessory-value-lookup?accessoryIds=${validation.values.accessory}`).then(({ data, status }) => {
        if (status === 200) {
          setIsLoading(false)
          const accessoryData = data.map(item => ({
            label: item.Value,
            value: item.Id,
            id: item.Id
          }));
          accessoryData.unshift({
            label: "Select accessory",
            value: 0,
            id: 0
          });
          setAccessoryValueName(accessoryData[0])
          setAccessoryValue(accessoryData);

        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
        setScreenLoad(false)
      })

    }

  }, [validation.values.accessory])

  // table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "AccessoryValue",
      },
      {
        Header: "Date",
        accessor: "DateRange",
        width: "10%",
        Cell: ({ row }) => (
          <div className=" mb-0">
            {row.original.DateRange == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DateRange.split("T")[0],
                "dd/MM/yyyy"
              )}
          </div>
        ),
      },
      {
        Header: "Stock",
        accessor: "Stock",
        width: "5%",
        Cell: ({ row }) => (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}>
            {row.original.Stock}
          </div>
        ),
      },
      {
        Header: "Count",
        accessor: "Count",
        width: "5%",
        Cell: ({ row }) => (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}>
            {row.original.Count}
          </div>
        ),
      },
    ],
    []
  )


  document.title = `Accessories Report | ${localStorage.getItem("ShopName")}`
  const flatpickrRef = useRef(null);
  const checkbox = useRef();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Card>
            <CardBody>
              <Row>
                <Col sm="12">
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row style={{ marginTop: "5px" }}>
                      <Col md={2} className="mb-2">
                        <label
                          htmlFor="name"
                          className="required form-label"
                        >
                          Categories
                        </label>
                        <Input
                          type="select"
                          id="validationCustom02"
                          // bsSize="sm"
                          className="form-control-sm"
                          name="category"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category}
                          invalid={
                            validation.touched.category &&
                              validation.errors.category
                              ? true
                              : false
                          }
                        >
                          <option value={0}>Select category</option>
                          {categories.map((item, index) => {
                            return (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            )
                          })}
                        </Input>
                        {validation.touched.category &&
                          validation.errors.category ? (
                          <FormFeedback type="invalid">
                            {validation.errors.category}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} className="mb-2">
                        <label
                          htmlFor="name"
                          className="required form-label"
                        >
                          Accessories
                        </label>
                        <Input
                          type="select"
                          id="validationCustom02"
                          disabled={validation.values.category == 0}
                          // bsSize="sm"
                          className="form-control-sm"
                          name="accessory"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.accessory}
                          invalid={
                            validation.touched.accessory &&
                              validation.errors.accessory
                              ? true
                              : false
                          }
                        >
                          <option value={0}>Select accessory</option>
                          {accessories.map((item, index) => {
                            return (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            )
                          })}
                        </Input>
                        {validation.touched.accessory &&
                          validation.errors.accessory ? (
                          <FormFeedback type="invalid">
                            {validation.errors.accessory}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} className="mb-2">
                        <label
                          htmlFor="name"
                          className="form-label"
                        >
                          Accessory Value
                        </label>
                        <Select
                          unstyled
                          styles={colourStyles}
                          isDisabled={validation.values.accessory == 0 || validation.values.category == 0}
                          value={accessoryValueName}
                          onChange={(e) => {
                            handleSelectGroup(e);
                          }}
                          options={accessoryValue}
                          className="select2-selection"
                        />
                      </Col>
                      <Col md={3} className="table-header" >
                        <div className="d-flex" style={{ marginTop: "21px" }} >
                          <div className="form-control " onClick={() => handleClick()} style={{ width: "40px", borderRight: "none", backgroundColor: "#EFF2F7", display: "flex", alignItems: "center", cursor: "pointer" }}>
                            <input
                              id="checkbox"
                              type="checkbox"
                              style={{ width: "15px", height: "15px", cursor: "pointer" }}
                              checked={dateDisble}
                              onChange={e => setdateDisble(e.target.checked)}
                              ref={checkbox}
                            />
                          </div>
                          <Flatpickr
                            ref={flatpickrRef}
                            disabled={!dateDisble}
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              mode: "range",
                              dateFormat: "d-m-Y"
                            }}
                            value={date}
                            onChange={(e) => { setdate(e) }}

                          />
                          <i
                            className="fas fa-calendar-alt calander-icon"
                            style={{ cursor: 'pointer', marginTop: "21px" }}
                            onClick={() => {
                              flatpickrRef.current.flatpickr.open();
                            }}
                          ></i>
                        </div>

                      </Col>
                      <Col md={2}
                        className="mb-2 available_button check_button Button_aline"
                      >
                        <>
                          {formSumitLoading2 ? (
                            <button
                              type="button"
                              className="btn btn-primary  btn-sm"
                              style={{ width: "80%", marginTop: "3px" }}
                            >
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              disabled={validation.values.accessory == 0}
                              className="btn btn-primary  btn-sm"
                              style={{ width: "80%", marginTop: "3px" }}
                              type="submit"
                            >
                              Search
                            </Button>
                          )}
                        </>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>

              <AccessoriesReportTable
                columns={columns}
                data={categoryDataList}
                isGlobalFilter={true}
                isCategoryList={true}
                customPageSize={10}
                isLoading={isLoading}
                className="custom-header-css"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AccessoriesReport

export const InvoiceTable = `     <table style="
font-size: 8px;
width: 295px !important;
height: 425px !important;
border-collapse: collapse !important;
border: 0.1px solid black !important;
font-family: 'Times New Roman', Times, serif !important;
color: black !important;
line-height: normal !important;
">
<tr style="
  height: 52px !important;
  border-bottom: 0.1px solid black !important;
">
    <td colspan="6" align="center" style="vertical-align: top !important">
        <div style="font-size: 11px !important">{COMPANY_NAME}</div>
        <div style="font-size: 8px !important; margin-top: 3px !important">
        {COMPANY_ADDRESS}
        </div>
        <div style="font-size: 8px !important; margin-top: 3px !important">
            Mo: +91 {CONTACTNO1} {CONTACTNO2}
        </div>
    </td>
</tr>
<tr style="
  height: 15px !important;
  background-color: #dfdfdf !important;
  font-weight: bold !important;
  border-bottom: 0.1px solid black !important;
  vertical-align: top !important;
"
    align="center">
    <td colspan="4" style="border-right: none !important">
        Customer Details
    </td>
    <td colspan="2" align="right" style="border-left: none !important">
        Bill No.: {BILL_NO}
    </td>
</tr>
<tr style="
  height: 15px !important;
  vertical-align: top !important;
  border-bottom: 0.1px solid black !important;
">
    <td colspan="4" style="border-right: none !important">
        <div style="
      font-weight: bold !important;
      float: left !important;
      margin-right: 3px !important;
    ">
            Shop Name:
        </div>
        {SHOP_NAME}
    </td>
    <td colspan="2"
        style="padding-left: 15px !important; border-left: none !important"
        align="right">
        <div style="font-weight: bold !important">Date: {CREATE_DATE}</div>
    </td>
</tr>
<tr style="
  height: 30px !important;
  vertical-align: top !important;
  border-bottom: 0.1px solid black !important;
">
    <td colspan="6">
        <div style="
      font-weight: bold !important;
      float: left !important;
      margin-right: 3px !important;
    ">
            Address:
        </div>
        {CUSTOMER_ADDRESS}
    </td>
</tr>
<tr style="
  height: 15px !important;
  vertical-align: top !important;
  border-bottom: 0.1px solid black !important;
">
    <td colspan="6">
        <div style="
      font-weight: bold !important;
      float: left !important;
      margin-right: 3px !important;
    ">
            Mo:
        </div>
        +91 {CUSTOMER_CONTACTNO1}
    </td>
</tr>
<tr style="
  height: 15px !important;
  background-color: #dfdfdf !important;
  font-weight: bold !important;
  border-bottom: 0.1px solid black !important;
">
    <td style="
    width: 12px !important;
    border-right: 0.1px solid black !important;
  "
        align="center">
        No
    </td>
    <td style="border-right: 0.1px solid black !important">Item Description</td>
    <td style="
    width: 40px !important;
    border-right: 0.1px solid black !important;
  ">
        Qty
    </td>
    <td style="
    width: 40px !important;
    border-right: 0.1px solid black !important;
  ">
        Rate
    </td>
    <td style="
    width: 40px !important;
    border-right: 0.1px solid black !important;
  ">
        GST(%)
    </td>
    <td style="width: 50px !important">Amount</td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
        1
    </td>
    <td style="border-right: 0.1px solid black !important">{PRODUCT_NAME}</td>
    <td style="border-right: 0.1px solid black !important">{QTY}</td>
    <td style="border-right: 0.1px solid black !important"><span style="font-family: NotoSansGujarati-Regular !important;">₹</span>{RATE}</td>
    <td style="border-right: 0.1px solid black !important">{GST}</td>
    <td><span style="font-family: NotoSansGujarati-Regular !important;">₹</span>{AMOUNT}</td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="height: 15px !important">
    <td style="border-right: 0.1px solid black !important" align="center">
    </td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td style="border-right: 0.1px solid black !important"></td>
    <td></td>
</tr>
<tr style="
  height: 15px !important;
  border-top: 0.1px solid black !important;
">
    <td rowspan="3"
        colspan="3"
        style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
    vertical-align: top !important;
  ">
    </td>
    <td style="
    font-weight: bold !important;
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  "
        colspan="2">
        Total Amount
    </td>
    <td style="
    font-weight: bold !important;
    border-bottom: 0.1px solid black !important;
  ">
  <span style="font-family: NotoSansGujarati-Regular !important;">₹</span>{TOTAL_AMOUNT}
    </td>
</tr>
<tr style="height: 15px !important; font-weight: bold !important">
    <td colspan="2"
        style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  ">
        Discount
    </td>
    <td style="border-bottom: 0.1px solid black !important"><span style="font-family: NotoSansGujarati-Regular !important;">₹</span>{DISCOUNT}</td>
</tr>

<tr style="height: 15px !important; font-weight: bold !important">
    <td colspan="2"
        style="
    border-right: 0.1px solid black !important;
    border-bottom: 0.1px solid black !important;
  ">
        Payble Amount
    </td>
    <td style="border-bottom: 0.1px solid black !important"><span style="font-family: NotoSansGujarati-Regular !important;">₹</span>{PAYBLE_AMOUNT}</td>
</tr>
<tr>
    <td colspan="6" style="vertical-align: top !important">
        <div>
            This is a Computer Generated Invoice
        </div>
    </td>
</tr>
<tr>
    <td colspan="6" style="vertical-align: bottom !important">
        <div>
            <div style="float: left; font-size: 8px;">
            {WEBSITE}
            </div>
            <div style="float: right; font-size: 8px;">
            {COMPANYEMAIL}
            </div>
        </div>
    </td>
</tr>
</table>`


import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import Profile from "../pages/UserProfile/Profile"

//Master
import User from "pages/Master/User/User"
import Adduser from "pages/Master/User/Adduser"
import Timeslote from "pages/Master/timeslote/Timeslote"
import Size from "pages/Master/size/Size"
import Inquiries from "pages/Master/inquries/Inquiries"
import Color from "pages/Master/color/Color"
import Categories from "pages/Master/Categories/Categories"
import Plan from "pages/Master/Plan/Plan"
import Reminder from "pages/Master/Reminder/Reminder"

import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/InternalServerError"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Products
import Products from "../pages/Inventory/Products/Products"
import CreateProduct from "pages/Inventory/Products/CreateProduct"
import UpdateProduct from "pages/Inventory/Products/UpdateProduct"

// Transactions
import Booking from "pages/Transiction/Booking/Booking"
import CreateBooking from "pages/Transiction/Booking/CreateBooking"
import CartListTable from "pages/Transiction/CartListTable"

// Reports
import RBooking from "pages/Reports/Booking/RBooking"
import Delivery from "pages/Reports/Delivery/Delivery"
import Feedback from "pages/Reports/Feedback"
import Return from "pages/Reports/Return/Return"
import BookedOrders from "pages/Reports/BookedOrder/BookedOrders"

// setting
import Setting from "pages/Setting/Setting"
import Demo from "pages/Demo"
import Avaiblity from "pages/Avaiblity/Avaiblity"
import UpadateUser from "pages/Master/User/UpadateUser"

//invoice
import Invoice from "pages/Invoice/Invoice"
// Subscriptions
import Subscriptions from "pages/Subcription/Subscription"

import { routes } from "./path"
import ViewBooking from "pages/Transiction/Booking/BookingView"
import ImportProduct from "pages/Inventory/Import-Bulk-Products/ImportProduct"
import ProductHistory from "pages/Reports/ProductHistory/ProductHistory"
import Salesman from "pages/Reports/Salesman/Salesman"
import AllPlans from "pages/AllPlans"
import Holdbills from "pages/Transiction/HoldBills/Holdbills"
import Income from "pages/Transiction/Income/Income"
import Expenses from "pages/Transiction/Expenses/Expenses"
import IncomExpense from "pages/Reports/Inccome-Expense/IncomExpense"
import Decrypt from "pages/Master/Decryption/Decrypt"
import DecrptData from "pages/DecryptData/DecrptData"
import SalesList from "pages/Transiction/Sales/SalesList"
import CreateSales from "pages/Transiction/Sales/CreateSales"
import ProductCatalogue from "pages/ProductCatalogue/ProductCatalogue"
import Tutorial from "pages/Tutorial/Tutorial"
import TutorialForVendor from "pages/Tutorial/TutorialListForVendor"
import ReleseNote from "pages/relesenote/ReleseNote"
import IncomeExpenseReport from "pages/Reports/Finance/IncomeExpenceReport"
import PendingBills from "pages/Reports/Pending-Bills/PendingBillsReport"
import DepositReport from "pages/Reports/Deposit/DepositReport"
import Accounts from "pages/Master/Accounts/Accounts"
import Items from "pages/Inventory/Items/Items"
import JournalVouchers from "pages/Transiction/JournalVouchers/JournalVouchers"
import PurchaseList from "pages/Transiction/Purchases/PurchaseList"
import CreatePurchase from "pages/Transiction/Purchases/CreatePurchase"
import Accessory from "pages/Master/Accessory/Accessory"
import StockeRegisterReport from "pages/Reports/StockRegister/StockRegister"
import CreditNotes from "pages/Transiction/CreditNotes/CreditNotes"
import CreateCreditNote from "pages/Transiction/CreditNotes/CreateCreditNote"
import AccountLedgerReport from "pages/Reports/Account-Ledger/AccountLedgerReport"
import AccessoriesReport from "pages/Reports/Accessories/AccessoriesReport"
import ExportProductGroomWell from "pages/GroomWell/ExportProduct/ExportProductGroomWell"
import MapCategoriesGroomWell from "pages/GroomWell/MapCategory/MapCategoriesGroomWell"
import GroomWellProduct from "pages/GroomWell/Product/GroomWellProduct"
import GroomWellProfile from "pages/GroomWell/GroomWellProfile/GroomWellProfile"
import DBBackup from "pages/DBBackup/DBBackup"
import ProductAvailable from "pages/AvailableProducts/ProductsAvailable"
import SalesGstReport from "pages/Reports/SalesGST/SalesGSTReport"
import Washing from "pages/Transiction/Washing/WashingList"

const authProtectedRoutes = [
  { path: routes.dashboard, component: <Dashboard /> },
  { path: routes.available, component: <Avaiblity /> },
  { path: routes.productAvailable, component: <ProductAvailable /> },
  { path: routes.catalogue, component: <ProductCatalogue /> },

  // Tutorials
  { path: routes.tutorial, component: <Tutorial /> },
  { path: routes.tutorialForVendor, component: <TutorialForVendor /> },

  // Master
  { path: routes.userList, component: <User /> },
  { path: routes.userCreate, component: <Adduser /> },
  { path: routes.userUpdate, component: <UpadateUser /> },
  { path: routes.inquiries, component: <Inquiries /> },
  { path: routes.categories, component: <Categories /> },
  { path: routes.size, component: <Size /> },
  { path: routes.color, component: <Color /> },
  { path: routes.timeslot, component: <Timeslote /> },
  { path: routes.plan, component: <Plan /> },
  { path: routes.reminder, component: <Reminder /> },
  { path: routes.accounts, component: <Accounts /> },
  { path: routes.accessory, component: <Accessory /> },
  { path: routes.items, component: <Items /> },
  { path: routes.demo, component: <Demo /> },

  // Product

  { path: routes.productList, component: <Products /> },
  { path: routes.createProduct, component: <CreateProduct /> },
  { path: routes.updateproduct, component: <UpdateProduct /> },
  { path: routes.importProduct, component: <ImportProduct /> },
  { path: routes.productHistory, component: <ProductHistory /> },

  // GroomWell
  { path: routes.groomWellProfile, component: <GroomWellProfile /> },
  { path: routes.exportProduct, component: <ExportProductGroomWell /> },
  { path: routes.mapCategory, component: <MapCategoriesGroomWell /> },
  { path: routes.groomWellProduct, component: <GroomWellProduct /> },


  // Transaction
  { path: routes.bookingList, component: <Booking /> },
  { path: routes.HoldBillList, component: <Holdbills /> },
  { path: routes.createbooking, component: <CreateBooking /> },
  { path: routes.updatebooking, component: <CreateBooking /> },
  { path: routes.viewBooking, component: <InvoiceDetail /> },
  { path: routes.cartlist, component: <CartListTable /> },
  { path: routes.income, component: <Income /> },
  { path: routes.expenses, component: <Expenses /> },
  { path: routes.createSale, component: <CreateSales /> },
  { path: routes.updateSale, component: <CreateSales /> },
  { path: routes.salesList, component: <SalesList /> },
  { path: routes.purchaseList, component: <PurchaseList /> },
  { path: routes.createPurchase, component: <CreatePurchase /> },
  { path: routes.UpdatePurchase, component: <CreatePurchase /> },
  { path: routes.washing, component: <Washing /> },
  { path: routes.CreditNote, component: <CreditNotes /> },
  { path: routes.CreateCreditNote, component: <CreateCreditNote /> },
  { path: routes.journalVouchers, component: <JournalVouchers /> },

  // Reports
  { path: routes.reportBooking, component: <RBooking /> },
  { path: routes.delivery, component: <Delivery /> },
  { path: routes.return, component: <Return /> },
  { path: routes.accessoryReport, component: <AccessoriesReport /> },
  { path: routes.pendingBills, component: <PendingBills /> },
  { path: routes.feedback, component: <Feedback /> },
  { path: routes.finance, component: <IncomeExpenseReport /> },
  { path: routes.salesman, component: <Salesman /> },
  { path: routes.incomeExpense, component: <IncomExpense /> },
  { path: routes.accountLedger, component: <AccountLedgerReport /> },
  { path: routes.deposit, component: <DepositReport /> },
  { path: routes.stockRegister, component: <StockeRegisterReport /> },

  // GST Report
  { path: routes.BookedOrderReport, component: <BookedOrders /> },
  { path: routes.salesGSTReport, component: <SalesGstReport /> },

  // Settings
  { path: routes.setting, component: <Setting /> },

  // db-backup
  { path: routes.dbBackup, component: <DBBackup /> },

  // invoice
  { path: routes.invoice, component: <Invoice /> },
  // Subscriptions
  { path: routes.subscription, component: <Subscriptions /> },

  // Decryption
  { path: routes.decrypt, component: <Decrypt /> },


  // Profile
  { path: routes.profile, component: <Profile /> },

  // Relese Note
  { path: routes.releseNote, component: <ReleseNote /> },


  //profile
  // { path: "/profile", component: <UserProfile /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/inquiry", component: <Register /> },
  { path: "/plans", component: <AllPlans /> },
  { path: "/decryptData", component: <DecrptData /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  { path: "/submission-inquiry", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }

import React, { useState } from 'react'
import appConfig from 'Api/app.config';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';

function DecrptData() {

    const [pasteData, setpasteData] = useState("uD33aj2xvtvZY6GJ9svlmBWGJALor2q45s8TWa5A+7M57ThQMslji1juFhbJbWMb")

    const handlePaste = async () => {
        const res = await fetch(`${appConfig.BASE_URL}/Encryption/decrypt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: pasteData,
                token: JSON.parse(localStorage.getItem('authUser')).Token,
            }),
        });
        var enterKeyEvent = new KeyboardEvent('keydown', { key: 'F12' });
        document.dispatchEvent(enterKeyEvent);
        console.log(JSON.parse(await res.json()));

    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <Input
                                    name="remark"
                                    rows="7"
                                    placeholder=""
                                    type="textarea"
                                    className="form-control-sm"
                                    value={pasteData}
                                    onChange={(e) => { setpasteData(e.target.value) }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='mt-2' style={{ display: "flex", justifyContent: "end" }}>
                                <Button color="primary"
                                    className="btn btn-primary me-2"
                                    type="button" onClick={() => { handlePaste() }}>Decrypt</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default DecrptData
// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"

//import components
import TableList from "../../components/Common/TableList"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

import { Booking_status } from "_mock/Static_data"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"

function ProductViewTable({ modal, toggle, productViewId }) {
  // useState
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { getApi } = useApi()

  // useEffect product list api
  useEffect(() => {
    if (productViewId) {
      setIsLoading(true)
      getApi(`Booking/booking-item-list?id=${productViewId}`).then(
        ({ data, status }) => {
          if (status === 200) {
            setIsLoading(false)
            setTableData(data)
          } else {
            setIsLoading(false)
          }
        }
      )
    }
  }, [productViewId])

  // status value get displayName
  const statusName = id => {
    const statusValue = Booking_status.filter(item => {
      if (item.Value == id) {
        return item.Value
      }
    })
    return statusValue[0].DisplayName
  }

  // product view table
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Name",
        accessor: "ProductName",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Rent.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Status
          </div>
        ),
        accessor: "Status",
        width: "6%",
        Cell: ({ row }) => {
          let statusValue = statusName(row.original.Status)
          return <div>{statusValue}</div>
        },
      },
    ],
    []
  )

  return (
    <Modal isOpen={modal} toggle={toggle} centered={true}>
      <div className="modal-content ">
        <ModalHeader className="text-centre" toggle={toggle} tag="h5">
          Products
        </ModalHeader>
        <ModalBody>
          <TableList
            columns={columns}
            data={tableData}
            isGlobalFilter={true}
            isCategoryList={true}
            customPageSize={10}
            isLoading={isLoading}
            className="custom-header-css"
          />
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ProductViewTable

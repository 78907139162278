/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import Compressor from "compressorjs"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
  FormGroup,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useNavigate } from "react-router-dom"
import uploadImage from "../../../assets/images/uploadImage.png"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import ImageCrop from "./ImageCrop"

let colors = []
let categories = []
let size = []
function CreateProduct() {
  document.title = `Create Product | ${localStorage.getItem("ShopName")}`
  const { getApi, postApi } = useApi()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [screeLoad, setscreeLoad] = useState(true)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [isInStock, setIsInStock] = useState(true)
  const [modal, setModal] = useState(false)
  const [formSumitLoading1, setformSumitLoading1] = useState(false)
  const [cropImage, setCropImage] = useState('')
  const [modal1, setModal1] = useState(false)
  const [enableProductStock, setEnableProductStock] = useState(false)

  useEffect(() => {
    setscreeLoad(true)
    getApi("Category/category-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        categories = data
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
    getApi("color/color-lookup").then(({ status, data }) => {
      if (status === 200) {
        colors = data
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
    getApi("size/size-lookup").then(({ data, status }) => {
      if (status === 200) {
        size = data
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    postApi("Setting/setting-by-keys", {
      key: ["ENABLE_PRODUCT_STOCK"],
      userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
    }).then(({ data, status }) => {
      if (status == 200) {
        setEnableProductStock(data[0].Value == "1")
      }
    })
  }, [])

  // toggle handlers
  const toggle = () => {
    setModal(!modal)
  }
  const toggle1 = () => {
    setModal1(!modal1)
  }

  const handleCancle = () => {
    toggle()
  }

  // product validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      Color: 0,
      Code: "",
      Name: "",
      Size: 0,
      remarks: "",
      Category: 0,
      Rent: 0,
      MRP: 0,
      productQty: 1,
      profilePic: "",
      hsnCode: "",
    },
    validationSchema: Yup.object({
      Code: Yup.string().required("The Product Code is required"),
      Name: Yup.string().required("The Product Name is required"),
      Category: Yup.number().min(1, "The Category is required"),
      Rent: Yup.number().min(0, "The Rent is required").required("The Rent is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        const profilePic =
          cropImage !== ""
            ? cropImage.split(",")[1]
            : cropImage
        const data = []
        data.push({
          id: values.id,
          code: values.Code,
          categoryId: parseInt(values.Category),
          sizeId: values.Size ? parseInt(values.Size) : 0,
          colorId: values.Color ? parseInt(values.Color) : 0,
          name: values.Name,
          rent: parseInt(values.Rent),
          mrp: parseInt(values.MRP || 0),
          hsn: values.hsnCode,
          image: cropImage != ""
            ? cropImage.split(",")[1]
            : cropImage,
          remarks: values.remarks,
          isInStock: isInStock,
          qty: values.productQty || 1,
        })
        postApi("Product/create", data).then(({ data, status }) => {
          setformSumitLoading(false)
          if (status === 200) {
            toast.success("Product Create Successfully", {
              style: {
                fontSize: "15px",
              },
            })
            if (!isInStock) {
              toggle()
            }
            setCropImage('')
            validation.values.profilePic = ""
            setPreviewUrl("")
            validation.handleReset()
            validation.setFieldValue("profilePic", '')
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })

      }
    },
  })

  // image select handler
  const handleDrop = acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    new Compressor(selectedFile, {
      quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        toggle1()
        // validation.setFieldValue("profilePic", await toBase64(compressedResult))
      },
    })
  }

  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // product image handler
  const handleclick = () => {
    validation.setFieldValue("profilePic", '')
    setPreviewUrl('')
    setCropImage('')
  }

  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      assignee: "",
      reminderDate: new Date().toISOString().slice(0, 10),
    },
    validationSchema: Yup.object({
      description: Yup.string().required("The description is required"),
      assignee: Yup.string().required("The assignee is required"),
      reminderDate: Yup.string().required("The reminder date is required"),
    }),
    onSubmit: values => {
      setformSumitLoading1(true)
      if (values) {
        postApi("Reminder/create", {
          reminderDate: values.reminderDate,
          assignee: values.assignee,
          description: values.description,
        }).then(({ data, status }) => {
          setformSumitLoading1(false)
          if (status == 400) {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          } else {
            toast.success("successfully created reminder", {
              style: {
                fontSize: "15px",
              },
            })
            toggle()
            validation.handleReset()
            validation.setFieldValue("profilePic", '')
          }
        })
      }
    },
  })

  const handleReset = () => {
    validation.setFieldValue("profilePic", "")
    setPreviewUrl('')
    setCropImage('')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault()
                      validation.handleReset()
                      validation.setFieldValue("profilePic", '')
                      return false
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            onClick={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="d-flex flex-column align-items-center text-center p-2 py-3">
                                  {cropImage ? (
                                    <>
                                      <div className="position-relative overflow-hidden product-image">
                                        <input {...getInputProps()} />
                                        <img
                                          style={{
                                            cursor: "pointer",
                                            border: "1px solid #ced4da",
                                            borderRadius: "3px",
                                          }}
                                          width="175px"
                                          height="200px"
                                          src={cropImage}
                                          {...getRootProps()}
                                        />
                                        <div className="delete-button">
                                          <button
                                            className="btn btn-sm btn-danger mb-1 me-1"
                                            onClick={() => handleclick()}
                                            type="button"
                                          >
                                            <i className=" fs-6 fas fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <input {...getInputProps()} />

                                      <div
                                        style={{
                                          width: "175px",
                                          height: "200px",
                                          border: "1px solid #ced4da",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        <img
                                          className="img-fluid"
                                          style={{}}
                                          src={uploadImage}
                                          {...getRootProps()}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </Dropzone>
                        </FormGroup>
                      </Col>
                      <Col md="9">
                        <Row>
                          <Col md="3">
                            <div className="mb-2">
                              <FormGroup className="mb-2">
                                <Label
                                  htmlFor="validationCustom02"
                                  className="required"
                                >
                                  Category
                                </Label>
                                <Input
                                  type="select"
                                  id="validationCustom02"
                                  bsSize="sm"
                                  className="form-control-sm"
                                  name="Category"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Category || ""}
                                  invalid={
                                    validation.touched.Category &&
                                      validation.errors.Category
                                      ? true
                                      : false
                                  }
                                >
                                  <option value={0}>Select</option>
                                  {categories.map((item, index) => {
                                    return (
                                      <option key={index} value={item.Key}>
                                        {item.Value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {validation.touched.Category &&
                                  validation.errors.Category ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Category}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                            {validation.touched.Category &&
                              validation.errors.Category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Category}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md="5">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                                className="required"
                              >
                                Product Name
                              </Label>
                              <Input
                                name="Name"
                                placeholder="Product Name"
                                type="text"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Name || ""}
                                invalid={
                                  validation.touched.Name &&
                                    validation.errors.Name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Name &&
                                validation.errors.Name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-2">
                              <Label htmlFor="validationCustom01">Color</Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                id="validationCustom01"
                                className="form-control-sm"
                                name="Color"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Color || ""}
                                invalid={
                                  validation.touched.Color &&
                                    validation.errors.Color
                                    ? true
                                    : false
                                }
                              >
                                <option>Select</option>
                                {colors.map((item, index) => {
                                  return (
                                    <option key={index} value={item.Key}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                              {validation.touched.Color &&
                                validation.errors.Color ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Color}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <div className="mb-2">
                              <FormGroup className="mb-2">
                                <Label htmlFor="validationCustom02">Size</Label>
                                <Input
                                  type="select"
                                  id="validationCustom02"
                                  bsSize="sm"
                                  className="form-control-sm"
                                  name="Size"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Size || ""}
                                  invalid={
                                    validation.touched.Size &&
                                      validation.errors.Size
                                      ? true
                                      : false
                                  }
                                >
                                  <option>Select</option>
                                  {size.map((item, index) => {
                                    return (
                                      <option key={index} value={item.Key}>
                                        {item.Value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {validation.touched.Size &&
                                  validation.errors.Size ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Size}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                            {validation.touched.Size &&
                              validation.errors.Size ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Size}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <div className="mb-2">
                              <Label className="form-label required">
                                Rent
                              </Label>
                              <Input
                                name="Rent"
                                className="form-control-sm"
                                placeholder="0"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onKeyPress={handleKeyPress}
                                value={validation.values.Rent}
                                invalid={
                                  validation.touched.Rent &&
                                    validation.errors.Rent
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Rent &&
                                validation.errors.Rent ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Rent}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-2">
                              <Label className="form-label ">
                                MRP
                              </Label>
                              <Input
                                name="MRP"
                                className="form-control-sm"
                                placeholder="0"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onKeyPress={handleKeyPress}
                                value={validation.values.MRP}
                                invalid={
                                  validation.touched.MRP &&
                                    validation.errors.MRP
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.MRP &&
                                validation.errors.MRP ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.MRP}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className="required"
                              >
                                Code
                              </Label>
                              <Input
                                name="Code"
                                placeholder="Code"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Code || ""}
                                invalid={
                                  validation.touched.Code &&
                                    validation.errors.Code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Code &&
                                validation.errors.Code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Code}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className=""
                              >
                                HSN Code
                              </Label>
                              <Input
                                name="hsnCode"
                                placeholder="HSN Code"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.hsnCode || ""}
                              />
                            </FormGroup>
                          </Col>
                          {enableProductStock &&
                            <Col md="2">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Product Qty
                                </Label>
                                <Input
                                  name="productQty"
                                  className="form-control-sm"
                                  placeholder="0"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  onKeyPress={handleKeyPress}
                                  value={validation.values.productQty}
                                  invalid={
                                    validation.touched.productQty &&
                                      validation.errors.productQty
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.productQty &&
                                  validation.errors.productQty ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.productQty}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>}
                          <Col md="2">
                            <div className="d-flex justify-content-start align-items-center gap-2 mt-3">
                              <>
                                <input
                                  id="checkbox2"
                                  type="checkbox"
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  defaultChecked={isInStock}
                                  onChange={e => setIsInStock(e.target.checked)}
                                />
                                <label style={{ cursor: "pointer" }} htmlFor="checkbox2" className="pt-2"  >
                                  In Stock
                                </label></> </div>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-2">
                              <Label htmlFor="validationCustom05">Remark</Label>
                              <Input
                                name="remarks"
                                rows="2"
                                placeholder="Type your remark here"
                                type="textarea"
                                className="form-control-sm"
                                value={validation.values.remarks || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="text-end">
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2"
                          type="reset"
                          onClick={() => handleReset()}
                        >
                          Reset
                        </Button>

                        <>
                          {formSumitLoading ? (
                            <button type="button" className="btn btn-primary ">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary "
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </>
                      </Col>
                    </Row>
                  </Form>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered={true}
                    size="small"
                    style={{ width: "350px" }}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={toggle} tag="h5">
                        {"Create Reminder for Make a Product"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validation1.handleSubmit()
                            return false
                          }}
                          onReset={e => {
                            e.preventDefault
                            validation1.handleReset()
                            return false
                          }}
                        >
                          <FormGroup className="mb-2">
                            <Label htmlFor="validation1Custom03" className="required">
                              Description
                            </Label>
                            <Input
                              name="description"
                              placeholder="Reminder Description"
                              type="textarea"
                              rows="2"
                              className="form-control-sm"
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.description || ""}
                              invalid={
                                validation1.touched.description && validation1.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation1.touched.description && validation1.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation1.errors.description}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <Label htmlFor="validation1Custom03" className="required">
                              Assignee
                            </Label>
                            <Input
                              name="assignee"
                              placeholder="Assignee"
                              type="text"
                              className="form-control-sm"
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.assignee || ""}
                              invalid={
                                validation1.touched.assignee && validation1.errors.assignee
                                  ? true
                                  : false
                              }
                            />
                            {validation1.touched.assignee && validation1.errors.assignee ? (
                              <FormFeedback type="invalid">
                                {validation1.errors.assignee}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-2">
                            <Label htmlFor="validation1Custom03" className="required">
                              Date
                            </Label>
                            <Input
                              name="reminderDate"
                              placeholder="date placeholder"
                              type="date"
                              // min={new Date().toISOString().slice(0, 10)}
                              dateFormat="DD/M/YYYY"
                              className="form-control-sm"
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.reminderDate || new Date().toISOString().slice(0, 10)}
                              invalid={
                                validation1.touched.reminderDate && validation1.errors.reminderDate
                                  ? true
                                  : false
                              }
                            />
                            {validation1.touched.reminderDate && validation1.errors.reminderDate ? (
                              <FormFeedback type="invalid">
                                {validation1.errors.reminderDate}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <div className="text-end">
                            <Button
                              color="secondary"
                              className="btn btn-primary me-2 btn-sm"
                              onClick={handleCancle}
                            >
                              Cancle
                            </Button>
                            <>
                              {formSumitLoading1 ? (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                >
                                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                  Loading
                                </button>
                              ) : (
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-sm "
                                  type="button"
                                  onClick={e => {
                                    e.preventDefault()
                                    validation1.handleSubmit()
                                    return false
                                  }}
                                >
                                  Submit
                                </Button>
                              )}
                            </>
                          </div>
                        </Form>
                      </ModalBody>
                    </div>
                  </Modal>
                  <Modal
                    isOpen={modal1}
                    toggle={toggle1}
                    centered={true}
                    size="lg"
                    style={{ width: "580px" }}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={toggle1} tag="h5">
                        {"Crop Image"}
                      </ModalHeader>
                      <ModalBody style={{ height: "490px" }}>
                        <ImageCrop
                          image={previewUrl}
                          setCropImage={setCropImage}
                          toggle={toggle1}
                          validation={validation}
                        />
                      </ModalBody>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateProduct

import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { Button, Col, Container, Row } from 'reactstrap'
import { GoogleGenerativeAI } from '@google/generative-ai';
import { toast } from 'react-hot-toast'

export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })


const getCroppedImg = (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();

        image.crossOrigin = 'Anonymous'; // Set crossOrigin attribute

        image.onload = () => {
            const { width, height } = croppedAreaPixels;
            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(
                image,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                width,
                height
            );

            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        resolve(base64data);
                    };
                },
                'image/jpeg',
                1
            );
        };

        image.onerror = (error) => {
            reject(error);
        };

        image.src = imageSrc;
    });
};


const ImageCrop = ({ image, setCropImage, toggle, validation }) => {
    const API_KEY = 'AIzaSyCqUBRJ3oBOlyCceW3kKZ3yY5ECjsiYRBU';
    const genAI = new GoogleGenerativeAI(API_KEY);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isLoadingUploadButton, setIsLoadingUploadButton] = useState(false)

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleCropChange = (crop) => {
        setCrop(crop);
    };

    const handleZoomChange = (zoom) => {
        setZoom(zoom);
    };

    const handleUploadClick = async () => {
        setIsLoadingUploadButton(true)
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
            console.log(croppedImage)
            try {
                const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' });
                const prompt = "Act as machine and answer in true or false, It's a contain of any one like nudity, pornography, animal, weapons, sex image? if your return false the perform this Act as fashion designer and Answer in active voice within 50 words for given cloth in photo. Cover point should be tailor measurement, fabric, color and rest like which occasions and comfortableness. if your return true then return True";
                const result = await model.generateContent([prompt, { inlineData: { data: croppedImage.split(",")[1], mimeType: 'image/jpeg' } }]);
                const response = await result.response;
                const text = response.text();
                if (response) {
                    if ((text?.trim()?.split(".")[0])?.split("\n")[0] == "False") {
                        setCropImage(croppedImage);
                        validation.setFieldValue("profilePic", croppedImage)
                        validation.setFieldValue("description", text?.trim()?.split("\n")[2] == "" ? "" : text?.trim().split("\n")[2])
                        setIsLoadingUploadButton(false)
                        toggle()
                    } else {
                        if (text.trim().split('.')[0] != "False") {
                            setIsLoadingUploadButton(false)
                            setCropImage('');
                            validation.setFieldValue("profilePic", '')
                            validation.setFieldValue("description", '')
                            toggle()
                            toast.error("Please Select Other Image.", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        } else {
                            setIsLoadingUploadButton(false)
                            setCropImage('');
                            validation.setFieldValue("profilePic", '')
                            validation.setFieldValue("description", '')
                            toggle()
                            toast.error("Not Support Image.", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                    }
                }
            } catch (error) {
                setIsLoadingUploadButton(false)
                setCropImage('');
                validation.setFieldValue("profilePic", '')
                validation.setFieldValue("description", '')
                toggle()
                console.log('Error handling image validation:', error);
                toast.error('Failed to validate image or generate text.');
            }
        } catch (error) {
            console.error('Error cropping image:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div className="controls">
                        <input
                            type="range"
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e) => setZoom(parseFloat(e.target.value))}
                            className="zoom-range"
                        />
                    </div>
                    <div className="App">
                        <div className="crop-container">
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={0.1 / 0.13}
                                onCropChange={handleCropChange}
                                onCropComplete={onCropComplete}
                                onZoomChange={handleZoomChange}
                                showGrid={false} // Optionally, you can hide the grid
                            />
                        </div>
                        {isLoadingUploadButton ?
                            <div className="controls2">
                                <button type="button" className="btn btn-primary ">
                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                    Loading
                                </button>
                            </div>
                            :
                            <div className="controls1">
                                <Button
                                    color="primary"
                                    className="btn btn-primary "
                                    type="button"
                                    onClick={handleUploadClick}
                                >
                                    {" Upload "}
                                </Button>
                            </div>
                        }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default ImageCrop